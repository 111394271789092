import React, { useRef } from "react";
import GetInTouchFooter from "./GetInTouchFooter_siddu";
import MediaQuery from "react-responsive";
import D2dLogo from "../Assets/D2D-logo.png";
import HeroImage from "../Assets/hero-Img.png";
import D2d_doc from "../Assets/Docs/2024boucher-full.pdf"
import D2d_gif from "../Assets/Docs/2024broucherGIF.gif"

export default function Doc2data_siddu() {
  const textColor = "#1C2951";

  return (
    <div
      className="doc2data_siddu"
      style={{
        marginTop: "70px",
        background: "#eef1ef",
      }}
    >
      {/** Hero Section  */}
      <section
        className="container px-0"
        style={{
          background:
            "linear-gradient(180deg, #EAEEEC 0%, rgba(234, 238, 236, 0.00) 100%)",
        }}
      >
        <img
          src={D2dLogo}
          className="d-block mx-auto pb-3"
          style={{ width: "18rem", padding: "20px 20px 0 20px " }}
        />

        <MediaQuery maxWidth={575}>
          <h3 className=" text-center pb-0" style={{ color: textColor }}>
            Revolutionize Government Workflows with Al-Powered Document
            Processing
            {/* <br /> */}
            {/* Al-Powered Document Processing */}
          </h3>
        </MediaQuery>
        <MediaQuery minWidth={576} maxWidth={991}>
          <h1 className=" text-center  pb-0" style={{ color: textColor }}>
            Revolutionize Government Workflows
            {/* Al-Powered Document
            Processing */}
            <br />
            with Al-Powered Document Processing
          </h1>
        </MediaQuery>
        <MediaQuery minWidth={992}>
          <h1 className=" text-center  pb-0" style={{ color: textColor }}>
            Revolutionize Government Workflows with
            {/* Al-Powered Document
            Processing */}
            <br />
            Al-Powered Document Processing
          </h1>
        </MediaQuery>

        <p class=" text-center" style={{ color: textColor }}>
          Experience Seamless Automation and Digitalization with Doc2Data.ai
        </p>

        <div
          className="mx-auto d-flex"
          style={{ width: "max-content", gap: "10px" }}
        >
          <a
            href="https://www.doc2data.ai/"
            target="_blank"
            class=" px-3 py-2 px-md-4 py-md-2 text-decoration-none text-white btn-of-hero-sec-d2d btn mr-2"
          >
            Learn More
          </a>
          <a
            href="https://blog.eigennet.com/"
            target="_blank"
            class="px-3 py-2 px-md-4 py-md-2 text-decoration-none text-white btn-of-hero-sec-d2d btn ml-2"
          >
            Explore Research Lab
          </a>
        </div>

        <div className=" pt-4 px-0">
          <img src={HeroImage} className="d-block col-12 p-md-0 mx-auto" />
        </div>
      </section>
      {/** Hero Section - end */}

      {/** Section one  */}
      <section className="container px-md-0 py-3 py-md-4">
        <h1
          className=" text-center "
          style={{ color: textColor, paddingTop: "20px", fontSize: "2rem" }}
        >
          How Doc2Data.ai Works
        </h1>
        <p class="text-nowrap text-center" style={{ color: textColor }}>
          Simplified Workflow Automation in 3 Steps
        </p>

        <div className="row p-0 m-0">
          <ThreeSubSectionCard
            // img="/dataflow-green.png"
            img={require("../Assets/dataflow-green.png")}
            title="Capture Information"
            content="Effortlessly capture information from complex documents and images using advanced AI and Machine Learning."
            mainClassName="px-0 pr-md-3 pl-lg-0 pr-lg-4"
          />
          <ThreeSubSectionCard
            // img="/scan-green.png"
            img={require("../Assets/scan-green.png")}
            title="Post-Processing"
            content="Ensure accurate extraction of data and enhance readability and searchability with intelligent post-processing algorithms."
            mainClassName="px-0 pl-md-3 px-lg-4"
          />
          <ThreeSubSectionCard
            // img="/Automation-green.png"
            img={require("../Assets/Automation-green.png")}
            title="Workflow Automation"
            content="Transform existing processes with seamless workflow automation, streamlining tasks for efficiency."
            mainClassName="px-0 pr-md-3 pl-lg-4 pr-lg-0"
          />
        </div>
      </section>

      {/** Section two  */}
      <section className="container px-md-0 py-3 py-md-4">
        {/** greentag is a custom style  */}
        <h5 className="greenTag text-white">Benefits</h5>
        <h1
          className=""
          style={{ margin: "1rem 0", color: textColor, fontSize: "2rem" }}
        >
          Unlock Key Benefits with Doc2Data.ai
        </h1>
        <div className="row m-0">
          <BenefitCard
            // img="/clock-green.png"
            img={require("../Assets/clock-green.png")}
            title="Saves Time"
            content="Bulk processing, increased throughput, saving time for critical tasks and deadlines."
            mainClassName="pl-0 pr-0 pr-md-3 pr-lg-3"
          />
          <BenefitCard
            // img="/database-green.png"
            img={require("../Assets/database-green.png")}
            title="Enhanced Storage"
            content="Store and manage data with enhanced searchability and meta-tagging."
            mainClassName="pl-0 pl-md-3 px-lg-2 pr-0"
          />
          <BenefitCard
            // img="/piggy-bank-green.png"
            img={require("../Assets/piggy-bank-green.png")}
            title="Saves Money"
            content="Optimize resources and cut costs with automated and streamlined workflows."
            mainClassName="pl-0 pr-0 pr-md-3 px-lg-2"
          />
          <BenefitCard
            // img="/target-green.png"
            img={require("../Assets/target-green.png")}
            title="Increase Accuracy"
            content="Achieve unparalleled accuracy in document processing and compliance monitoring."
            mainClassName="pl-0 pl-md-3 pl-lg-2 pr-0"
          />
        </div>
      </section>

      {/** Section 3  */}
      <section className="container px-md-0 py-3 py-md-4">
        <h5 className="greenTag text-white">Advantages</h5>
        <h1
          className=""
          style={{ margin: "1rem 0", color: textColor, fontSize: "2rem" }}
        >
          Gaining the Edge with Doc2Data.ai
        </h1>
        <div className="row p-0 m-0">
          <AdvantagesCard
            // img="/box-green.png"
            img={require("../Assets/box-green.png")}
            title="Bulk Document Processing"
            className="pr-md-2"
          />
          <AdvantagesCard
            // img="/code-green.png"
            img={require("../Assets/code-green.png")}
            title="Metadata Tagging and Indexing"
            className="pl-md-2"
          />
          <AdvantagesCard
            // img="/shield-tick-green.png"
            img={require("../Assets/shield-tick-green.png")}
            title="Automated Compliance Checks"
            className="pr-md-2"
          />
          <AdvantagesCard
            // img="/Doc-search-green.png"
            img={require("../Assets/Doc-search-green.png")}
            title="Create Searchable Databases"
            className="pl-md-2"
          />
          <AdvantagesCard
            // img="/tool-green.png"
            img={require("../Assets/tool-green.png")}
            title="Integration with Existing DMS"
            className="pr-md-2"
          />
          <AdvantagesCard
            // img="/redactionIcon-green.png"
            img={require("../Assets/redactionIcon-green.png")}
            title="PII Identification and Redaction"
            className="pl-md-2"
          />
        </div>
      </section>

      {/** Section 4  */}
      <section className="container px-md-0 py-3 py-md-4">
        <h5 className="greenTag text-white">Powerful Features</h5>
        <h1
          className=""
          style={{ margin: "1rem 0", color: textColor, fontSize: "2rem" }}
        >
          Tackle Everything with Doc2Data.ai
        </h1>
        <div className="row p-0 m-0">
          <FeaturesCard
            // img="file-plus-green.png"
            img={require("../Assets/file-plus-green.png")}
            title="Convert Anything"
            content="Versatile document conversion for various simple and complex formats and fragile document types."
          />
          <FeaturesCard
            // img="shield-tick-green.png"
            img={require("../Assets/shield-tick-green.png")}
            title="Tiered Authentication"
            content="Enhanced security with tiered authentication levels for users and admins."
          />
          <FeaturesCard
            // img="Edit-green.png"
            img={require("../Assets/Edit-green.png")}
            title="Add Custom Metadata"
            content="Robust metadata tagging features to increase searchability and document organization."
          />
          <FeaturesCard
            // img="settings-green.png"
            img={require("../Assets/settings-green.png")}
            title="Automated Compliance"
            content="Stay compliant with government regulations with automated checks."
          />
          <FeaturesCard
            // img="Doc-search-green.png"
            img={require("../Assets/Doc-search-green.png")}
            title="Document Search"
            content="Intuitive search functionality to locate documents effectively."
          />
          <FeaturesCard
            // img="Sharepoint.png"
            img={require("../Assets/Sharepoint.png")}
            title="SharePoint Integration"
            content="Seamless integration with SharePoint for enhanced collaboration."
          />
        </div>
      </section>

      <section className="container px-md-0 py-3 py-md-4">
        <h1
          className=" text-center text-nowrap "
          style={{ color: textColor, fontSize: "2rem" }}
        >
          Read Our One-Pager
        </h1>
        <div className="col-12 px-0 col-md-10 col-lg-6 mx-auto">
          <img
            // src="/pagerDocFnB.gif"
            // src={require("../Assets/pagerDocFnB.gif")}
            src={D2d_gif}
            className="d-block w-100"
            style={{ borderRadius: "15px" }}
            alt="..."
          />
        </div>
        <a
          class=" px-3 py-2 px-md-4 mt-3 py-md-2 text-decoration-none text-white btn-of-hero-sec-d2d btn mr-2 mx-auto d-block"
          style={{ width: "max-content" }}
          // href="https://www.doc2data.ai/wp-content/uploads/2023/09/2023boucher.pdf"
          href={D2d_doc}
          target="_blank"
        >
          Download
        </a>
      </section>

      {/** Slider Seection */}
      {/* <div className="container" style={{ padding: "1.25rem 0" }}>
        <h1
          className=" text-center text-nowrap "
          style={{ color: textColor, fontSize: "2rem" }}
        >
          Doc2Data.ai's Intelligent Character Recognition
        </h1>
        <p
          class=" text-center"
          style={{ color: textColor, fontSize: "16px", fontWeight: "400" }}
        >
          Doc2Data.ai's Intelligent Character Recognition can capture
          information contained in a variety of simple and complex <br />{" "}
          documents with varying formats, sizes, and structures.
        </p>
        <SliderSection />
        <p
          class=" text-center pt-4"
          style={{ color: textColor, fontSize: "16px", fontWeight: "400" }}
        >
          Move slider to see Before and After
        </p>
      </div> */}

      <GetInTouchFooter page="services" />
    </div>
  );
}

function ThreeSubSectionCard({ img, title, content, mainClassName = "" }) {
  return (
    <div
      className={
        "col-12 col-md-6 col-lg-4 d-flex flex-column py-0 align-items-center " +
        mainClassName
      }
      style={{ gap: "10px" }}
    >
      <MediaQuery maxWidth={575}>
        <img
          src={img}
          className="card-img-top"
          alt="..."
          style={{ width: "50px", height: "50px" }}
        />
      </MediaQuery>
      <MediaQuery minWidth={576}>
        <img
          src={img}
          className="card-img-top"
          alt="..."
          style={{ width: "65px", height: "65px" }}
        />
      </MediaQuery>
      <h5 className="p-0" style={{ fontSize: "20px", fontWeight: "500" }}>
        {title}
      </h5>
      {/* <SectionHeading heading={title} textColor="#1C2951" /> */}
      <p
        className="p-0  text-justify"
        style={{ fontSize: "16px", fontWeight: "400" }}
      >
        {content}
      </p>
    </div>
  );
}

function BenefitCard({ img, title, content, mainClassName }) {
  return (
    <div
      className={
        "col-12 col-md-6 col-lg-3 d-flex flex-column py-0 " + mainClassName
      }
    >
      <MediaQuery maxWidth={575}>
        <img
          src={img}
          className="card-img-top"
          alt="..."
          style={{ width: "35px", height: "35px" }}
        />
      </MediaQuery>
      <MediaQuery minWidth={576}>
        <img
          src={img}
          className="card-img-top"
          alt="..."
          style={{ width: "65px", height: "65px" }}
        />
      </MediaQuery>

      <h5 className="my-2" style={{ fontSize: "20px", fontWeight: "500" }}>
        {title}
      </h5>
      <p
        className="text-md-justify"
        style={{ fontSize: "16px", fontWeight: "400", textAlig: "justify" }}
      >
        {content}
      </p>
    </div>
  );
}

function AdvantagesCard({ img, title, className }) {
  return (
    <div className={`col-12 col-md-6 p-0 ${className}`} style={{ gap: "13px" }}>
      <div className="bg-white d-flex align-items-center flex-grow-1 my-2 py-3">
        <img
          src={img}
          className="mx-2"
          alt="..."
          style={{ width: "20px", height: "18px" }}
        />
        <h5
          className="m-0 p-0 "
          style={{ fontSize: "20px", fontWeight: "500" }}
        >
          {title}
        </h5>
      </div>
    </div>
  );
}

function FeaturesCard({ img, title, content, mainClassName = "" }) {
  return (
    <div className="col-12 col-md-6 col-lg-4 p-1">
      <div
        className={
          "d-flex flex-column gap-2 bg-white px-3 pt-3 h-100 " + mainClassName
        }
        style={{ borderRadius: "10px" }}
      >
        <div className="d-flex  ">
          <img
            src={img}
            className="card-img-top"
            alt="..."
            style={{ width: "27px", height: "27px", marginRight: "10px" }}
          />
          <h5 className="mb-0" style={{ fontSize: "20px", fontWeight: "500" }}>
            {title}
          </h5>
        </div>
        <p className="" style={{ marginLeft: "37px" }}>
          {content}
        </p>
      </div>
    </div>
  );
}

function SliderSection() {
  const container = useRef(null);
  const container1 = useRef(null);
  return (
    <div class="row-2s">
      <div class="">
        <div class="S11" ref={container1}>
          <div class="BAslider-1">
            <img
              class="BAimage-before1 BAslider-image1"
              src="beforeOne.png"
              alt="color photo"
            />
            <img
              class="BAimage-after1 BAslider-image1"
              src="after.png"
              alt="black and white"
            />
          </div>

          <input
            type="range"
            min="0"
            max="100"
            value="50"
            onChange={(e) => {
              container1.current.style.setProperty(
                "--position",
                `${e.target.value}%`
              );
            }}
            aria-label="Percentage of before photo shown"
            class="BAslider1"
          />
          <div class="BAslider-line1" aria-hidden="true"></div>
          <div class="BAslider-button1" aria-hidden="true">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              viewBox="0 0 256 256"
            >
              <rect width="256" height="256" fill="none"></rect>
              <line
                x1="128"
                y1="40"
                x2="128"
                y2="216"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="16"
              ></line>
              <line
                x1="96"
                y1="128"
                x2="16"
                y2="128"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="16"
              ></line>
              <polyline
                points="48 160 16 128 48 96"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="16"
              ></polyline>
              <line
                x1="160"
                y1="128"
                x2="240"
                y2="128"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="16"
              ></line>
              <polyline
                points="208 96 240 128 208 160"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="16"
              ></polyline>
            </svg>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="main">
          <div class="S1" ref={container}>
            <div class="BAslider-1">
              <img
                class="BAimage-before BAslider-image"
                src="before2.png"
                alt="color photo"
              />
              <img
                class="BAimage-after BAslider-image"
                src="after2.png"
                alt="black and white"
              />
            </div>

            <input
              type="range"
              min="0"
              max="100"
              value="50"
              onChange={(e) => {
                container.current.style.setProperty(
                  "--position",
                  `${e.target.value}%`
                );
              }}
              aria-label="Percentage of before photo shown"
              class="BAslider"
            />
            <div class="BAslider-line" aria-hidden="true"></div>
            <div class="BAslider-button" aria-hidden="true">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                viewBox="0 0 256 256"
              >
                <rect width="256" height="256" fill="none"></rect>
                <line
                  x1="128"
                  y1="40"
                  x2="128"
                  y2="216"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="16"
                ></line>
                <line
                  x1="96"
                  y1="128"
                  x2="16"
                  y2="128"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="16"
                ></line>
                <polyline
                  points="48 160 16 128 48 96"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="16"
                ></polyline>
                <line
                  x1="160"
                  y1="128"
                  x2="240"
                  y2="128"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="16"
                ></line>
                <polyline
                  points="208 96 240 128 208 160"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="16"
                ></polyline>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SectionHeading({ heading, textColor }) {
  return (
    <>
      <MediaQuery maxWidth={575}>
        <h2 className="h4" style={{ color: textColor, fontWeight: "700" }}>
          {heading}
        </h2>
      </MediaQuery>
      <MediaQuery minWidth={768}>
        <h2 className="h3" style={{ color: textColor, fontWeight: "700" }}>
          {heading}
        </h2>
      </MediaQuery>
    </>
  );
}
