import React, { useEffect } from "react";
import { FaFilePdf, FaCheck } from "react-icons/fa";
import { AiFillCaretRight } from "react-icons/ai";
import "../index.css";
import capability from "../Assets/Docs/capability.pdf";

import gsa from "../Assets/Img/gsa_contracts.png";
import cmmi from "../Assets/Img/cmmi1.png";
import MediaQuery from "react-responsive";
import iso from "../Assets/Img/iso.png";

export default function Capabilities() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div
        className="container"
        style={{
          boxShadow: "0px 0px 10px rgba(51, 51, 51, 0.04)",
          marginTop: "70px",
        }}
      >
        <h2 className="col-title" style={{ borderBottom: "1px solid black" }}>
          CAPABILITIES
        </h2>
        <p>
          <span
            style={{ fontSize: "20px", fontWeight: "bold", color: "#15244f" }}
          >
            Company Overview
          </span>
          <a
            style={{
              padding: "5px 8px",
              backgroundColor: "#1c2951",
              float: "right",
              color: "#fff",
            }}
            href={capability}
            target="_blank"
            rel="noreferrer"
          >
            <FaFilePdf /> Download Capabilities
          </a>
        </p>
        <br />
        <p className="mobile-responsive">
          Eigennet, LLC is a certified small business with CMMI DEV&amp;SVC ML 2
          &amp; ISO 9001:2015 that specializes in solving management and
          information technology challenges for various governmental and
          commercial organizations. Our technical capabilities focus on
          end-to-end document conversion services encompassing records
          management, document scanning and digitization. We use innovative
          technological solutions combined with artificial intelligence and
          machine learning to leverage the document conversion processes to make
          it quick, reliable, and cost effective. We also provide IT
          professional services, legal support services in copyright law and
          audit compliance support to organizations as per their needs and
          requirements.
        </p>
        <p style={{ fontSize: "20px", fontWeight: "bold", color: "#15244f" }}>
          Business Process Areas:
        </p>

        <table
          style={{
            width: "100%",
            border: "0px solid #e1e0e0",
            background: "#fff",
            marginBottom: "10px",
          }}
        >
          <tbody>
            <tr>
              <td
                style={{
                  borderRight: "0px solid #e1e0e0",
                  padding: "0px 8px",
                  width: "50%",
                }}
              >
                <FaCheck style={{ color: "#84c000" }} /> IT Professional
                Services
                <br />
                <FaCheck style={{ color: "#84c000" }} /> Document Conversion
                Services (Electronic &amp; Physical)
                <br />
                <FaCheck style={{ color: "#84c000" }} /> Documents &amp; Records
                Management
                <br />
                <FaCheck style={{ color: "#84c000" }} /> Compliance Monitoring
                &amp; Solutions
              </td>
              <td
                style={{
                  borderRight: "0px solid #e1e0e0",
                  padding: "8px",
                  width: "50%",
                }}
              >
                <FaCheck style={{ color: "#84c000" }} /> Digital Imaging
                Services
                <br />
                <FaCheck style={{ color: "#84c000" }} /> Copyright-Related Legal
                Services
                <br />
                <FaCheck style={{ color: "#84c000" }} /> Management Consulting{" "}
                <br />
                <FaCheck style={{ color: "#84c000" }} /> Paralegal Services For
                Copyright
                <br />
              </td>
            </tr>
          </tbody>
        </table>

        <table
          style={{
            width: "100%",
            border: "0px solid #e1e0e0",
            background: "#fff",
            marginBottom: "10px",
          }}
        >
          <tbody>
            <tr>
              <td
                style={{
                  borderRight: "0px solid #e1e0e0",
                  padding: "8px",
                  width: "70%",
                  verticalAlign: "top",
                }}
              >
                <AiFillCaretRight style={{ color: "#84c000" }} />
                <strong> GSA:</strong>{" "}
                <a
                  href="https://www.gsaelibrary.gsa.gov/ElibMain/contractorInfo.do?contractNumber=47QTCA19D00A2&contractorName=EIGENNET%2C+LLC&executeQuery=YES"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  47QTCA19D00A2, 47QTCA19D00N3
                </a>
                <br />
                <AiFillCaretRight style={{ color: "#84c000" }} />
                <strong> GSA Categories:</strong> 493110RM, 518210C, 518210DC,
                518210ERM, 541110, 541511, 54151HEAL, 54151S, 541611, 541690,
                561439, OLM and 561320SBSA
                <br />
                <AiFillCaretRight style={{ color: "#84c000" }} />
                <strong> UEI:</strong> XTA5EN9KNJN5 / <strong>DUNS:</strong>{" "}
                080267682 / <strong>Cage Code:</strong> 7MSQ9
                <br />
                <AiFillCaretRight style={{ color: "#84c000" }} />
                <strong> PSC CODE:</strong> AJ11 R&amp;D
                <br />
                <AiFillCaretRight style={{ color: "#84c000" }} />
                <strong> NAICS CODES:</strong> 541511, 517410, 518210, 519130,
                541330, 541519, 541611, 541612, 541614,&nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;541618, 541690, 541715,
                541990, 611420
              </td>
              <td
                style={{
                  borderRight: "0px solid #e1e0e0",
                  padding: "8px",
                  width: "30%",
                }}
              >
                <a
                  href="https://www.gsaelibrary.gsa.gov/ElibMain/contractorInfo.do?contractNumber=47QTCA19D00A2&contractorName=EIGENNET LLC&executeQuery=YES"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  <img
                    className="alignleft"
                    src={gsa}
                    alt="ab2"
                    style={{ border: "10px solid #fff", width: "90%" }}
                  />
                </a>
              </td>
            </tr>
          </tbody>
        </table>

        <p style={{ fontSize: "20px", fontWeight: "bold", color: "#15244f" }}>
          Our Capabilities
        </p>
        <p>
          <strong style={{ color: "#15244f" }}>
            AI &amp; Machine Learning:{" "}
          </strong>
          Objective centered analytic solutions leveraging open source and
          enterprise tools for predictive modeling and machine learning needs.
          Our Data Science team has a proven record for benchmarking model
          results by adopting highly customized statistical algorithms.
        </p>
        <p>
          <strong style={{ color: "#15244f" }}>
            Natural Language Processing:{" "}
          </strong>
          Automation of text processing in several languages and checking for
          compliance per federal regulations with the use of algorithms and
          custom NLP methods.
        </p>
        <p>
          <strong style={{ color: "#15244f" }}>Computer Vision: </strong>
          Development of computers and software systems to derive meaningful
          information from digital images, documents, videos, and other visual
          inputs. Taking subsequent actions and making recommendations to
          multiple agencies, based on the information processed.
        </p>
        <p>
          <strong style={{ color: "#15244f" }}>Robotics: </strong>Setup and
          operationalization of commercial off-the shelf robotic systems for
          repetitive physical tasks, material handling and dynamic monitoring
          systems.
        </p>
        <p>
          <strong style={{ color: "#15244f" }}>Augmented Reality: </strong>
          Development of custom data scripts to create robust object recognition
          and positional tracking solutions for mobile based augmented reality
          systems, to understand the environment and to blend digital content
          into the real world. Our custom data scripts use AI and machine
          learning technologies to aid augmented reality-based applications to
          quickly process the information received from the imaging systems
          namely cameras to effectively track the surrounds and to help display
          content relevant to the user.
        </p>
        <p>
          <strong style={{ color: "#15244f" }}>Statistical Analysis: </strong>We
          offer an end-to-end statistical analysis and reporting services
          starting from surveying, data sampling, transformations, data
          warehousing, analysis, dashboarding and reporting. Our personnel
          operating in the mathematical and statistical domain have years of
          experience in extracting meaning from data and deriving business value
          often using Agile practices.
        </p>
        <p>
          <strong style={{ color: "#15244f" }}>
            Custom Application Development:{" "}
          </strong>{" "}
          Development and integration of critical applications utilizing .NET,
          Java, Ruby, Python and other technologies through managed services or
          distributed development. We will assist in navigating issues of
          multi-tenancy, operational support, requirements management and
          security to ensure a cohesive platform while keeping data secure.
        </p>

        <MediaQuery minWidth={930}>
          <p style={{ fontSize: "20px", fontWeight: "bold", color: "#15244f" }}>
            Past Performances
          </p>
          <p>
            <AiFillCaretRight style={{ color: "#84c000" }} />
            <strong>
              {" "}
              Document Conversion &amp; Digitization for Library of Congress
            </strong>{" "}
            using automated text extraction and quality control driven by custom
            data scripts and optical character recognition for an end-to-end
            conversion of documents Including bulk conversion of PDF and Images
            to Text. Efficient and accurate quality control of the digitized
            documents using an AI based application to identify and rectify the
            errors with minimal manual effort
          </p>
          <p>
            <AiFillCaretRight style={{ color: "#84c000" }} />
            <strong>
              {" "}
              Independent Testing of the Paid Family Leave Tax System
            </strong>{" "}
            within the Department of Employment Services (DOES) to develop
            plans, execution of test cases, and conduct reviews of configuration
            items and architectural designs to provide independent testing of
            the System.
          </p>
          <p>
            <AiFillCaretRight style={{ color: "#84c000" }} />
            <strong> Paralegal Services for Copyright Office</strong> by
            reviewing and verifying incoming documents and applications for
            compliance. Also administering 508 filings, litigations, and filing
            certificates for all applications.
          </p>
          <p>
            <AiFillCaretRight style={{ color: "#84c000" }} />
            <strong> Big data infrastructure and computing for Optum,</strong> a
            Healthcare Organization. Performed end-to-end set-up and maintenance
            of data engineering pipelines to serve various business reports,
            extracted data from different sources, transforming and loading it
            into HDFS.
          </p>
          <p>
            <AiFillCaretRight style={{ color: "#84c000" }} />
            <strong> Warning indicators for NHTSA</strong> (National Highway
            Safety Association) product recalls using machine learning models
            and natural language processing for DOT.
          </p>
          <p>
            <AiFillCaretRight style={{ color: "#84c000" }} />
            <strong>
              {" "}
              Established Data Lakes &amp; Enterprise Data Hubs
            </strong>{" "}
            for private medium and large size businesses (Nike, Bank of America)
            for high performance distributed computing on top of a central data
            repository which included relational, transactional &amp;
            unstructured data.
          </p>
        </MediaQuery>

        <MediaQuery maxWidth={929}>
          <p style={{ fontSize: "20px", fontWeight: "bold", color: "#15244f" }}>
            Past Performances
          </p>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="row">
                <div className="col-1">
                  <AiFillCaretRight style={{ color: "#84c000" }} />
                </div>
                <div className="col-11">
                  <p>
                    <strong>
                      {" "}
                      Document Conversion &amp; Digitization for Library of
                      Congress
                    </strong>{" "}
                    using automated text extraction and quality control driven
                    by custom data scripts and optical character recognition for
                    an end-to-end conversion of documents Including bulk
                    conversion of PDF and Images to Text. Efficient and accurate
                    quality control of the digitized documents using an AI based
                    application to identify and rectify the errors with minimal
                    manual effort
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-1">
                  <AiFillCaretRight style={{ color: "#84c000" }} />
                </div>
                <div className="col-11">
                  <p>
                    <strong>
                      {" "}
                      Independent Testing of the Paid Family Leave Tax System
                    </strong>{" "}
                    within the Department of Employment Services (DOES) to
                    develop plans, execution of test cases, and conduct reviews
                    of configuration items and architectural designs to provide
                    independent testing of the System.
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-1">
                  <AiFillCaretRight style={{ color: "#84c000" }} />
                </div>
                <div className="col-11">
                  <p>
                    <strong> Paralegal Services for Copyright Office</strong> by
                    reviewing and verifying incoming documents and applications
                    for compliance. Also administering 508 filings, litigations,
                    and filing certificates for all applications.
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-1">
                  <AiFillCaretRight style={{ color: "#84c000" }} />
                </div>
                <div className="col-11">
                  <p>
                    <strong>
                      {" "}
                      Big data infrastructure and computing for Optum,
                    </strong>{" "}
                    a Healthcare Organization. Performed end-to-end set-up and
                    maintenance of data engineering pipelines to serve various
                    business reports, extracted data from different sources,
                    transforming and loading it into HDFS.
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-1">
                  <AiFillCaretRight style={{ color: "#84c000" }} />
                </div>
                <div className="col-11">
                  <p>
                    <strong> Warning indicators for NHTSA</strong> (National
                    Highway Safety Association) product recalls using machine
                    learning models and natural language processing for DOT.
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-1">
                  <AiFillCaretRight style={{ color: "#84c000" }} />
                </div>
                <div className="col-11">
                  <p>
                    <strong>
                      {" "}
                      Established Data Lakes &amp; Enterprise Data Hubs
                    </strong>{" "}
                    for private medium and large size businesses (Nike, Bank of
                    America) for high performance distributed computing on top
                    of a central data repository which included relational,
                    transactional &amp; unstructured data.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>

        <MediaQuery minWidth={930}>
          <p style={{ fontSize: "20px", fontWeight: "bold", color: "#15244f" }}>
            Differentiators
          </p>
          <p>
            <FaCheck style={{ color: "#84c000" }} /> Key staff members, each
            with over 15 years each of IT &amp; relevant experience each working
            at high security client site facilities.
          </p>
          <p>
            <FaCheck style={{ color: "#84c000" }} /> Development of in-house
            software products (RFP Engine and Doc2data.ai) and custom data
            scripts which are currently utilized by Federal agencies for the
            purposes of AI based document conversion and automated compliance.
          </p>
          <p>
            <FaCheck style={{ color: "#84c000" }} /> 100% Certified key
            personnel staff in the areas including but not limited to (PMP,
            ITIL, AWS Cloud, Azure Cloud, Six Sigma).
          </p>
          <p>
            <FaCheck style={{ color: "#84c000" }} /> Proven Rapid onboarding
            process to provide a superior workforce to clients.
          </p>
        </MediaQuery>

        <MediaQuery maxWidth={929}>
          <p style={{ fontSize: "20px", fontWeight: "bold", color: "#15244f" }}>
            Differentiators
          </p>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="row">
                <div className="col-1">
                  <FaCheck style={{ color: "#84c000" }} />
                </div>
                <div className="col-11">
                  <p>
                    Key staff members, each with over 15 years each of IT &amp;
                    relevant experience each working at high security client
                    site facilities.
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-1">
                  <FaCheck style={{ color: "#84c000" }} />
                </div>
                <div className="col-11">
                  <p>
                    {" "}
                    Development of in-house software products (RFP Engine and
                    QCtitle.io) and custom data scripts which are currently
                    utilized by Federal agencies for
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the purposes of AI
                    based document conversion and automated compliance.
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-1">
                  <FaCheck style={{ color: "#84c000" }} />
                </div>
                <div className="col-11">
                  <p>
                    {" "}
                    100% Certified key personnel staff in the areas including
                    but not limited to (PMP, ITIL, AWS Cloud, Azure Cloud, Six
                    Sigma).
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-1">
                  <FaCheck style={{ color: "#84c000" }} />
                </div>
                <div className="col-11">
                  <p>
                    {" "}
                    Proven Rapid onboarding process to provide a superior
                    workforce to clients.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>

        <p style={{ fontSize: "20px", fontWeight: "bold", color: "#15244f" }}>
          Certifications:
        </p>
        <table
          style={{
            width: "100%",
            border: "0px solid #e1e0e0",
            background: "#fff",
            marginBottom: "10px",
          }}
        >
          <tbody>
            <tr>
              <td
                style={{
                  borderRight: "0px solid #e1e0e0",
                  padding: "0px 8px",
                  width: "50%",
                  color: "#1c2951",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                CMMI Certifications
              </td>
              <td
                style={{
                  borderRight: "0px solid #e1e0e0",
                  padding: "0px 8px",
                  width: "50%",
                  color: "#1c2951",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                ISO Certifications
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: "0px solid #e1e0e0",
                  padding: "0px 8px",
                  width: "50%",
                }}
              >
                <FaCheck style={{ color: "#84c000" }} /> CMMI Development Level
                2 Certification
                <br />
                <FaCheck style={{ color: "#84c000" }} /> CMMI Service Level 2
                Certification
              </td>
              <td
                style={{
                  borderRight: "0px solid #e1e0e0",
                  padding: "8px",
                  width: "50%",
                }}
              >
                <FaCheck style={{ color: "#84c000" }} /> ISO 9001:2015
              </td>
            </tr>
          </tbody>
        </table>

        <MediaQuery minWidth={930}>
          <p>
            <img src={cmmi} style={{ width: "50%" }} alt="ab2" />
            <img
              src={iso}
              style={{ width: "12%", marginTop: "20px" }}
              alt="ab2"
            />
          </p>
          <div className="clear">&nbsp;</div>
        </MediaQuery>

        <MediaQuery maxWidth={930}>
          <p>
            <img src={cmmi} style={{ width: "100%" }} alt="ab2" />
            <img
              src={iso}
              style={{ width: "30%", marginTop: "20px" }}
              alt="ab2"
            />
          </p>
          <div className="clear">&nbsp;</div>
        </MediaQuery>
      </div>
      <br />
      <br />
    </>
  );
}
