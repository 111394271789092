import React from "react";
import AboutUsBackground from "../Assets/AboutUs_bg.png";
import GetInTouchFooter from "./GetInTouchFooter_siddu";
import MediaQuery from "react-responsive";

import cmmidev from "../Assets/Img/cmmidevorg.png";
import iso from "../Assets/Img/iso.png";
import cmmisv from "../Assets/Img/cmmisvcorg.png";

export default function AboutUs() {
  const textColor = "#1C2951";
  const paraFontSize = "18px";
  return (
    <div
      className=""
      style={{
        marginTop: "70px",
        // background: "#eef1ef",
      }}
    >
      <div
        className=" position-relative "
        style={{
          // backgroundImage: `url(${AboutUsBackground})`,
          // backgroundSize: "cover",
          // backgroundPosition: "center",
          marginBottom: "20px",
          // paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <img
          src={AboutUsBackground}
          style={{ opacity: "10%" }}
          className=" d-bblock w-100 h-100 position-absolute top-0 bottom-0"
        />
        <div className="container text-black py-5 z-1 ">
          <div>
            <MediaQuery maxWidth={575}>
              <h3 className=" text-center " style={{ color: textColor }}>
                About Eigen
                <span
                  style={{
                    color: "#84c000",
                  }}
                >
                  net
                </span>
              </h3>
            </MediaQuery>
            <MediaQuery minWidth={575}>
              <h1 className=" text-center " style={{ color: textColor }}>
                About Eigen
                <span
                  style={{
                    color: "#84c000",
                  }}
                >
                  net
                </span>
              </h1>
            </MediaQuery>
            {/* <MediaQuery maxWidth={575}>
              <h5
                className=" pb-0"
                style={{
                  color: textColor,
                  // fontWeight: "700",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                }}
              >
                Harness the power of digital transformation comprehensive and
                secure solutions for federal agencies seeking to modernize their
                document management processes.
                About Eigennet Unleashing innovative technology to drive your
                success: our story of comprehensive and forward-looking
                solutions.
              </h5>
            </MediaQuery>
            <MediaQuery minWidth={575}>
              <h4
                className=" pb-0"
                style={{
                  color: textColor,
                  // fontWeight: "700",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                }}
              >
                Harness the power of digital transformation comprehensive and
                secure solutions for federal agencies seeking to modernize their
                document management processes.
                About Eigennet Unleashing innovative technology to drive your
                success: our story of comprehensive and forward-looking
                solutions.
              </h4>
            </MediaQuery> */}
          </div>
        </div>
      </div>
      {/** AI as a Service  */}
      <div className="container">
        <SectionSeperatorBlueLine />
        {/* <h2>AI as a Service</h2> */}
        <SubHeading heading="Our Team" />
        {/* <p style={{ fontSize: paraFontSize }}>
          Program your System to Perform human-like tasks.
        </p> */}
        <div className=" row gap-2">
          <ServiceCard
            img={require("../Assets/OurTeam.png")}
            title="Our Team"
            // description="We are a team of IT professionals highly focused in the services we provide and uphold our motto “Solution Driven Technology” as we put forth the best tools and solutions in a cost-effective manner. We are committed to the areas of Analytics and predict a bright future in this domain with our eye on next generation technologies. Our solutions are technology based but coupled with best development practices and leaner operating principles for high customer value."
            description={`Our team at Eigennet delivers top-tier solutions with a relentless focus. We embody "Solution Driven Technology," tailoring cost-effective tools and strategies to our clients' needs. With unwavering commitment, we bring unique expertise to achieve exceptional outcomes. Leveraging next-generation technologies and adhering to best practices, we ensure high-value results, driving success in the ever-evolving IT landscape. Collaborating seamlessly, we stand ready to overcome challenges and exceed expectations.`}
          />
          <ServiceCard
            img={require("../Assets/Why_Eigennet.png")}
            title="Why Eigennet?"
            // Template content
            // description="Eigennet is transparent with our customers throughout the process whether it involves developing new software or maintaining existing functionality. Our experience on essential Federal projects gives us an edge in understanding the vital and important nature of the government’s business. In other industries Eigennet has worked directly with non-profit organizations, the healthcare industry and various sectors of the finance industry. "
            description="Eigennet, LLC offers unparalleled expertise in management and IT solutions, certified with CMMI DEV&SVC ML 2 & ISO 9001:2015. Specializing in end-to-end document processing services, we harness the power of AI and machine learning to augment existing processes for efficient and reliable results. With a customer-centric approach and a proven track record serving government and non-governmental organizations, Eigennet delivers tailored solutions to meet your needs with precision and excellence."
          />
        </div>
      </div>

      {/* Who we serve */}
      <div className="container">
        <SectionSeperatorBlueLine />
        {/* <h2 style={{ fontSize: subheadingFontFise }}>Who We Serve?</h2> */}
        <SubHeading heading="Who We Serve?" />
        <p style={{ color: textColor, fontSize: paraFontSize }}>
          {/* We cater to various industries that could use our expertise from
          Federal, State and Local governments, the non-profit sector, the
          finance industry and healthcare. We realize IT challenges come in all
          shapes and sizes and that customers have different needs based on
          their business. */}
          Eigennet serves a diverse range of customers including federal, state,
          local governments, non-profits, finance, and healthcare. Eigennet
          understand that IT challenges vary widely, and customer needs depend
          on their specific business requirements.
        </p>
        <div className=" row">
          <ServiceCard
            img={require("../Assets/Government.png")}
            title="Government"
            // description="Eigennet stands ready to support federal agencies in their vital work, recognizing the challenges posed by evolving budgets and regulations. We also understand the diverse IT challenges faced by state and local governments due to resource constraints. We're committed to partnering with agencies at all levels to develop solutions that benefit our communities "
            description="Eigennet has extensive experience providing comprehensive IT solutions, supporting federal, state, and local government agencies in achieving their objectives. Eigennet’s team actively navigates the dynamic landscape of budgets, regulations, and outcomes, ensuring our solutions remain adaptable and effective in meeting evolving challenges. We successfully deliver tailored IT services to numerous government entities, addressing diverse challenges and resource limitations with efficiency and expertise at the federal, and state levels."
          />
          <ServiceCard
            img={require("../Assets/Healthcare.png")}
            title="Healthcare"
            // description="In the healthcare industry, compliance, patient care, and technology expenses are on the rise. Managing the surge in digital data and mobile data access adds complexity. Eigennet addresses these concerns by securely managing data, adapting to mobile apps, and providing accurate representations of organizational outcomes. Our expertise helps healthcare organizations operate efficiently. "
            description="Eigennet excels in navigating the complex healthcare environment, addressing governmental mandates, patient needs, workforce retention, and insurance changes. With expertise in data management, mobile app integration, and security, we enable efficient operations amidst rising technology costs and data volume. Eigennet empowers healthcare stakeholders with accurate insights, mitigating privacy and liability concerns for improved industry performance."
          />
          <ServiceCard
            img={require("../Assets/Non_profit.png")}
            title="Non-Profit"
            // description="Non-Profit Organizations face funding uncertainties and competition, striving to make every dollar count. Eigennet aids them with internet and social media analytics for cost-effective online marketing. We identify trends and specific geographic targets, helping these organizations meet their goals with maximum benefits."
            description="Eigennet recognizes the critical role of Non-Profits in development, facing limited visibility and funding challenges. Economic downturns and funding competition pose hurdles, while technological advancements remain unexplored due to budget constraints. Eigennet leverages internet and social media analytics to facilitate targeted online marketing campaigns, optimizing resources for impact. Our data-driven approach empowers non-profits to achieve goals efficiently."
          />
          <ServiceCard
            img={require("../Assets/Finance.png")}
            title="Finance"
            // description="The financial industry relies heavily on Big Data. We address global impacts, mobile solutions, and business continuity. Eigennet's industry knowledge ensures secure data management, risk assessment, and compliance fulfillment. Our Big Data expertise offers secure data storage, presentation, and efficient business intelligence solutions with quick implementation, vital in a time-sensitive industry."
            description="Eigennet understands the finance industry’s reliance on data and records, especially big data and records in the form of finance documents. Eigennet offers expertise in secure data handling, data clean-up and data warehouse for finance organizations dealing with large amounts of data. Eigennet also offers a variety of services from records management to processing that can effectively aid financial organizations looking to streamline workflows and to reduce manual effort. "
          />
        </div>
      </div>

      {/* Work Models */}
      <div className="container">
        <SectionSeperatorBlueLine />
        {/* <h2 style={{ fontSize: subheadingFontFise }}>Work Models</h2> */}
        <SubHeading heading="Work Models" />
        <p style={{ fontSize: paraFontSize, color: textColor }}>
          {/* Eigennet aims to satisfy our customers by engaging them throughout the
          lifecycle learning about their business and delivering solutions to
          meet their needs. We will coordinate with you on getting your project
          off the ground by focusing on your most important needs so it will
          bring value to your organization. Our team is experienced in using
          industry best practices to help you reduce costs by using lean
          operating principles. */}
          Eigennet is committed to customer satisfaction, engaging clients at
          every step, from understanding their business to delivering
          personalized solutions. We work closely with clients to prioritize
          their essential requirements, adding value to their organization. By
          employing industry best practices, we facilitate cost reduction
          through lean operating principles.
        </p>
        <div className=" row">
          <ServiceCard
            img={require("../Assets/Agile.png")}
            title="Agile"
            // description="Eigennet endorses Agile development for its transparency, rapid results, and adaptability to change. Embracing daily and weekly 'scrums,' we empower teams and focus on customer priorities. Agile features thoughtful Sprint planning, team improvement, and aligns with our motto 'Solution-Driven Technology' for smart, efficient, and cost-effective solutions."
            description={`Eigennet advocates for Agile methodology in development due to its transparency, rapid results, and adaptability to unforeseen changes. We fully embrace daily scrums and weekly scrum of scrums, empowering teams and fostering commitment towards goal attainment. Customers play a pivotal role in setting product backlog priorities, ensuring focus on pressing needs. Thoughtful sprint planning ensures achievable commitments within short development cycles. Agile aligns with our motto of "Solution Driven Technology," emphasizing smart, efficient, and cost-effective solutions.`}
          />
          <AgileManifestoCard
            // img={""}
            img={require("../Assets/Agile_manifesto.png")}
            title="Gain More with Agile"
            description="Agile Development Over Traditional Software Development"
          />
        </div>
      </div>

      <div className="container">
        <SectionSeperatorBlueLine />
        {/* <h2 style={{ fontSize: subheadingFontFise }}>Certifications</h2> */}
        <SubHeading heading="Certifications" />
        <p style={{ color: textColor, fontSize: paraFontSize }}>
          Below are approved Certifications for Eigennet and Its Employees
        </p>
        <img
          className="py-1 col-12 col-md-5 col-lg-4"
          src={cmmidev}
          alt="certifications"
          // className="col-2"
          style={{ objectFit: "contain" }}
          // style={{ width: "13em", marginRight: "0.5em", height: "82px" }}
        />
        <img
          className="py-1 col-12 col-md-5 col-lg-4"
          src={cmmisv}
          alt="certifications"
          style={{ objectFit: "contain" }}
          // style={{ width: "17em", marginRight: "0.5em", height: "82px" }}
        />
        <img
          className="py-1 col-5 col-md-2 mx-auto mx-md-0 "
          src={iso}
          alt="certifications"
          style={{ objectFit: "contain" }}
          // style={{ width: "8em", marginRight: "0.5em" }}
        />
      </div>

      <GetInTouchFooter page="ABOUTUS" />
    </div>
  );
}

function ServiceCard({ img, title, description }) {
  const textColor = "#1C2951";
  return (
    <div className="col-12 col-md-6 p-1">
      <div className="p-1 p-lg-2 d-flex flex-column h-100">
        <img
          src={img}
          style={{ height: "188px", background: "black" }}
          className="w-100"
          alt="..."
        />
        <div
          className="py-1 px-3 h-100"
          style={{
            color: textColor,
            borderBottom: "1px solid rgb(28, 41, 81,0.25)",
            borderInline: "1px solid rgb(28, 41, 81,0.25)",
          }}
        >
          <h5
            className=" px-0 py-2 m-0"
            style={{ color: textColor, fontSize: "24px" }}
          >
            {title}
          </h5>
          <p
            className="px-0 m-0 pt-0 pb-3 pb-md-5"
            style={{ color: textColor, fontSize: "16px", textAlign: "justify" }}
          >
            {description}
          </p>
        </div>
      </div>
    </div>
  );
}

function AgileManifestoCard({ img, title, description }) {
  const textColor = "#1C2951";
  return (
    <div className="col-12 col-md-6 p-1">
      <div
        className="d-flex flex-column h-100 p-1 p-lg-2"
        // style={{
        //   border: "1px solid black",
        // }}
      >
        <img
          src={img}
          className="w-100"
          alt="..."
          style={{
            height: "233px",
            borderTop: "1px solid rgb(28, 41, 81,0.25)",
            borderLeft: "1px solid rgb(28, 41, 81,0.25)",
            borderRight: "1px solid rgb(28, 41, 81,0.25)",
          }}
        />
        <div
          className=" py-1 px-3 h-100"
          style={{
            color: textColor,
            borderBottom: "1px solid rgb(28, 41, 81,0.25)",
            borderLeft: "1px solid rgb(28, 41, 81,0.25)",
            borderRight: "1px solid rgb(28, 41, 81,0.25)",
          }}
        >
          <h5 className=" p-0 m-0" style={{ fontSize: "24px" }}>
            {title}
          </h5>
          <p className="p-0 m-0 pb-5" style={{ fontSize: "16px" }}>
            {description}
          </p>
        </div>
      </div>
    </div>
  );
}

function SubHeading({ heading }) {
  const textColor = "#1C2951";
  return (
    <>
      <MediaQuery maxWidth={575}>
        <h2 className="h4" style={{ color: textColor, fontWeight: "700" }}>
          {heading}
        </h2>
      </MediaQuery>
      <MediaQuery minWidth={575}>
        <h2 className="h3" style={{ color: textColor, fontWeight: "700" }}>
          {heading}
        </h2>
      </MediaQuery>
    </>
  );
}

function SectionSeperatorBlueLine() {
  return (
    <div
      className=" "
      style={{
        width: "100px",
        height: "8px",
        background: "#1C2951",
        marginTop: "30px",
        marginBottom: "20px",
      }}
    ></div>
  );
}
