import React,{useEffect} from 'react'

import scrum from '../Assets/Img/scrum.jpg'
import agile from '../Assets/Img/agile_manifesto.png'
import "../index.css";

export default function WorkModels() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <div className='container' style={{boxShadow:'0px 0px 10px rgba(51, 51, 51, 0.04)',marginTop:'70px'}}>
      <h2 className="col-title" style={{borderBottom: '1px solid black'}}>WORK MODELS</h2>
      <p  className='mobile-responsive'>Eigennet aims to satisfy our customers by engaging them throughout the lifecycle learning about their business and delivering solutions to meet their needs. We will coordinate with you on getting your project off the ground by focusing on your most important needs so it will bring value to your organization. Our team is experienced in using industry best practices to help you reduce costs by using lean operating principles.</p>
      <h2 className="col-title" style={{borderBottom: '1px solid black'}}>Agile</h2>
      
      <p>Develop quality working products rapidly in a team environment.</p>


      <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4"><img className="alignleft wp-image-129" style={{marginBottom: '20px',width:'-webkit-fill-available'}} src={scrum} alt="aglis" />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-8">
            <h4>Scrum, Scrum, Scrum</h4>

            <p className='mobile-responsive'>Eigennet recommends the Agile methodology in development for its transparency, rapid results and flexibility to address unexpected changes. We truly embrace the daily “scrum” and/or weekly “scrum of Scrums” because it empowers the working team, is commitment-driven towards the end-goal, which ultimately leads to results-oriented products. The customer is directly involved in setting the priority in the product backlog so the team can focus on their most pressing needs. In Agile, we have thoughtful Sprint planning so our commitment to the customer’s priority can be achievable during the short development cycle. The team also has an opportunity to improve, refine and enhance their work during retrospective meetings. The Agile methodology marries with our motto “Solution Driven Technology” which focuses on producing technology which is smart, efficient and cost effective.</p>
            </div>

</div>
      <h2 className="col-title" style={{borderBottom: '1px solid black'}}>Gain More with Agile</h2>
      <p>Agile Development Over Traditional Software Development</p>
      <div><img src={agile} alt="Agile Manifesto" style={{marginBottom: '20px',width:'-webkit-fill-available'}}/></div>

      </div>
      <br/>
      <br/>
      </>
  )
}
