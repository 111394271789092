import React,{useEffect} from 'react'

import gov from '../Assets/Img/gov-nw.jpg'
import healthcare from '../Assets/Img/health.jpg'
import nonprofit from '../Assets/Img/non-profit.jpg'
import finance from '../Assets/Img/finance1.jpg'
import "../index.css";


export default function WhoWeServe() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <div className='container' style={{boxShadow:'0px 0px 10px rgba(51, 51, 51, 0.04)',marginTop:'70px'}}>
                <h2 className="col-title" style={{borderBottom: '1px solid black'}}>WHO WE SERVE</h2>
                <p>We cater to various industries that could use our expertise from Federal, State and Local governments, the non-profit sector, the finance industry and healthcare. We realize IT challenges come in all shapes and sizes and that customers have different needs based on their business.</p>
                <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-5"><img className="alignleft wp-image-169"style={{marginBottom: '20px',width:'-webkit-fill-available'}} src={gov} alt="fds"  />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-7">
            <h4>Government</h4>

            <p className='mobile-responsive'>The federal government conducts vital work for the benefit of our nation; Eigennet understands this importance and is ready to put our capabilities to work in supporting agencies in meeting their goals. We understand there are constant changes in budgets, regulations and unintended outcomes. We believe that the strength of our capabilities will help agencies meet those evolving challenges. At the state and local level, we know that IT challenges come in all shapes and sizes and often a lack of resources. Eigennet values the work of our local governments and realizes the impact on our communities. We are prepared to partner with State and Local agencies to develop solutions that will benefit our localities.</p>
</div>
</div>

<div className="clear">&nbsp;</div>
<div style={{borderBottom: '1px solid black'}}></div><br/>

<div className="row">
            <div className="col-sm-12 col-md-6 col-lg-5"><img className="alignleft wp-image-169" style={{marginBottom: '20px',width:'-webkit-fill-available'}} src={healthcare} alt="finance"/>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-7">
            <h4>Healthcare</h4>

            <p className='mobile-responsive'>The healthcare industry operates in a complex environment that includes mandates from government, addressing patient needs, retaining a reliable workforce and keeping track of changes in the insurance industry. These demands on the industry have led to overall increased costs in technology. Furthermore, the amount of digital data is rising exponentially and the consumers of that data have gradually moved towards using mobile devices to access the data. There are many questions and concerns from privacy to security and liability for healthcare providers. Eigennet is skilled in managing data, comprehending the shift to mobile apps, and safely converting data into accurate representations of organisational outcomes. These experiences will enable organizations in the healthcare industry to operate more efficiently.</p>
</div>
</div>
<div className="clear">&nbsp;</div>
<div style={{borderBottom: '1px solid black'}}></div><br/>
<div className="row">
            <div className="col-sm-12 col-md-6 col-lg-5"><img className="alignleft wp-image-169" style={{marginBottom: '20px',width:'-webkit-fill-available'}} src={nonprofit} alt="fds"/>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-7">
            <h4>Non-Profit</h4>

            <p  className='mobile-responsive'>Non-Profit Organizations often take the place government in doing crucial work to help develop our communities. They often function with very little visibility and struggle to stay afloat due to uncertainties in funding. Downturns in the economy often puts a strain on funding sources and an increase in the number of Non-Profit Organizations makes it harder to compete for funds. Meanwhile advances in technology have gone wayside because these organizations have to make every dollar count. Eigennet’s internet and social media analytics can help with online marketing campaigns by identifying trends and help organizations to identify specific geographical targets. Our team can help you to meet your goals by examining data that will ultimately give you the maximum benefit with low-cost solutions.
            </p>
            </div>
</div>
<div className="clear">&nbsp;</div>
<div style={{borderBottom: '1px solid black'}}></div><br/>
<div className="row">
            <div className="col-sm-12 col-md-6 col-lg-5"><img className="alignleft wp-image-169" style={{marginBottom: '20px',width:'-webkit-fill-available'}} src={finance} alt="finance"/>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-7">
            <h4>Finance</h4>

            <p className='mobile-responsive'>The financial industry is one the top consumers of Big Data solutions. The industry is in a unique position where there are customer demands for mobile solutions, global impacts and the need for business continuity. We realize the impact of global markets can trigger major overhauls of financial products, systems and interfaces. We have working knowledge in the industry to help your organization to secure client data, assess and mitigate potential risks and meet your compliance obligations. Eigennet’s expertise in Big Data can help to securely store data, present data and customize business intelligence solutions that are efficient, cost-effective and can be implemented in shorter turn-around time. This is trivial in an industry where time is crucial.</p>
            </div>
</div>

    </div>
    <br/>
    <br/>
    </>
  )
}
