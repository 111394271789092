import { useEffect, useState } from "react";
import footlogo from "../Assets/Img/navbar_logo.svg";
export default function GetInTouchFooter({ page }) {
  const [errorState, setErrorState] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  useEffect(() => {
    if (submitted) {
      setTimeout(() => {
        setSubmitted(false);
      }, 3000);
    }
  }, [submitted]);
  const getInTouchFormSubmit = async (event) => {
    event.preventDefault();
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{3,})$/;
    const nameRegex = /^[a-zA-Z ]{2,40}$/;
    const formData = new FormData(event.target);
    const currentErrorState = {};

    if (!emailRegex.test(formData.get("email").trim())) {
      currentErrorState.email = "Please enter a valid email address";
    }
    if (!nameRegex.test(formData.get("name").trim())) {
      currentErrorState.name = "Please enter a valid Name";
    }
    const subject = formData.get("subject").trim();
    if (subject.length < 2) {
      currentErrorState.subject = "Subject must atleast be 2 characters";
    }
    if (subject.length > 100) {
      currentErrorState.subject = "Subject must be less than 100 characters";
    }
    const message = formData.get("message").trim();
    if (message.length < 2) {
      currentErrorState.message = "Message must atleast be 2 characters";
    }
    if (message.length > 250) {
      currentErrorState.message = "Message must be less than 250 characters";
    }
    setErrorState(currentErrorState);
    if (Object.keys(currentErrorState).length > 0) {
      return;
    }

    const inputData = {};
    formData.forEach(function (value, key) {
      inputData[key] = value;
    });
    inputData["page"] = page;

    // TODO: include an api call
    try {
      setSubmitting(true);
      const res = await fetch(
        "https://ljj6xe1ryd.execute-api.us-east-1.amazonaws.com/Beta/Eigennet-contact",
        {
          method: "POST",
          "content-type": "application/json",
          body: JSON.stringify(inputData),
        }
      );
      if (res.status !== 200) {
        throw new Error("Something went wrong");
      }
      event.target.reset();
      setSubmitting(false);
      setSubmitted(true);
    } catch (e) {
      console.log("error is sending", e);
    }
  };
  const textColor = "#1C2951";
  return (
    <div className="bg-white" style={{ padding: "3rem 0" }}>
      <div className=" container">
        {/* <div className="row flex-wrap-reverse"> */}
          {/* <div className="d-flex flex-column col-12 col-lg-7"> */}
            <h1
              className=" text-nowrap m-0"
              style={{ color: textColor, fontSize: "2rem" }}
            >
              Get In Touch
            </h1>
            <form
              className="d-flex flex-wrap pt-2"
              onSubmit={getInTouchFormSubmit}
            >
              <FormFiled
                label="Name"
                name="name"
                id="name"
                className=" col-12 col-sm-6 pl-0"
                error={errorState.name}
                disabled={submitting}
              />
              <FormFiled
                label="Email Address"
                name="email"
                id="email-id"
                className=" col-12 col-sm-6 pl-0"
                type="email"
                error={errorState.email}
                disabled={submitting}
              />
              <FormFiled
                label="Subject"
                name="subject"
                id="subject"
                className=" col-12 col-sm-6 pl-0"
                type="text"
                error={errorState.subject}
                disabled={submitting}
              />
              <FormFiled
                label="Message"
                name="message"
                id="message"
                className=" col-12 col-sm-6 pl-0"
                type="text"
                error={errorState.message}
                disabled={submitting}
              />
              <button
                className="btn w-50 text-white mt-3 mx-auto"
                style={{ backgroundColor: "#89c000" }}
                disabled={submitting}
              >
                {/* Send Message */}
                {submitted
                  ? "Message Sent 👍"
                  : submitting
                  ? "Sending message"
                  : "Send Message"}
              </button>
            </form>
          </div>
          {/* <div className="col-5 d-none d-lg-flex flex-column pb-3 pb-md-0">
            <h1
              className=" text-nowrap "
              style={{ color: textColor, fontSize: "2rem" }}
            >
              Our Company
            </h1>
            <div
              className="d-flex justify-content-center align-items-center flex-grow-1"
              style={{
                background: "#eef1ef",
                borderRadius: "15px",
                minHeight: "150px",
              }}
            >
              <img
                src={footlogo}
                className=""
                style={{ width: "250px", height: "48px" }}
              />
            </div>
          </div> */}
        </div>
      // </div>
    // </div>
  );
}

function FormFiled({
  label,
  name,
  id,
  type = "text",
  className,
  error = "",
  disabled,
}) {
  return (
    <div
      className={"pt-3 " + (error !== "" ? "pb-2" : "pb-3") + className}
      style={{ width: "max-content" }}
    >
      <input
        type={type}
        style={{
          outline: "none",
          border: "none",
          borderBottom: "1px solid black",
        }}
        className={"w-100"}
        id={id}
        name={name}
        placeholder={label}
        required
        disabled={disabled}
      />
      <span className="text-danger p-0 m-0" style={{ fontSize: "14px" }}>
        {error}
      </span>
    </div>
  );
}
