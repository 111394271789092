import React, {useEffect} from 'react'
import "../index.css";

import docConversion from "../Assets/Img/doucument-conversion.jpg"


export default function DocumentConversion() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <div className='container' style={{boxShadow:'0px 0px 10px rgba(51, 51, 51, 0.04)',marginTop:'70px'}}>
    <h2 className="col-title mb-3" style={{borderBottom: '1px solid black'}}>DOCUMENT CONVERSION</h2>
<div className='row'>
<div className='col-sm-12 col-md-6 col-lg-5'>
<div className='text-center'>

  <img className="aligncenter" src={docConversion} alt='' style={{width:'-webkit-fill-available'}}/>
  </div>
  </div>

<div className='mobile-responsive col-sm-12 col-md-6 col-lg-7 mt-3' >
  <p>With the experience gathered over the years by converting thousands of documents for different governmental and commercial organizations, Eigennet takes pride in its ability to provide robust end-to-end document conversion services to its clients. With foolproof records management plans and innovative technical solutions in hand, Eigennet can process and convert thousands of documents with supreme accuracy and efficiency. By eliminating the extensive need for manual resources and employing AI-based automated solutions, Eigennet offers a bulk conversion solution to clients.
  </p>
  </div>
</div>

<p className='mobile-responsive mt-4' > 
Eigennet takes special care throughout the conversion cycle, right from arranging the documents adhering to strict management checklists, to indexing the converted files to the database. By automating the process of conversion Eigennet can employ intelligent quality checks on the converted digital data. The quality process is capable enough to identify the various facets of the converted data and can rectify errors automatically. Combining intelligent data extraction tools, tried and tested document processing solutions, and an efficient and accurate quality control tool, Eigennet offers a complete document conversion solution for the bulk conversion of documents.</p>
<br/>
</div>
</>
  )
}
