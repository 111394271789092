import React, { useEffect } from "react";
import "../index.css";
// import pricelist from "../Assets/Docs/Pricelist-edv2.pdf";
import pricelistA2 from "../Assets/Docs/Pricelist-edA2.pdf";
import pricelistN3 from "../Assets/Docs/Pricelist-edN3.pdf";

import gsa from "../Assets/Img/gsa_contracts.png";
import A2 from "../Assets/contracts/A2.png";
import N3 from "../Assets/contracts/N3.png";

export default function Contracts() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div
        className="container"
        style={{
          boxShadow: "0px 0px 10px rgba(51, 51, 51, 0.04)",
          marginTop: "70px",
        }}
      >
        <h2 className="col-title" style={{ borderBottom: "1px solid black" }}>
          CONTRACTS
        </h2>
        <p style={{ textAlign: "justify" }}>
          Eigennet is a small business ready to support the government on a
          broad range of services.{" "}
          <b>
            <a
              href="https://www.gsaelibrary.gsa.gov/ElibMain/contractorInfo.do?contractNumber=47QTCA19D00A2&contractorName=EIGENNET%2C+LLC&executeQuery=YES"
              target="_blank"
              className="contract"
              rel="noopener noreferrer nofollow"
            >
              47QTCA19D00A2
            </a>
          </b>
          ,{" "}
          <b>
            <a
              href="https://www.gsaelibrary.gsa.gov/ElibMain/contractorInfo.do?contractNumber=47QTCA19D00A2&contractorName=EIGENNET%2C+LLC&executeQuery=YES"
              target="_blank"
              className="contract"
              rel="noopener noreferrer nofollow"
            >
              47QTCA19D00N3
            </a>
          </b>
        </p>
        <h2 className="col-title">GSA MAS Schedule</h2>
        <p
          className="mobile-responsive  pb-3"
          style={{
            borderBottom: "1px solid black",
          }}
        >
          General Services Administration (GSA) multiple award schedule (MAS) is
          one of the most popular contracting vehicles. It is an indefinite
          delivery/indefinite quantity (IDIQ) with direct access to a wide
          variety of products, services and solutions from industry leaders. The
          procurement cycles are more efficient for the government, ensures
          compliance and the vendors have pre-approved rates that the government
          can use.
        </p>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-5 align-self-center ">
            <div className="abt-inner">
              <img
                className="alignleft wp-image-168"
                // src={gsa}
                src={A2}
                alt="ab2"
                style={{
                  border: "10px solid #fff",
                  // width: "100%",
                  // width: "-moz-available",
                  // width: "-webkit-fill-available,",
                  // width: "stretch",
                  maxWidth: "100%",
                }}
              />
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-7">
            <p>
              <b>Contract Number: </b>47QTCA19D00A2
              <br />
              <a
                href="https://www.gsaadvantage.gov/ref_text/47QTCA19D00A2/0Z7WJ0.3UY9DO_47QTCA19D00A2_EIGENNET04022024.PDF"
                target="_blank"
                className="contract"
                rel="noopener noreferrer nofollow"
              >
                GSA Approved Pricelist
              </a>
              {/* <br /> */}
              {/* <b>Contract Number: </b>47QTCA19D00N3
              <br />
              <a
                // href="https://www.gsaadvantage.gov/ref_text/47QTCA19D00A2/0Z7WJ0.3UY9DO_47QTCA19D00A2_EIGENNET04022024.PDF"
                href="https://www.gsaadvantage.gov/ref_text/47QTCA19D00N3/0Z9KI6.3UZXCU_47QTCA19D00N3_EIGENNETSBSA04172024.PDF"
                target="_blank"
                className="contract"
                rel="noopener noreferrer nofollow"
              >
                GSA Approved Pricelist
              </a> */}
              <br />
              <b>Effective Date:</b> Apr 16, 2019 through Apr 15, 2029
              <br />
              {/* <a href="https://www.gsaelibrary.gsa.gov/ElibMain/contractorInfo.do?contractNumber=47QTCA19D00A2&contractorName=EIGENNETLLC&executeQuery=YES" target="_blank" className="contract" rel="noopener noreferrer nofollow">GSA MAS Schedule Web Page</a><br/> */}
              <a
                href="https://www.gsaelibrary.gsa.gov/ElibMain/contractorInfo.do?contractNumber=47QTCA19D00A2&contractorName=EIGENNET%2C+LLC&executeQuery=YES"
                target="_blank"
                className="contract"
                rel="noopener noreferrer nofollow"
              >
                GSA MAS Schedule Web Page
              </a>
              <br />
              <a
                href="https://www.gsaadvantage.gov/advantage/main/start_page.do"
                target="_blank"
                className="contract"
                rel="noopener noreferrer nofollow"
              >
                GSA Advantage Web Page
              </a>
              <br />
              {/* <a href="https://www.eigennet.com/wp-content/uploads/2022/12/GovernmentCommercial-Pricing.pdf" target="_blank" className="contract" rel="noopener noreferrer">Government/Commercial Pricing</a><br/> */}
              {/* href={pricelist} */}
              <a target="_blank" href={pricelistA2} rel="noreferrer">
                Commercial Pricelist
              </a>
              <br />
              {/* <a href="https://www.gsaadvantage.gov/ref_text/47QTCA19D00A2/0XV0OB.3TLDJ2_47QTCA19D00A2_EIGENNET01092023.PDF" target="_blank" className="contract" rel="noopener noreferrer nofollow">GSA Approved Pricelist</a><br/> */}
              {/* <br /> */}
              <b>Eigennet point of contact:</b>{" "}
              <a
                href="mailto:srao@eigennet.com"
                className="mailink"
                target="_blank"
                rel="nofollow noreferrer"
              >
                srao@eigennet.com
              </a>
              {/* <br />
              <b>SINS:</b> 493110RM, 518210C, 518210DC, 518210ERM, 541110,
              541511, 54151HEAL, 54151S, 541611, 541690, 561439, OLM */}
              <br />
              <b>SAM UNIQUE ENTITY ID:</b> XTA5EN9KNJN5 <br />
            </p>
          </div>
          <p className="col-12">
            <h5 className="col-title">SINS:</h5>
            493110RM &#8211; PHYSICAL RECORDS MANAGEMENT SERVICES
            <br />
            518210C &#8211; CLOUD COMPUTING AND CLOUD RELATED IT PROFESSIONAL
            SERVICES
            <br />
            518210DC &#8211; DOCUMENT CONVERSION SERVICES
            <br />
            518210ERM &#8211; ELECTRONIC RECORDS MANAGEMENT SOLUTIONS
            <br />
            541110 &#8211; PROFESSIONAL LEGAL SERVICES
            <br />
            541511 &#8211; WEB BASED MARKETING
            <br />
            54151HEAL &#8211; HEALTH IT SERVICES
            <br />
            54151S &#8211; INFORMATION TECHNOLOGY PROFESSIONAL SERVICES
            <br />
            541611 &#8211; MANAGEMENT AND FINANCIAL CONSULTING, ACQUISITION AND
            GRANTS MANAGEMENT SUPPORT, AND BUSINESS PROGRAM AND PROJECT
            MANAGEMENT SERVICES
            <br />
            541690 &#8211; TECHNICAL CONSULTING SERVICES
            <br />
            561439 &#8211; DOCUMENT PRODUCTION ON-SITE AND OFF-SITE SERVICES
            <br />
            OLM &#8211; ORDER LEVEL MATERIALS
            {/* <br /> */}
            {/* 561320SBSA &#8211; PROFESSIONAL SERVICES
            <br /> */}
          </p>
        </div>

        <div
          className="row pt-4 mx-1 p-0"
          style={{
            borderTop: "1px solid black",
            borderBottom: "1px solid black",
          }}
        >
          <div className="col-sm-12 col-md-6 col-lg-5 align-self-center p-0 ">
            <div className="abt-inner">
              <img
                className="alignleft wp-image-168"
                // src={gsa}
                src={N3}
                alt="ab2"
                style={{
                  border: "10px solid #fff",
                  // width: "100%",
                  // width: "-moz-available",
                  // width: "-webkit-fill-available,",
                  // width: "stretch",
                  maxWidth: "100%",
                }}
              />
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-7">
            <p>
              {/* <b>Contract Number: </b>47QTCA19D00A2
              <br />
              <a
                href="https://www.gsaadvantage.gov/ref_text/47QTCA19D00A2/0Z7WJ0.3UY9DO_47QTCA19D00A2_EIGENNET04022024.PDF"
                target="_blank"
                className="contract"
                rel="noopener noreferrer nofollow"
              >
                GSA Approved Pricelist
              </a>
              <br /> */}
              <b>Contract Number: </b>47QTCA19D00N3
              <br />
              <a
                // href="https://www.gsaadvantage.gov/ref_text/47QTCA19D00A2/0Z7WJ0.3UY9DO_47QTCA19D00A2_EIGENNET04022024.PDF"
                href="https://www.gsaadvantage.gov/ref_text/47QTCA19D00N3/0Z9KI6.3UZXCU_47QTCA19D00N3_EIGENNETSBSA04172024.PDF"
                target="_blank"
                className="contract"
                rel="noopener noreferrer nofollow"
              >
                GSA Approved Pricelist
              </a>
              <br />
              <b>Effective Date:</b> Apr 16, 2024 through Apr 15, 2029
              <br />
              {/* <a href="https://www.gsaelibrary.gsa.gov/ElibMain/contractorInfo.do?contractNumber=47QTCA19D00A2&contractorName=EIGENNETLLC&executeQuery=YES" target="_blank" className="contract" rel="noopener noreferrer nofollow">GSA MAS Schedule Web Page</a><br/> */}
              <a
                href="https://www.gsaelibrary.gsa.gov/ElibMain/contractorInfo.do?contractNumber=47QTCA19D00A2&contractorName=EIGENNET%2C+LLC&executeQuery=YES"
                target="_blank"
                className="contract"
                rel="noopener noreferrer nofollow"
              >
                GSA MAS Schedule Web Page
              </a>
              <br />
              <a
                href="https://www.gsaadvantage.gov/advantage/main/start_page.do"
                target="_blank"
                className="contract"
                rel="noopener noreferrer nofollow"
              >
                GSA Advantage Web Page
              </a>
              <br />
              {/* <a href="https://www.eigennet.com/wp-content/uploads/2022/12/GovernmentCommercial-Pricing.pdf" target="_blank" className="contract" rel="noopener noreferrer">Government/Commercial Pricing</a><br/> */}
              {/* <a href={pricelist} target="_blank" rel="noreferrer"> */}
              <a target="_blank" href={pricelistN3} rel="noreferrer">
                Commercial Pricelist
              </a>
              <br />
              {/* <a href="https://www.gsaadvantage.gov/ref_text/47QTCA19D00A2/0XV0OB.3TLDJ2_47QTCA19D00A2_EIGENNET01092023.PDF" target="_blank" className="contract" rel="noopener noreferrer nofollow">GSA Approved Pricelist</a><br/> */}
              {/* <br /> */}
              <b>Eigennet point of contact:</b>{" "}
              <a
                href="mailto:srao@eigennet.com"
                className="mailink"
                target="_blank"
                rel="nofollow noreferrer"
              >
                srao@eigennet.com
              </a>
              <br />
              {/* <b>SINS:</b> 561320SBSA and OLM <br /> */}
              <b>SAM UNIQUE ENTITY ID:</b> XTA5EN9KNJN5 <br />
            </p>
          </div>
          <p className="col-12 p-0">
            <h5 className="col-title">SINS:</h5>
            {/* 493110RM &#8211; PHYSICAL RECORDS MANAGEMENT SERVICES
            <br />
            518210C &#8211; CLOUD COMPUTING AND CLOUD RELATED IT PROFESSIONAL
            SERVICES
            <br />
            518210DC &#8211; DOCUMENT CONVERSION SERVICES
            <br />
            518210ERM &#8211; ELECTRONIC RECORDS MANAGEMENT SOLUTIONS
            <br />
            541110 &#8211; PROFESSIONAL LEGAL SERVICES
            <br />
            541511 &#8211; WEB BASED MARKETING
            <br />
            54151HEAL &#8211; HEALTH IT SERVICES
            <br />
            54151S &#8211; INFORMATION TECHNOLOGY PROFESSIONAL SERVICES
            <br />
            541611 &#8211; MANAGEMENT AND FINANCIAL CONSULTING, ACQUISITION AND
            GRANTS MANAGEMENT SUPPORT, AND BUSINESS PROGRAM AND PROJECT
            MANAGEMENT SERVICES
            <br />
            541690 &#8211; TECHNICAL CONSULTING SERVICES
            <br />
            561439 &#8211; DOCUMENT PRODUCTION ON-SITE AND OFF-SITE SERVICES
            <br /> */}
            561320SBSA &#8211; PROFESSIONAL SERVICES
            <br />
            OLM &#8211; ORDER LEVEL MATERIALS
            <br />
          </p>
        </div>

        {/* <p>
          <h5 className="col-title">Eigennet Special Item Numbers:</h5>
          493110RM &#8211; PHYSICAL RECORDS MANAGEMENT SERVICES
          <br />
          518210C &#8211; CLOUD COMPUTING AND CLOUD RELATED IT PROFESSIONAL
          SERVICES
          <br />
          518210DC &#8211; DOCUMENT CONVERSION SERVICES
          <br />
          518210ERM &#8211; ELECTRONIC RECORDS MANAGEMENT SOLUTIONS
          <br />
          541110 &#8211; PROFESSIONAL LEGAL SERVICES
          <br />
          541511 &#8211; WEB BASED MARKETING
          <br />
          54151HEAL &#8211; HEALTH IT SERVICES
          <br />
          54151S &#8211; INFORMATION TECHNOLOGY PROFESSIONAL SERVICES
          <br />
          541611 &#8211; MANAGEMENT AND FINANCIAL CONSULTING, ACQUISITION AND
          GRANTS MANAGEMENT SUPPORT, AND BUSINESS PROGRAM AND PROJECT MANAGEMENT
          SERVICES
          <br />
          541690 &#8211; TECHNICAL CONSULTING SERVICES
          <br />
          561439 &#8211; DOCUMENT PRODUCTION ON-SITE AND OFF-SITE SERVICES
          <br />
          OLM &#8211; ORDER LEVEL MATERIALS
          <br />
          561320SBSA &#8211; PROFESSIONAL SERVICES
          <br />
        </p> */}
        {/* <p><h5 className="col-title" >Eigennet Special Item Numbers:</h5>
518210DC &#8211; DOCUMENT CONVERSION SERVICES<br/>
518210ERM &#8211; ELECTRONIC RECORDS MANAGEMENT SOLUTIONS<br/>
493110RM &#8211; PHYSICAL RECORDS MANAGEMENT SERVICES<br/>
54151S &#8211; Information Technology &#8211; IT Services<br/>
54151HEAL &#8211; Health IT Services<br/>
OLM &#8211; Order Level Materials </p>   */}
      </div>
      <br />
      <br />
    </>
  );
}
