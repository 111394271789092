import React, { useState } from "react";
import "../index.css";
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  Link,
} from "react-router-dom";
// import { useState } from 'react';
// import MediaQuery from 'react-responsive';
import { NavLink, useLocation } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";

import navlogo from "../Assets/Img/navbar_logo.svg";

export default function NavBar(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const location = useLocation();
  const currentPath = location.pathname.slice(1);
  console.log("currentPath", currentPath);
  console.log(isLoading);
  const handleLinkClick = () => {
    setIsLoading(true);
    setLoadingProgress(30); // Set initial progress value

    // Simulate loading delay (remove this in your actual code)
    setTimeout(() => {
      setLoadingProgress(100); // Set progress to complete
      setIsLoading(false);

      // Reset progress after a delay (optional)
      // setTimeout(() => {
      //   setLoadingProgress(0);
      // }, 500);
    }, 1000);
  };

  return (
    <div>
      <LoadingBar progress={loadingProgress} height={5} color="#89C000" />

      <nav
        className="navbar navbar-expand-xl navbar-light shadow fixed-top"
        style={{ backgroundColor: "#eaeaea" }}
      >
        <Link
          className="site-logo mr-3"
          onClick={handleLinkClick}
          to="/"
          data-toggle="collapse"
          data-target=".navbar-collapse.show"
        >
          <img src={navlogo} style={{ width: "230px" }} alt="Eigennet"></img>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item ml-2">
              <NavLink
                className={
                  "nav-link" + (currentPath === "" ? " nav-highlight" : "")
                }
                style={{
                  fontWeight: "500",
                  color: "#1B2951",
                  // borderBottom: "4px solid #84c000",
                }}
                to="/"
                onClick={handleLinkClick}
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                HOME
              </NavLink>
            </li>
            <li className="nav-item dropdown ml-2">
              <Link
                className={
                  "nav-link dropdown-toggle " +
                  (currentPath === "Doc2data" || currentPath === "RfpEngine"
                    ? " nav-highlight"
                    : "")
                }
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                style={{
                  fontWeight: "500",
                  color: "#1B2951",
                }}
                aria-haspopup="true"
                aria-expanded="false"
              >
                PRODUCTS
              </Link>
              <ul
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li>
                  <Link
                    className="dropdown-item"
                    to="/Doc2data"
                    onClick={handleLinkClick}
                    style={{ color: "#1B2951" }}
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    DOC2DATA
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/RfpEngine"
                    onClick={handleLinkClick}
                    style={{ color: "#1B2951" }}
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    RFP-ENGINE
                  </Link>
                </li>
                {/* <li><Link className="dropdown-item" to="/AroundEarnings" onClick={handleLinkClick} style={{color:'#1B2951'}}  data-toggle="collapse" data-target=".navbar-collapse.show">AROUND EARNINGS</Link></li> */}
                {/* <li><Link className="dropdown-item" to="/QcTitle" style={{color:'#1B2951'}}>QC TITLE</Link></li> */}
              </ul>
            </li>

            <li className="nav-item ml-2">
              <NavLink
                className={
                  "nav-link" +
                  (currentPath === "services" || currentPath === "Services"
                    ? " nav-highlight"
                    : "")
                }
                style={{
                  fontWeight: "500",
                  color: "#1B2951",
                  // borderBottom: "4px solid #84c000",
                }}
                to="services"
                onClick={handleLinkClick}
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                {props.services}
              </NavLink>
            </li>

            <li className=" nav-item ml-2">
              <NavLink
                className={
                  "nav-link" +
                  (currentPath === "Hardware" || currentPath === "hardware"
                    ? " nav-highlight"
                    : "")
                }
                style={{ fontWeight: "500", color: "#1B2951" }}
                to="Hardware"
                onClick={handleLinkClick}
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                {props.hardware}
              </NavLink>
            </li>

            {/* <li className="nav-item dropdown ml-2">
              <Link
                className="nav-link dropdown-toggle"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                style={{ fontWeight: "500", color: "#1B2951" }}
                aria-haspopup="true"
                aria-expanded="false"
              >
                SERVICES
              </Link>
              <ul
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li className="dropdown-submenu">
                  <Link className="dropdown-item dropdown-toggle">
                    ELECTRONIC RECORDS
                    <br /> MANAGEMENT
                  </Link>
                  <ul className="dropdown-menu">
                    <Link
                      className="dropdown-item"
                      to="/DocumentConversion"
                      onClick={handleLinkClick}
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      {" "}
                      DOCUMENT CONVERSION
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/ElectronicRecords"
                      onClick={handleLinkClick}
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      ELECTRONIC RECORDS
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/PhysicalRecords"
                      onClick={handleLinkClick}
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      PHYSICAL RECORDS
                    </Link>
                  </ul>
                </li>

                <Link
                  className="dropdown-item"
                  to="LegalAndLitigation"
                  style={{ color: "#1B2951" }}
                  onClick={handleLinkClick}
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  COPYRIGHT LEGAL &<br />
                  LITIGATION SUPPORT SERVICES
                </Link>
                <Link
                  className="dropdown-item"
                  to="AiAsAService"
                  style={{ color: "#1B2951" }}
                  onClick={handleLinkClick}
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  AI AS A SERVICE
                </Link>
                <Link
                  className="dropdown-item"
                  to="CustomAppDevelop"
                  style={{ color: "#1B2951" }}
                  onClick={handleLinkClick}
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  CUSTOM APPLICATION
                  <br /> DEVELOPMENT
                </Link>
                <Link
                  className="dropdown-item"
                  to="Administrative"
                  style={{ color: "#1B2951" }}
                  onClick={handleLinkClick}
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  ADMINISTRATIVE OR
                  <br /> CLERICAL SUPPORT
                </Link>
              </ul>
            </li> */}

            {/* <li className="nav-item ml-2">
              <NavLink
                className="nav-link"
                style={{ fontWeight: "500", color: "#1B2951" }}
                to="Services"
                onClick={handleLinkClick}
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                {props.services}
              </NavLink>
            </li> */}

            {/* <li className="nav-item dropdown ml-2">
              <Link
                className="nav-link dropdown-toggle"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                style={{ fontWeight: "500", color: "#1B2951" }}
                aria-haspopup="true"
                aria-expanded="false"
              >
                ABOUT US
              </Link>
              <ul
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              > */}
            {/* <Link
                  className="dropdown-item"
                  to="OurTeam"
                  onClick={handleLinkClick}
                  style={{ color: "#1B2951" }}
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  OUR TEAM
                </Link> */}
            {/* <Link
                  className="dropdown-item"
                  to="WhoWeServe"
                  onClick={handleLinkClick}
                  style={{ color: "#1B2951" }}
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  WHO WE SERVE
                </Link>
                <Link
                  className="dropdown-item"
                  to="WorkModels"
                  onClick={handleLinkClick}
                  style={{ color: "#1B2951" }}
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  WORK MODELS
                </Link>
                <Link
                  className="dropdown-item"
                  to="Certifications"
                  onClick={handleLinkClick}
                  style={{ color: "#1B2951" }}
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  CERTIFICATIONS
                </Link>
              </ul>
            </li> */}

            {/* <li className="nav-item ml-2">
              <NavLink
                className="nav-link"
                style={{ fontWeight: "500", color: "#1B2951" }}
                to="aboutus"
                onClick={handleLinkClick}
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                {props.about_us}
              </NavLink>
            </li> */}

            {/* <li className="nav-item ml-2">
              <NavLink
                className={
                  "nav-link" +
                  (currentPath === "aboutus" ? " nav-highlight" : "")
                }
                style={{ fontWeight: "500", color: "#1B2951" }}
                to="aboutus"
                onClick={handleLinkClick}
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                {props.about_us}
              </NavLink>
            </li> */}

            <li className="nav-item ml-2">
              <NavLink
                className={
                  "nav-link" +
                  (currentPath === "contracts" ? " nav-highlight" : "")
                }
                style={{ fontWeight: "500", color: "#1B2951" }}
                to="contracts"
                onClick={handleLinkClick}
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                {props.contracts}
              </NavLink>
            </li>
            <li className="nav-item ml-2">
              <Link
                className={
                  "nav-link" +
                  (currentPath === "capabilities" ? " nav-highlight" : "")
                }
                style={{ fontWeight: "500", color: "#1B2951" }}
                to="capabilities"
                onClick={handleLinkClick}
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                {props.capabilities}
              </Link>
            </li>
            <li className="nav-item ml-2">
              <a
                className="nav-link"
                target="_blank"
                style={{ fontWeight: "500", color: "#1B2951" }}
                // onClick={handleLinkClick}
                rel="noopener nofollow noreferrer"
                href="http://blog.eigennet.com/"
              >
                {props.re_labs}
              </a>
            </li>
            <li className="nav-item ml-2">
              <Link
                className={
                  "nav-link" +
                  (currentPath === "careers" ? " nav-highlight" : "")
                }
                style={{ fontWeight: "500", color: "#1B2951" }}
                to="careers"
                onClick={handleLinkClick}
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                {props.careers}
              </Link>
            </li>

            <li className="nav-item ml-2">
              <a
                className="nav-link"
                target="_blank"
                rel="noopener nofollow noreferrer"
                style={{ fontWeight: "500", color: "#1B2951" }}
                href="https://workspace.eigennet.com"
              >
                WORKSPACE
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}
