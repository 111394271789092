import React, { useEffect } from 'react';

import cServices from '../Assets/Img/c-services.jpg'
import develop_process from '../Assets/Img/developmentprocess.jpg'
import cValidate from '../Assets/Img/c-validate.jpg'
import "../index.css";

export default function CustomAppDevelop() {
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <div className='container' style={{boxShadow:'0px 0px 10px rgba(51, 51, 51, 0.04)',marginTop:'70px'}}>
            <h2 className="col-title" style={{borderBottom: '1px solid black'}}>CUSTOM APPLICATION DEVELOPMENT</h2>
            <p style={{textAlign: 'justify'}}>We use innovative application development techniques including agile to enhance the life-cycle of the entire development process.</p>
            <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-5"><img className="alignleft wp-image-123" style={{marginBottom: '20px',width:'-webkit-fill-available'}} src={cServices} alt="custm" />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-7">
            <h4>Getting to know the Customer</h4>
            <p className='mobile-responsive'>We engage the customer through emails, meetings, surveys, and other working sessions to learn about the specific set of problems that are challenging to their business processes. Whether it’s a new application or enhancement to an existing functional work product, our analysts will thoroughly examine your needs and come up with complete requirements to share with you. We can present potential solutions to your organization’s decision makers with clear visualizations and get customer input throughout the engagement process to ensure that the application is built beyond expectations.</p>
            </div>
</div>

<div className="clear">&nbsp;</div>
<div style={{borderBottom: '1px solid black'}}></div><br/>

            <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-5"><img className="alignleft wp-image-123" style={{marginBottom: '20px',width:'-webkit-fill-available'}} src={develop_process} alt="custm"/>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-7">
            <h4>Development and Process </h4>
            <p className='mobile-responsive'>We use industry best practices to ensure that all work towards your application development meets high quality standards. Customization includes full development and integration of critical applications utilizing .NET, Java, Ruby and front-end technologies such as HTML5, JavaScript, etc., through managed services or distributed development. We will assist in navigating issues of multi-tenancy, operational support, and security to ensure a cohesive platform while securing data. Our team will ensure the right tools are used for your specific project while improving quality in all stages of the development lifecycle with complete testing cycles and validation resulting in a lower defect rate.</p>
            </div>
</div>

<div className="clear">&nbsp;</div>
<div style={{borderBottom: '1px solid black'}}></div><br/>

            <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-5"><img className="alignleft wp-image-123" style={{marginBottom: '20px',width:'-webkit-fill-available'}} src={cValidate} alt="custm"/>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-7">
            <h4>Customer Validation</h4>
            <p className='mobile-responsive'>In keeping our customers engaged, we can provide you with work samples, proto-types, product demonstrations and proof of concepts to ensure that our solution meets your requirements. We conduct User Acceptance Testing (UAT) sessions with the customer to get feedback and work on refining any defects found. Our dedication to the complete life-cycle of the development process sets us apart and we want our customers to have a competitive edge while building an ongoing relationship.</p>
</div>
</div>



            </div>
            <br/>
            <br/>
            </>
  )
}
