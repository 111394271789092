import React, {useEffect} from 'react'
import electronicRec from "../Assets/Img/electronics-records.jpg"
import "../index.css";


export default function ElectronicRecords() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <div className='container' style={{boxShadow:'0px 0px 10px rgba(51, 51, 51, 0.04)',marginTop:'70px'}}>
    <h2 className="col-title mb-3" style={{borderBottom: '1px solid black'}}>ELECTRONIC RECORDS</h2>
<div className='row'>
<div className='col-sm-12 col-md-6 col-lg-5'><img loading="lazy" className="alignleft align wp-image-116" src={electronicRec} alt='' style={{width:'-webkit-fill-available'}}/></div>

<div className='mobile-responsive col-sm-12 col-md-6 col-lg-7' >
  <p>
  Eigennet provides electronic records management to clients who require management services for large number of documents. Leveraging on the experience gathered over the years providing services to various governmental and commercial organizations, Eigennet has in place various management frameworks that can aid in managing, sorting, organizing, and indexing documents. Eigennet’s records management solutions are designed keeping in mind all the stages of records management, right from sorting and preparing the files to extracting the metadata and indexing the files. However, what makes Eigennet’s records management solutions stand out from the rest is the innovative way in which the metadata is extracted from the documents by leveraging on creative technological solutions that can analyze and extract the metadata quickly and in a structured format.
  </p>
  </div>
</div>

<p className='mobile-responsive mt-4' > 
Since managing the records with proper naming conventions is a time-consuming task, Eigennet incorporates AI and Machine Learning solutions that can analyze thousands of documents quickly, identify the names of the documents and naming patterns and other relevant information. Extracting the information enables Eigennet to archive or index the documents with much better accuracy and can ensure that no document is lost. With powerful tools, a wealth of experience and an innate understanding of records management, Eigennet offers its clients a one-of-a-kind service designed to satisfy every electronic record management needs.</p><br/>

</div>
</>
  )
}
