// import React, { useEffect } from 'react';
// import { withRouter } from 'react-router-dom';
// import pdfUrl from '../Assets/Docs/eigennetllc_handbook_ud.pdf'
// // import pdfUrl from '../../public/eigennetllc_handbook_ud.pdf'


 
// const EmployeeHandbook = ({ history }) => {
//   useEffect(() => {
//     const openPDF = () => {
//       // const pdfUrl = `${process.env.PUBLIC_URL}/sample.pdf`;

 

//       // Check if the component is mounted before opening the PDF
//       if (!history.location.state || !history.location.state.pdfOpened) {
//         const newWindow = window.open(pdfUrl);

 

//         if (newWindow) {
//           // Mark the PDF as opened in state
//           history.replace(history.location.pathname, { pdfOpened: true });
//         } else {
//           // Redirect to the home page if the popup is blocked
//           history.push('/');
//         }
//       }
//     };

 

//     openPDF();
//   }, [history]);


//   // useEffect(() => {     
//   //   // Check if the URL path matches the PDF URL    
//   //   if (window.location.pathname === '/some-file.pdf') {       
//   //     // Directly open the PDF file in the browser      
//   //     window.location.href = '/some-file.pdf';     
//   //   }   
//   // }, []);
 

//   return <div>Loading PDF...</div>;
// };

 

// export default withRouter(EmployeeHandbook);


import React from 'react';
import pdfUrl from '../Assets/Docs/eigennetllc_handbook_ud.pdf'

const EmployeeHandbook = () => {
  const pdfWithParams = `${pdfUrl}#zoom=80`;

  return (
    <>
    <div style={{marginTop: "70px"}}>
  {/* <a href={pdfUrl} download>Download PDF</a> */}
      <embed src={pdfWithParams} type="application/pdf" width="100%" height="600px" />
    </div>
  </>
  );
};

export default EmployeeHandbook;