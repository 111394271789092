import React,{useEffect} from 'react'
import "../index.css";

export default function LegalAndLitigation() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <div className='container' style={{boxShadow:'0px 0px 10px rgba(51, 51, 51, 0.04)',marginTop:'70px'}}>
            <h2 className="col-title" style={{borderBottom: '1px solid black',paddingBottom: '5px'}}>COPYRIGHT LEGAL & LITIGATION SUPPORT SERVICES</h2>

            <p className='mobile-responsive' style={{marginTop:'17px'}} >Eigennet offers copyright related legal and litigation services for organizations dealing with large number of legal documents, appeals and cases. Eigennet's experience in working with federal agencies in this domain provided us the expertise required in dealing with numerous documents and reports. Eigennet’s team of paralegals are adept at reviewing documents and establishing correspondences, conducting weekly and monthly meetings, creating elaborate reports, and fishing out intricate and relevant details from the documents. We employ specific tools to ensure the labor cost and review times are vastly reduced.  Furthermore, Eigennet’s expertise in records management ensures that proper metadata is extracted from electronic documents, and the physical documents are properly indexed and filed enabling proper case management. With this, Eigennet ensures that the day-to-day workflows are flawless without any roadblocks. With a team of experienced paralegals and attorneys, innovative document management and extraction tools and with tried and tested management procedures, Eigennet offers comprehensive copyright related legal and litigation support services.</p>

      
    </div>

    </>
  )
}
