import React from "react";
import GetInTouchFooter from "./GetInTouchFooter_siddu";
import MediaQuery from "react-responsive";
import rfpHeroSectionBackground from "../Assets/engine-heroSectionFrame.png";
import rfp_logo from "../Assets/engineLogo.svg";

export default function RfpEngine_siddu() {
  const textColor = "#1C2951";
  return (
    <div
      className="rfp_siddu_new"
      style={{
        marginTop: "70px",
        // background: "#eef1ef",
      }}
    >
      {/** Hero section  */}
      <div
        style={{
          // backgroundImage: 'url("./Assets/engine-heroSectionFrame.png")',
          // backgroundImage: require("../Assets/engine-heroSectionFrame.png"),
          backgroundImage: `url(${rfpHeroSectionBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          marginBottom: "20px",
          paddingBottom: "20px",
        }}
      >
        <div
          className="container d-flex flex-column justify-content-center align-items-center py-3"
          style={{ gap: "10px" }}
        >
          <img
            className=""
            // src="/engineLogo.png"
            // src={require("../Assets/engineLogo.svg")}
            src={rfp_logo}
            style={{width: "190px", height: "76px"}}
          />
          <MediaQuery maxWidth={575}>
            <h3
              className=" text-center text-white"
              //   style={{ color: "#C2B78C" }}
            >
              Win Contracts with the Power of Generative AI
            </h3>
          </MediaQuery>
          <MediaQuery minWidth={576}>
            <h1
              className=" text-center text-white"
              //   style={{ color: "#C2B78C" }}
            >
              Win Contracts with the Power of Generative AI
            </h1>
          </MediaQuery>
        </div>
        <div
          className="mx-auto d-flex"
          style={{ width: "max-content", gap: "10px" }}
        >
          <a
            href="https://www.rfp-engine.com/"
            target="_blank"
            class=" px-3 py-2 px-md-4 py-md-2 text-decoration-none text-white btn-of-hero-sec-rfp btn"
          >
            Learn More
          </a>
          <a
            href="https://blog.eigennet.com/"
            target="_blank"
            class=" px-3 py-2 px-md-4 py-md-2 text-decoration-none text-white btn-of-hero-sec-rfp btn"
          >
            Explore Research Lab
          </a>
        </div>
      </div>
      {/** Hero section end */}

      <MediaQuery maxWidth={575}>
        <h4
          className=" text-center pb-0"
          style={{
            color: textColor,
            fontWeight: "700",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          Unlock Proposal Wins with RFP Engine
        </h4>
      </MediaQuery>

      <MediaQuery minWidth={576}>
        <h2
          className=" text-center container pb-0"
          style={{
            color: textColor,
            fontWeight: "700",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          Unlock Proposal Wins with RFP Engine
        </h2>
      </MediaQuery>

      <div
        className="container"
        style={{ paddingTop: "20px", paddingBottom: "20px" }}
      >
        <div className="row justify-content-between flex-wrap-reverse">
          <div className="col-12 col-lg-8 pt-4 pt-lg-0">
            <SectionHeading heading="Aggregation" textColor={textColor} />
            <p
              className=" fs-4 "
              style={{ color: "rgba(28, 41, 81, 0.79)", textAlig: "justify" }}
            >
              {/* The RFP Engine takes care of the tedious tasks that can slow your
              team down. By leveraging the power of generative AI, RFP Engine
              automates processes such as gathering RFx information and
              summarizing it from solicitations, centralizing the solicitations
              for easy access and collaboration, and sending automated alerts
              when action is required, enabling teams to channel their efforts
              into crafting compelling responses rather than managing repetitive
              counter-productive tasks. */}
              Aggregate all RFx opportunities in one place, be it SAMs, GSA,
              FedConnect, or any other. RFP Engine serves as the single point to
              store, sort, and view your RFx data, enabling centralized access
              to multiple RFx files, fostering improved collaboration,
              decision-making, and RFx tracking.
            </p>
          </div>
          <div className=" col-4 col-md-3 col-lg-3 d-flex align-items-center">
            <img
              className=" d-block w-100"
              // src="01-image.png"
              src={require("../Assets/01-image.png")}
              style={
                {
                  // width: "306.16px",
                  // height: "236px",
                }
              }
            />
          </div>
        </div>
      </div>

      <div
        className="container"
        style={{ paddingTop: "20px", paddingBottom: "20px" }}
      >
        <div className="row justify-content-between ">
          <div className=" col-4 col-md-3 col-lg-3 d-flex align-items-center">
            <img
              className=" d-block w-100"
              // src="02-image.png"
              src={require("../Assets/02-image.png")}
              style={
                {
                  // width: "306.16px",
                  // height: "236px",
                }
              }
            />
          </div>
          <div className="col-12 col-lg-8 pt-4 pt-lg-0">
            {/* <h2 className="" style={{ color: textColor }}>
              End-to-End Project Visibility
            </h2> */}
            <SectionHeading heading="Scoring" textColor={textColor} />
            <p className="" style={{ color: "rgba(28, 41, 81, 0.79)" }}>
              {/* RFP Engine provides complete project visibility, ensuring that
              deadlines are met, and any modifications to requirements are
              seamlessly incorporated into the proposal response. With the RFP
              Engine, solicitations and responses can be tracked from start to
              finish, staying on top of every detail at the right time. */}
              Score opportunities with the power of generative AI. RFP Engine
              uses generative AI to automate proposal scoring by offering a
              GPT-score for the proposals based on the organization’s
              capabilities and past performances, allowing teams to
              automatically identify relevant opportunities instantly.
            </p>
          </div>
        </div>
      </div>

      <div
        className="container"
        style={{ paddingTop: "20px", paddingBottom: "20px" }}
      >
        <div className="row justify-content-between flex-wrap-reverse ">
          <div className="col-12 col-lg-8 pt-4 pt-lg-0">
            {/* <h2 className="" style={{ color: textColor }}>
              Collaborative Decision-Making
            </h2> */}
            <SectionHeading heading="Alerting" textColor={textColor} />
            <p className="" style={{ color: "rgba(28, 41, 81, 0.79)" }}>
              {/* The integrated proposal appraisal system fosters collaboration
              among team members during proposal evaluations, enabling
              collective decision-making that harnesses the full potential of
              the team's expertise. Elevate the proposal management process with
              RFP Engine and increase your likelihood of winning contracts
              successfully. Teams can take advantage of the RFP engine’s
              numerous features to ensure that they tackle the proposal process
              efficiently while saving more time and effort. */}
              Stay on top of the proposal game with the right alerts at the
              right time. RFP Engine’s AI core alerts users to the right
              opportunities as they appear. RFP Engine takes alerts one step
              further by providing users with AI-assisted GPT-summaries for
              proposals to expedite the bid/no-bid decisions, enabling the right
              actions at the right time.
            </p>
          </div>
          <div className=" col-4 col-md-3 col-lg-3 d-flex align-items-center">
            <img
              className=" d-block w-100"
              // src="03-image.png"
              src={require("../Assets/03-image.png")}
              style={
                {
                  // width: "306.16px",
                  // height: "236px",
                }
              }
            />
          </div>
        </div>
      </div>

      {/* <div style={{ backgroundColor: "#EEF1EF" }}>
        <div
          className="container"
          style={{ paddingTop: "20px", paddingBottom: "20px" }}
        >
          <div className="pb-3">
            <MediaQuery maxWidth={575}>
              <h2
                className=" text-center container h4 pb-0"
                style={{
                  color: textColor,
                  fontWeight: "700",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                }}
              >
                A Proposal Management System Which You are looking for!
              </h2>
            </MediaQuery>

            <MediaQuery maxWidth={767} minWidth={576}>
              <h2
                className=" text-center container h2 pb-0"
                style={{
                  color: textColor,
                  fontWeight: "700",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                }}
              >
                A Proposal Management System Which You are looking for!
              </h2>
            </MediaQuery>

            <MediaQuery minWidth={768}>
              <h2
                className=" text-center container h3 pb-0"
                style={{
                  color: textColor,
                  fontWeight: "700",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                }}
              >
                A Proposal Management System Which You Are Looking For!
              </h2>
            </MediaQuery>
          </div>
          <div className="row justify-content-between flex-wrap-reverse m-0">
            <div className="col-12 col-lg-7 row m-0 p-0">
              <p className="" style={{ color: "rgba(28, 41, 81, 0.79)" }}>
                Using the AI console, users can gain actionable insights about
                each contract in seconds. With Artificial Intelligence, the
                console can efficiently parse through complex content and
                extract relevant information, such as requirements, contact
                information, questions due, dollar value, duration, etc. Besides
                increasing efficiency and productivity, the console also
                eliminates the enormous time required to find relevant
                proposals. Using the built-in email system, teams can
                collaborate and share information easily with key stakeholders
                and subject matter experts. Using Reports, organizations can
                monitor performance and identify bottlenecks to optimize
                proposal development strategies.
              </p>
              <div className="col-12 p-0">
                <button
                  className="btn text-white px-3 py-2"
                  style={{ backgroundColor: textColor }}
                >
                  Learn More
                </button>
              </div>
            </div>
            <div className=" col-12 col-lg-5 d-flex pb-4 pb-lg-0 align-self-center ">
              <img
                className=" col-7 col-lg-12 d-block mx-auto"
                src="/image 26.png"
              />
            </div>
          </div>
        </div>
      </div> */}

      <GetInTouchFooter page="RFP" />
    </div>
  );
}

function SectionHeading({ heading, textColor }) {
  return (
    <>
      <MediaQuery maxWidth={575}>
        <h2 className="h4" style={{ color: textColor, fontWeight: "700" }}>
          {heading}
        </h2>
      </MediaQuery>
      <MediaQuery minWidth={768}>
        <h2 className="h3" style={{ color: textColor, fontWeight: "700" }}>
          {heading}
        </h2>
      </MediaQuery>
    </>
  );
}
