import React, { useEffect } from 'react';
import ai from "../Assets/Img/artificial-intelligence.jpg"
import ml from "../Assets/Img/machine-learning.jpg"
import security from "../Assets/Img/securityy.jpg"
import "../index.css";

export default function AiAsAService() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <div className='container' style={{boxShadow:'0px 0px 10px rgba(51, 51, 51, 0.04)',marginTop:'70px'}}>
            <h2 className="col-title" style={{borderBottom: '1px solid black'}}>AI AS A SERVICE</h2>
            <h6>Program your System to Perform human-like tasks.</h6>
            <div className="row">
            <div className='col-sm-12 col-md-6 col-lg-5'><img className="alignleft wp-image-168" style={{marginBottom: '5px',width:'-webkit-fill-available'}} src={ai} alt="ab2" />
            </div>
            <div className='col-sm-12 col-md-6 col-lg-7'>
            
            <p className='mobile-responsive'><h4>Digitalization </h4>
            Does your organization have digitalization initiatives? We provide Artificial Intelligence (AI) as a service using objective centered solutions leveraging open source and enterprise tools for custom applications such as chat bots, image recognition, text to speech, predictive tasks etc. These applications enable your organization to perceive activities and patterns and take action to successfully achieve functionality in performing tasks. As data grows, AI has the capability to analyze more information coupled with Big Data Analytics will help form accurate results while enhancing capabilities in existing processes.</p>
            </div>
</div>
<div className="clear">&nbsp;</div>
<div style={{borderBottom: '1px solid black'}}></div><br/>

<div className="row">
<div className='col-sm-12 col-md-6 col-lg-5'>
  <img loading="lazy" className="alignleft wp-image-169" style={{marginBottom: '0px',width:'-webkit-fill-available'}} src={ml} alt="ab1" />
  </div>
<div className='col-sm-12 col-md-6 col-lg-7'>
  <p className='mobile-responsive'><h4>Machine Learning</h4>
Considered as a subset of AI, Machine Learning is the scientific study of algorithms and statistical models that a system uses to correctly perform a function without instructions. Relying heavily on AI and statistical data, it can help in predicting, decision making and optimization. While Machine Learning has been widely popular in self-driving cars, speech recognition and optimal web searches, its use in software development can be enhanced to bring optimal results for any organization. We have expertise in developing algorithms which can improve your software development process through Machine Learning.</p>

</div>
</div>
<div className="clear">&nbsp;</div>
<div style={{borderBottom: '1px solid black'}}></div><br/>
<div className="row">
<div className='col-sm-12 col-md-6 col-lg-5'>
  <img loading="lazy" className="alignleft wp-image-169" style={{marginBottom: '0px',width:'-webkit-fill-available'}} src={security} alt="ab1" width="383" height="230"/>
  </div>
<div className='col-sm-12 col-md-6 col-lg-7'>
  <p className='mobile-responsive'><h4>Security</h4>
  Whether it is on your laptop, or through an APP, we involve ourselves in many online transactions including banking, account verification, applying for government benefits, registrations and many others. Leveraging AI can help with fraud prevention by detecting unusual behaviors such as logging in from a new location, suspicious activities in transaction and invalid facial recognition. Image recognition is a powerful subset of AI that has many uses for security, the system can be trained to recognize specific features to develop acceptable patterns for transactions. Among other security practices of AI include risk management, cyber security, physical security and surveillance.</p>

</div>


</div>
    </div>
    <br/>
    <br/>
    </>
  )
}
