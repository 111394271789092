import React, { useEffect } from "react";
import ServicesBackground from "../Assets/Servicesbg.png";
import GetInTouchFooter from "./GetInTouchFooter_siddu";
import MediaQuery from "react-responsive";

export default function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const textColor = "#1C2951";
  const paraFontSize = "18px";
  return (
    <div
      className=""
      style={{
        marginTop: "70px",
        // background: "#eef1ef",
      }}
    >
      <div
        className=" position-relative "
        style={{
          color: textColor,
          // backgroundImage: `url(${AboutUsBackground})`,
          // backgroundSize: "cover",
          // backgroundPosition: "center",
          marginBottom: "20px",
          // paddingTop: "px",
          paddingBottom: "10px",
        }}
      >
        <img
          src={ServicesBackground}
          style={{
            opacity: "10%",
            // height: "150px",
            // minHeight: "150px",
            // maxHeight: "150px",
          }}
          className=" d-block w-100 h-100 position-absolute top-0 bottom-0"
        />
        <div
          className="container d-flex align-items-center justify-content-center text-black py-5 z-1 "
          style={
            {
              // background: "green",
            }
          }
        >
          <div
            className=""
            style={
              {
                // background: "red",
              }
            }
          >
            <MediaQuery maxWidth={575}>
              <h3 className=" text-center ">Services to Offer</h3>
            </MediaQuery>
            <MediaQuery minWidth={575}>
              <h1 className=" text-center ">Services to Offer</h1>
            </MediaQuery>
            {/* <MediaQuery maxWidth={575}>
              <h5
                className=" pb-0"
                style={{
                  color: textColor,
                  paddingTop: "20px",
                  paddingBottom: "20px",
                }}
              >
                Harness the power of digital transformation comprehensive and
                secure solutions for federal agencies seeking to modernize their
                document management processes.
                About Eigennet Unleashing innovative technology to drive your
                success: our story of comprehensive and forward-looking
                solutions.
              </h5>
            </MediaQuery> */}
            {/* <MediaQuery minWidth={575}>
              <h4
                className=" pb-0"
                style={{
                  color: textColor,
                  paddingTop: "20px",
                  paddingBottom: "20px",
                }}
              >
                Harness the power of digital transformation comprehensive and
                secure solutions for federal agencies seeking to modernize their
                document management processes.
                About Eigennet Unleashing innovative technology to drive your
                success: our story of comprehensive and forward-looking
                solutions.
              </h4>
            </MediaQuery> */}
          </div>
        </div>
      </div>

      <div className="container">
        <SectionSeperatorBlueLine />
        {/* <SubHeading heading="Ai as a Service" /> */}
        <SubHeading heading="AI as a Service" />
        <p
          style={{
            color: textColor,
            fontSize: paraFontSize,
            textAlign: "justify",
          }}
          // contentEditable="true"
        >
          Eigennet is a company that is at the forefront of leveraging AI to
          augment and improve existing processes. Through rigorous innovation,
          Eigennet has successfully incorporated AI into various services, most
          notably for workflow automation and compliance monitoring. By
          combining AI with bespoke document processing tools and solutions,
          Eigennet offers AI assisted intelligent solutions that can effectively
          transform files into searchable electronic cloud versions that can
          massively aid in automating workflows. Eigennet’s AI as a service’s
          core aim is to enable organizations to automate time consuming tasks
          and to transform them into less resource intensive, efficient, and
          accurate processes. Eigennet’s prior experience with a variety of
          government and non-governmental organizations has also enabled
          Eigennet to leverage AI to automate a variety of records and document
          related compliances such as NARA, HIPAA, Section 508 etc. streamlining
          processes enabling scalability.{" "}
          {/* At Eigennet, we lead the way in innovative solutions, including
          Intelligent Character Recognition (ICR) and Metadata Extraction,
          revolutionizing document management for businesses and government
          agencies. Our ICR technology sets new standards, excelling at
          identifying handwritten structures and complex document patterns,
          ensuring precise data extraction. Seamlessly integrated with
          Eigennet's solutions, ICR transforms scanned images into editable,
          searchable text, enhancing record management efficiency. Moreover,
          leveraging Doc2Data.ai's document understanding, we offer cutting-edge
          metadata extraction, optimizing digitization processes. Through
          AI-powered tools like Doc2Data.ai, we provide bulk metadata extraction
          from various documents, meeting diverse organizational needs. Our
          systemic approach to metadata extraction enhances document workflows,
          streamlining processes, and increasing productivity. Experience the
          efficiency and accuracy of our solutions as we empower organizations
          to streamline record management and enhance data accessibility at
          Eigennet */}
        </p>
      </div>

      <div className="container">
        <SectionSeperatorBlueLine />
        {/* <SubHeading heading="Ai as a Service" /> */}
        <SubHeading heading="Scanning And Digitization" />
        <p
          style={{
            color: textColor,
            fontSize: paraFontSize,
            textAlign: "justify",
          }}
          // contentEditable="true"
        >
          At Eigennet, we lead the way in scanning and digitization services by
          offering solutions that are innovative and intelligent. Our
          state-of-the-art AI assisted Intelligent Character Recognition sets
          new standards, excelling at identifying characters in a wide range of
          simple and complex documents, and even form the ones having
          handwriting. Seamlessly integrated to the character recognition
          solution are scanners that meet FADGI 2-star and 3-star compliance
          requirements enabling the easy transformation of scanned images into
          editable, and searchable text. Moreover, leveraging intelligent
          character recognition we offer cutting edge bulk metadata extraction
          from various documents, meeting diverse organization needs. Our
          systemic approach to metadata extraction enhances document workflows,
          streamlining processes, and increasing productivity. Experience the
          efficiency and accuracy of our solutions as we empower organizations
          to streamline record management and enhance data accessibility at
          Eigennet.
        </p>
        {/* <div className="row">
          <ServiceCard
            onlyTwo={true}
            img={require("../Assets/Digitalization.png")}
            // title="Digitalization"
            // description="Learn about our digitalization initiatives. We offer AI as a service, delivering custom applications like chatbots and image recognition. These solutions enhance data analysis, recognize patterns, and improve process efficiency as your data grows."
            title="Intelligent Character Recognition"
            description="At Eigennet, we are pioneering innovation with our Intelligent Character Recognition (ICR) solutions, setting new standards in character recognition for businesses and government. Particularly valuable for organizations handling handwritten, aged, or complex documents, our cutting-edge technology excels at identifying handwritten structures, page layouts, and document patterns, ensuring precise data extraction. Eigennet's ICR seamlessly transforms scanned images into editable and searchable text, revolutionizing record management processes for large businesses and agencies. Experience the efficiency and accuracy of our solutions as we empower organizations to streamline record management and enhance data accessibility at Eigennet."
          />
          <ServiceCard
            onlyTwo={true}
            img={require("../Assets/MachineLearning.png")}
            // title="Machine Learning"
            // description="Machine Learning, a subset of AI, uses algorithms and statistical models for instruction-free function execution. It's instrumental in prediction, decision-making, and optimization. While known for self-driving cars and speech recognition, its potential in software development can yield optimal results. We specialize in ML algorithms to enhance your software development processes."
            title="Metadata Extraction"
            description="At Eigennet, we believe that proper digitization happens only when proper metadata parameters are extracted from the document. Eigennet uses the ICR powered document understanding of Doc2Data.ai to provide state of the art metadata extraction. With an AI based tool like Doc2Data.ai Eigennet offers customers bulk metadata extraction from a variety of simple and complex documents designed to meet diverse organizational needs. Utilizing Eigennet’s systemic approach to extracting metadata customers can benefit from optimized document management that enhances document workflows. With our services, customers can experience the power of metadata-driven document management and digitization, that streamlines processes and increases productivity. (reduce any 4 words)"
          />
          <ServiceCard
            img={require("../Assets/Security.png")}
            title="Security"
            description="Online transactions, whether on a laptop or app, cover banking, account verification, and more. AI enhances security by detecting unusual behavior, such as new logins and suspicious activities. Image recognition aids in pattern recognition for secure transactions, and AI contributes to risk management, cybersecurity, and surveillance."
          />
        </div> */}
      </div>

      <div className="container">
        <SectionSeperatorBlueLine />
        <SubHeading heading="Electronic Records Management" />
        <p
          style={{
            color: textColor,
            fontSize: paraFontSize,
            textAlign: "justify",
          }}
        >
          Eigennet harnesses cutting-edge AI-based solutions in its electronic
          records management services to seamlessly extract data and metadata
          from diverse document formats. Leveraging this innovation, we
          meticulously index and store information, ensuring optimal
          accessibility and organization. From simple files to complex
          documents, we adeptly process and categorize content, facilitating
          streamlined indexing with enhanced efficiency. With our robust
          capabilities, we empower businesses to confidently navigate their data
          landscape, unlocking the full potential of their information assets.
          Understanding that electronic records management extends beyond
          storage and indexing, our services integrate a key feature: Power
          Search. This feature, akin to "search on steroids," goes beyond
          traditional methods, employing Boolean methodology for swift
          pinpointing of required information within documents, thereby
          optimizing the search process, making Eigennet’s electronic records
          management his makes it an ideal solution for storing, accessing, and
          retrieving files quickly.
        </p>
        {/* <div className="row">
          <ServiceCard
            img={require("../Assets/DocumentConversion.png")}
            title="Document Conversion"
            description="Eigennet brings years of experience in converting documents for various organizations. We offer end-to-end document conversion services with robust records management and efficient AI-based automation, ensuring accurate bulk conversion while reducing the need for manual resources."
          />
          <ServiceCard
            img={require("../Assets/ElectronicRecords.png")}
            title="Electronic Records"
            description="Eigennet's electronic records management leverages extensive experience serving government and commercial clients. Our innovative solutions ensure efficient document sorting, metadata extraction, and accurate naming, offering clients dependable records management."
          />
          <ServiceCard
            img={require("../Assets/PhysicalRecords.png")}
            title="Physical Records"
            description="Eigennet offers efficient physical records management solutions, drawing from years of experience with government organizations. We streamline every phase, from document sorting and secure archiving to precise adherence to naming guidelines, ensuring hassle-free and accurate management."
          />
        </div> */}
      </div>

      {/* <div className="container">
        <SectionSeperatorBlueLine />
        <SubHeading heading="Custom Application Development" />
        <p
          style={{
            color: textColor,
            fontSize: paraFontSize,
            textAlign: "justify",
          }}
        ></p>
        <div className="row">
          <ServiceCard
            img={require("../Assets/GetToKnow.png")}
            title="Getting to know the Customer"
            description="We collaborate with clients through various channels, understanding their unique challenges. Our analysts deeply assess your needs, formulate comprehensive requirements, and present solutions to your organization. We prioritize clear communication and customer input to exceed expectations in every phase of the project."
          />
          <ServiceCard
            img={require("../Assets/DevelopmentAndProcess.png")}
            title="Development and Process"
            description="We ensure top-quality application development, customizing solutions in .NET, Java, Ruby, HTML5, and JavaScript. Our services encompass multi-tenancy, support, and security, creating a cohesive platform. We employ the right tools for your project, focusing on quality through rigorous testing to minimize defects."
          />
          <ServiceCard
            img={require("../Assets/CustomerValidation.png")}
            title="Customer Validation"
            description="To keep clients engaged, we offer samples, prototypes, demos, and proof of concepts. We value User Acceptance Testing (UAT) for feedback and defect refinement. Our commitment to the development lifecycle sets us apart, ensuring clients maintain a competitive edge and fostering long-term relationships."
          />
        </div>
      </div> */}

      {/* <div className="container">
        <SectionSeperatorBlueLine />
        <SubHeading heading="Copyright Legal & Litigation Support Services" />
        <p
          style={{
            color: textColor,
            fontSize: paraFontSize,
            textAlign: "justify",
          }}
        >
          Eigennet offers copyright related legal and litigation services for
          organizations dealing with large number of legal documents, appeals
          and cases. Eigennet's experience in working with federal agencies in
          this domain provided us the expertise required in dealing with
          numerous documents and reports. Eigennet’s team of paralegals are
          adept at reviewing documents and establishing correspondences,
          conducting weekly and monthly meetings, creating elaborate reports,
          and fishing out intricate and relevant details from the documents. We
          employ specific tools to ensure the labor cost and review times are
          vastly reduced. Furthermore, Eigennet’s expertise in records
          management ensures that proper metadata is extracted from electronic
          documents, and the physical documents are properly indexed and filed
          enabling proper case management. With this, Eigennet ensures that the
          day-to-day workflows are flawless without any roadblocks. With a team
          of experienced paralegals and attorneys, innovative document
          management and extraction tools and with tried and tested management
          procedures, Eigennet offers comprehensive copyright related legal and
          litigation support services.
        </p>
      </div> */}

      <div className="container">
        <SectionSeperatorBlueLine />
        <SubHeading heading="Office Administration" />
        <p
          style={{
            color: textColor,
            fontSize: paraFontSize,
            textAlign: "justify",
          }}
        >
          Eigennet takes pride in its ability to offer excellent office
          administration support through our vast pool of skilled professionals
          that are ready to alleviate any organizations administrative burdens.
          Our expertise lies in tailoring staffing solutions to meet the
          specific needs of our clients, scaling resources as required. Whether
          a project demands a surge in administrative support or steady hand,
          Eigennet flexes its resources accordingly and Eigennet’s team members
          undergoes meticulous selection, ensuring proficiency and capabilities
          of their roles. Entrusting office administration tasks to Eigennet
          guarantees a surge in efficiency and productivity for businesses and
          commitment to excellence, to deliver impeccable services, prioritizing
          attention to detail and professionalism. Upholding the utmost
          confidentiality and discretion, we employ stringent security
          protocols, safeguarding client data with unwavering diligence.
        </p>
      </div>

      <GetInTouchFooter page="Services" />
    </div>
  );
}

function ServiceCard({ img, title, description, onlyTwo = false }) {
  const textColor = "#1C2951";
  return (
    <div className={"col-12 col-md-6 p-1 " + (onlyTwo ? "" : "col-lg-4")}>
      <div className="p-1 p-lg-2 d-flex flex-column h-100">
        <img
          src={img}
          //   style={{ height: "188px", background: "black" }}
          className="w-100"
          alt="..."
        />
        <div
          className="py-1 px-3 h-100"
          style={{
            color: textColor,
            borderBottom: "1px solid rgb(28, 41, 81,0.25)",
            borderInline: "1px solid rgb(28, 41, 81,0.25)",
          }}
        >
          <h5
            className=" px-0 py-2 m-0"
            style={{ color: textColor, fontSize: "24px" }}
          >
            {title}
          </h5>
          <p
            className="px-0 m-0 pt-0 pb-3 pb-md-5"
            style={{ color: textColor, fontSize: "16px" }}
          >
            {description}
          </p>
        </div>
      </div>
    </div>
  );
}

function SubHeading({ heading }) {
  const textColor = "#1C2951";
  return (
    <>
      <MediaQuery maxWidth={575}>
        <h2 className="h4" style={{ color: textColor, fontWeight: "700" }}>
          {heading}
        </h2>
      </MediaQuery>
      <MediaQuery minWidth={575}>
        <h2 className="h3" style={{ color: textColor, fontWeight: "700" }}>
          {heading}
        </h2>
      </MediaQuery>
    </>
  );
}

function SectionSeperatorBlueLine() {
  return (
    <div
      className=" "
      style={{
        width: "100px",
        height: "8px",
        background: "#1C2951",
        marginTop: "30px",
        marginBottom: "20px",
      }}
    ></div>
  );
}
