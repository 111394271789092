import React, { useState, useEffect } from "react";
import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  Link,
} from "react-router-dom";

import "../index.css";

import Button from "react-bootstrap/Button";
import { Card, Col, Row } from "react-bootstrap";

import {
  FaFileAlt,
  FaBrain,
  FaRegLightbulb,
  FaSlidersH,
  FaRegFileCode,
  FaChartPie,
  FaFileCode,
  FaBook,
  FaHandsHelping,
  FaArrowRight,
} from "react-icons/fa";
import banner from "../Assets/Img/Bannerx.png";
import d2dBg from "../Assets/Img/d2dBg.png";
import card_icon from "../Assets/Img/card-icon1.png";
// import d2d_icon from "../Assets/Img/ios-square-d2d.png";
import d2d_imac from "../Assets/Img/d2dUploadpageIMACpng.png";
import gpo from "../Assets/Img/gpo.png";
import optum from "../Assets/Img/optum.png";
import axispoint from "../Assets/Img/axispoint.png";
import does from "../Assets/Img/does.png";
import logo_3 from "../Assets/Img/logo_3.png";
import abbvie from "../Assets/Img/abbvie.png";
import usnaval from "../Assets/Img/us naval hospital guam.png";
import loc from "../Assets/Img/loc.png";
import irs from "../Assets/Img/irs.png";
import afr from "../Assets/Img/afr.png";
import abmc from "../Assets/Img/ABMC.png";
// import ae from "../Assets/Img/ae.png";
import rfp from "../Assets/Img/RFP_logo1.svg";
import cmmidev from "../Assets/Img/cmmidevorg.png";
import iso from "../Assets/Img/iso.png";
import cmmisv from "../Assets/Img/cmmisvcorg.png";
import bg_mobile from "../Assets/Img/mobile-resv5.png";
// import d2dbooth from "../Assets/Img/D2dBooth/booth-1edit.png"
import d2dbooth2 from "../Assets/Img/D2dBooth/2.png";
import d2dbooth3 from "../Assets/Img/D2dBooth/3.png";
import d2dbooth4 from "../Assets/Img/D2dBooth/4.png";
import d2dbooth5 from "../Assets/Img/D2dBooth/5.png";
import d2d_new_icon from "../Assets/Img/doc-2-data-v2.svg";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import MediaQuery from "react-responsive";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// import BlogAnnouncement from "./BlogAnnouncement";
import new_gif from "../Assets/Img/new1.gif";
import openinnewtab from "../Assets/Img/OpenInNewTab.svg";

export default function HomePage(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  const buttonStyle = {
    borderColor: isClicked ? "#1c2951" : "#89c000",
    color: isClicked ? "#1c2951" : "#89c000",
    backgroundColor: "transparent",
    borderRadius: "0.25rem",
    padding: "0.5rem 1rem",
    fontWeight: "bold",
    transition: "all 0.3s ease-in-out",
    // marginTop:16
  };

  var settings = {
    dots: false,
    infinite: true,
    // speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    // initialSlide: 0,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var announcement_slider = {
    dots: false,
    infinite: true,
    // speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // initialSlide: 0,
    autoplay: true,
    speed: 4500,
    autoplaySpeed: 6000,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settings1 = {
    dots: false,
    infinite: true,
    // speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // initialSlide: 0,
    autoplay: true,
    speed: 4500,
    autoplaySpeed: 6000,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const cardRef = useRef(null);

  // useEffect(() => {
  //   const cardHeight = cardRef.current.clientHeight;
  //   const cards = document.querySelectorAll('.card');
  //   cards.forEach((card) => {
  //     card.style.height = `${cardHeight}px`;
  //   });
  // }, []);
  return (
    <>
      {/* this media query code is for Mobile and Tab */}
      <MediaQuery maxWidth={1200}>
        {/* <div className="container-fluid text-center bg-image"
    style={{height:'-webkit-fill-available',width:'-webkit-fill-available', backgroundImage: `url(${banner})`,backgroundSize: 'cover',backgroundRepeat:'no-repeat'}}>
    <div className="mask">
        <div className="container mt-3" style={{height:'12em'}}>
        <h1 className="mt-3"><b>Engineering the Mission with Innovation</b></h1>
          <h6 className="mb-3">Take your organization’s technology to the next level with solutions that are smart, efficient, and cost effective.</h6>
        </div>
        </div></div> */}
        <div
          className="container-fluid text-center bg-image"
          style={{
            width: "-webkit-fill-available",
            backgroundImage: `url(${bg_mobile})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            marginTop: "66px",
          }}
        >
          <div className="container">
            <div className="mask">
              <div className="row mt-1">
                <div className="col-10 text-black" style={{ color: "#1c2951" }}>
                  <h1
                    className="mb-3 mt-3"
                    style={{ textAlign: "left", fontSize: "2.5rem" }}
                  >
                    <b>Engineering The Mission With Innovation</b>
                  </h1>
                  <p
                    className="mb-3"
                    style={{ textAlign: "left", fontSize: "18px" }}
                  >
                    Transform your organization with cutting-edge, cost-saving
                    IT solutions powered by our exclusive products
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="container p-4">
<Slider {...settings1}>
<img src={d2dbooth} alt="d2d" style={{borderRadius:'15px',width:'-webkit-fill-available'}}/> 
<img src={d2dbooth2} alt="d2d" style={{borderRadius:'15px',width:'-webkit-fill-available'}}/> 
</Slider>
</div> */}
        </div>
        <div
          className="container mt-3 mb-4"
          style={{ padding: "25px", paddingBottom: "0" }}
        >
          <h2 className="col-title text-center pb-3 font-weight-bold">
            Announcements
          </h2>

          <Slider {...announcement_slider}>

          <div className="container">
                      <div style={{ fontSize: "18px" }}>
                        <img
                          src={new_gif}
                          alt="new_gif"
                          style={{ display: "inline-block" }}
                        />
Excited to announce that Eigennet has been awarded a new contractor from the U.S Naval Hospital Guam under the Resource Management Department. 
                       </div>
                    </div>
          <div className="container">
                      <div style={{ fontSize: "18px" }}>
Excited to announce that Eigennet has been awarded a new GSA Contract under Multiple Award Schedule for SBSA.
                      </div>
                    </div>
          <div className="container">
                      <div style={{ fontSize: "18px" }}>
                        {/* <img
                          src={new_gif}
                          alt="new_gif"
                          style={{ display: "inline-block" }}
                        /> */}
                          Eigennet's latest blog is out!{" "}
                          <a href="https://blog.eigennet.com/DocumentProcessingVsDocumentManagement"
                          // href="https://blog.eigennet.com/"
                          target="_blank"
                          className="mailink"
                          rel="noreferrer"> Read more </a>{" "}
                          to uncover the key distinctions between Document Processing and Document Management.

                      </div>
                    </div>
            <div className="container ">
              <div style={{ fontSize: "18px" }}>
                {/* <img
                  src={new_gif}
                  alt="new_gif"
                  style={{ display: "inline-block" }}
                /> */}
                Eigennet is going to exhibit the Doc2Data.ai and
                its capabilities at{" "}
                <a
                  href="https://digitalgovernment.com/2022-930gov-sponsors-exhibitors/"
                  target="_blank"
                  className="mailink"
                  rel="noreferrer"
                >
                  The Digital Government Institute
                </a>{" "}
                conference and tradeshow.
              </div>
            </div>
            <div className="container ">
              <p style={{ fontSize: "18px" }}>
                Eigennet has been awarded Cloud Computing and Cloud Related IT
                Professional Services 518210C and Document Production On-site
                and Off-site Services 561439 SINs.
                <br />
              </p>
            </div>
            <div className="container ">
              <p style={{ fontSize: "18px" }}>
                Eigennet has been appraised at level 2 of the CMMI Institute’s
                Capability Maturity Model Integration Certificate.
              </p>
            </div>

            {/* <div className="container p-0">
<p style={{fontSize:'18px'}}> New Blog added to our <a href="https://blog.eigennet.com/" target="_blank" className="mailink" rel="noreferrer">Research Lab</a> - Paper to Pixel: Understanding the Different Types of Document Digitization for Federal Government Agencies.<br/>
</p>
</div> */}
            {/* <div className="container p-0">
<p style={{fontSize:'18px'}}>
Eigennet Launches web site<a href="https://allforrecovery.org/" target="_blank" className="mailink" rel="noreferrer"> Allforrecovery.org</a> to support those impacted by Addiction.
</p>
</div> */}
          </Slider>

          <div className="container mt-4">
            <Slider {...settings1}>
              {/* <div className="container">
<img src={d2dbooth} alt='d2d' style={{margin:'auto',objectFit:'cover',height:'130px',width:'20rem'}}/>
</div> */}
              <div className="container">
                <img
                  src={d2dbooth2}
                  alt="d2d"
                  style={{
                    margin: "auto",
                    objectFit: "cover",
                    height: "130px",
                    width: "20rem",
                  }}
                />
              </div>
              <div className="container">
                <img
                  src={d2dbooth3}
                  alt="d2d"
                  style={{
                    margin: "auto",
                    objectFit: "cover",
                    height: "130px",
                    width: "20rem",
                  }}
                />
              </div>

              <div className="container">
                <img
                  src={d2dbooth4}
                  alt="d2d"
                  style={{
                    margin: "auto",
                    objectFit: "cover",
                    height: "130px",
                    width: "20rem",
                  }}
                />
              </div>
            </Slider>
          </div>
        </div>

        <div className="container mb-4">
          <h2
            className="col-title font-weight-bold text-center mb-4"
            style={{ color: "#1C2951" }}
          >
            Research Lab{" "}
          </h2>

          <div
            className="row d-flex flex-column"
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            {/* <div className="col-sm-12 col-md-12 col-lg-4 d-flex flex-column"  style={{justifyContent:'center', alignItems:'center'}}> */}
            <div className="container">
              <article className="AHcard1 mb-4">
                {/* <div className="row"> */}
                <div className="col-3">
                  <div className="AHthumb_n"></div>
                </div>
                <div className="col-9" style={{ float: "right" }}>
                  <div className="infos1" style={{ marginTop: "22px" }}>
                    <h2 className="AHtitle1">
                    Document Processing Vs Document Management{" "}
                      <img
                        src={openinnewtab}
                        width={15}
                        style={{ color: "primary" }}
                        alt="newTab"
                      />
                    </h2>
                    <br />
                    <a
                      type="button"
                      className="btn btn-sm btn-primary1 mt-2"
                      style={{ marginLeft: "80px" }}
                      href="https://blog.eigennet.com/DocumentProcessingVsDocumentManagement"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Read Blog
                    </a>
                  </div>
                </div>
                {/* </div> */}
              </article>
            </div>
            {/* </div> */}
            {/* <div className="col-sm-12 col-md-12 col-lg-4 d-flex flex-column"  style={{justifyContent:'center', alignItems:'center'}}> */}
            <div className="container">
              <article className="AHcard1 mb-4">
                {/* <div className="row"> */}
                <div className="col-3">
                  <div className="AHthumb1"></div>
                </div>
                <div className="col-9" style={{ float: "right" }}>
                  <div className="infos1" style={{ marginTop: "22px" }}>
                    <h2 className="AHtitle1">
                      Document Digitization for Federal Government Agencies{" "}
                      <img
                        src={openinnewtab}
                        width={15}
                        style={{ color: "primary" }}
                        alt="newTab"
                      />
                    </h2>
                    <br />
                    <a
                      type="button"
                      className="btn btn-sm btn-primary1 mt-2"
                      style={{ marginLeft: "80px" }}
                      href="https://blog.eigennet.com/post/171"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Read Blog
                    </a>
                  </div>
                </div>
                {/* </div> */}
              </article>
            </div>
            {/* </div> */}

            {/* <div className="col-sm-12 col-md-12 col-lg-4 d-flex flex-column " style={{justifyContent:'center', alignItems:'center'}}> */}
            <div className="container">
              <article className="AHcard1 mb-4">
                {/* <div className="row"> */}
                <div className="col-3">
                  <div className="AHthumbTwo"></div>
                </div>

                <div className="col-9" style={{ float: "right" }}>
                  <div
                    className="infos1"
                    style={{ marginTop: "34px", marginLeft: "34px" }}
                  >
                    <h2 className="AHtitle1">
                      PFL Benefits by State{" "}
                      <img
                        src={openinnewtab}
                        width={15}
                        style={{ color: "primary" }}
                        alt="newTab"
                      />
                    </h2>

                    <br />
                    <a
                      type="button"
                      className="btn btn-sm btn-primary1"
                      style={{ marginLeft: "50px" }}
                      href="https://blog.eigennet.com/Tableau"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Read Blog
                    </a>
                  </div>
                </div>
                {/* </div> */}
              </article>
            </div>
            {/* </div> */}
            {/* <div className="col-sm-12 col-md-12 col-lg-4 d-flex flex-column " style={{justifyContent:'center', alignItems:'center'}}> */}
            {/* <div className="container">
              <article className="AHcard1 mb-4">
                <div className="col-3">
                  <div className="AHthumbFour"></div>
                </div>
                <div className="col-9" style={{ float: "right" }}>
                  <div className="infos1" style={{ marginTop: "30px" }}>
                    <h2 className="AHtitle1">
                      Demographics of FEMA Incidents{" "}
                      <img
                        src={openinnewtab}
                        width={15}
                        style={{ color: "primary" }}
                        alt="newTab"
                      />
                    </h2>

                    <br />
                    <a
                      type="button"
                      className="btn btn-sm btn-primary1"
                      style={{ marginLeft: "80px" }}
                      href="https://blog.eigennet.com/FemaTableau"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Read Blog
                    </a>
                  </div>
                </div>
              </article>
            </div> */}
            {/* </div> */}

            {/* <div className="col-sm-12 col-md-6 col-lg-3 d-flex flex-column " style={{justifyContent:'center', alignItems:'end'}}>
            <article className="AHcard">
      <div className="AHthumbThree"></div>
      <div className="infos">
        <h2 className="AHtitle">Tableau VS. Qlik VS. Power BI</h2>
        
        <br/><br/>
        <a type="button" className="btn btn-primary1" href="https://blog.eigennet.com/TableauVsQlik" target="_blank" rel="noreferrer">Read Blog</a>
        
      </div>
    </article>
      </div> */}
          </div>
          <div style={{ textAlign: "center" }}>
            <a
              className="btn btn-sm btn-primary mt-3"
              href="https://blog.eigennet.com/"
              target="_blank"
              rel="noreferrer"
              style={{
                borderRadius: "6px",
                backgroundColor: "#89C000",
                border: "none",
              }}
            >
              View All
            </a>
          </div>
        </div>
        {/* <div className="container pr-4">
    <div className="row">

      <div className="col-sm-8 col-md-6 col-lg-4 mb-3">
            <article className="AHcard">
      <div className="AHthumb"></div>
      <div className="infos">
        <h2 className="AHtitle">Paper to Pixel: Understanding the Different Types of Document Digitization for Federal Government Agencies</h2>
        
        <br/>
        <button type="button" className="btn btn-primary1">Read Blog</button>
        
      </div>
    </article>
      </div>

      <div className="col-sm-8 col-md-6 col-lg-4 mb-3">
            <article className="AHcard">
      <div className="AHthumbTwo"></div>
      <div className="infos">
        <h2 className="AHtitle">Data Analytics with Power BI</h2>
        
        <br/><br/><br/><br/>
        <button type="button" className="btn btn-primary1">Read Blog</button>
        
      </div>
    </article>
      </div>

      <div className="col-sm-8 col-md-6 col-lg-4 mb-3">
            <article className="AHcard">
      <div className="AHthumbThree"></div>
      <div className="infos">
        <h2 className="AHtitle">Powercharge Lean Manufacturing with Internet of Things</h2>
        
        <br/><br/><br/>
        <button type="button" className="btn btn-primary1">Read Blog</button>
        
      </div>
    </article>
      </div>

    </div>
  </div> */}
      </MediaQuery>

      {/* this media query code is for laptop */}

      <MediaQuery maxWidth={1920}>
        <MediaQuery minWidth={1201}>
          <div
            className="container-fluid text-center bg-image"
            style={{
              width: "107%",
              backgroundImage: `url(${banner})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              marginTop: "66px",
            }}
          >
            <div className="mask">
              <div className="row d-flex justify-content-center align-items-center h-100">
                {/* <div className="col-9 text-black justify-content-center" style={{margin:'80px 0px 0px -108px',color:'#1c2951'}} >
          <h1 className="mb-3" style={{textAlign:'left',fontSize:'3.5rem'}}><b>Engineering The Mission With<br/> Innovation</b></h1>
          <p className="mb-3" style={{textAlign:'left',fontSize:'24px',fontWeight:'300'}}>Transform your organization with cutting-edge, cost-saving IT solutions<br/> powered by our exclusive products</p>
       
        </div> */}

                <div
                  className="container p-5"
                  style={{ margin: "10px 72px 0px -108px", color: "#1c2951" }}
                >
                  <h1
                    className="mb-3"
                    style={{
                      textAlign: "left",
                      fontSize: "3.3rem",
                      color: "#1c2951",
                      lineHeight: "3.5rem",
                    }}
                  >
                    <b>
                      Engineering The Mission With
                      <br /> Innovation
                    </b>
                  </h1>
                  <ul>
                    <div className="container p-0">
                      <p
                        style={{
                          textAlign: "left",
                          fontSize: "24px",
                          lineHeight: "2rem",
                        }}
                      >
                        Transform your organization with cutting-edge,
                        cost-saving IT solutions
                        <br /> powered by our exclusive products
                      </p>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-3">
            <div className="row">
              <div className="col-8">
                {/* <h3 className="col-title mb-2" style={{textAlign:'left'}}>Announcements</h3> */}
                <h2
                  className="col-title font-weight-bold text-left mb-2"
                  style={{ color: "#1C2951" }}
                >
                  Announcements{" "}
                </h2>

                <div>
                  <Slider {...announcement_slider}>
                  <div className="container p-0">
                      <div style={{ fontSize: "18px" }}>
                        <img
                          src={new_gif}
                          alt="new_gif"
                          style={{ display: "inline-block" }}
                        />
Excited to announce that Eigennet has been awarded a new contractor from the U.S Naval Hospital Guam under the Resource Management Department. 
                       </div>
                    </div>
                  <div className="container p-0">
                      <div style={{ fontSize: "18px" }}>
                       
Excited to announce that Eigennet has been awarded a new GSA Contract under Multiple Award Schedule for SBSA.
                      </div>
                    </div>
                    <div className="container p-0">
                      <div style={{ fontSize: "18px" }}>
                        {/* <img
                          src={new_gif}
                          alt="new_gif"
                          style={{ display: "inline-block" }}
                        /> */}
                          Eigennet's latest blog is out!{" "}                        
                          <a
                          href="https://blog.eigennet.com/DocumentProcessingVsDocumentManagement"
                          // href="https://blog.eigennet.com/"
                          target="_blank"
                          className="mailink"
                          rel="noreferrer"
                        >
                        Read more                       
                        </a>{" "}
                        to uncover the key distinctions between Document Processing and Document Management.

                      </div>
                    </div>
                    <div className="container p-0">
                      <div style={{ fontSize: "18px" }}>
                        {/* <img
                          src={new_gif}
                          alt="new_gif"
                          style={{ display: "inline-block" }}
                        /> */}
                        Eigennet has successfully exhibited the
                        Doc2Data.ai and its capabilities at{" "}
                        <a
                          href="https://digitalgovernment.com/2022-930gov-sponsors-exhibitors/"
                          target="_blank"
                          className="mailink"
                          rel="noreferrer"
                        >
                          The Digital Government Institute
                        </a>{" "}
                        conference and tradeshow.
                      </div>
                    </div>
                    <div className="container p-0">
                      <p style={{ fontSize: "18px" }}>
                        Eigennet has been awarded Cloud Computing and Cloud
                        Related IT Professional Services 518210C and Document
                        Production On-site and Off-site Services 561439 SINs.
                        <br />
                      </p>
                    </div>
                    <div className="container p-0">
                      <p style={{ fontSize: "18px" }}>
                        Eigennet has been appraised at level 2 of the CMMI
                        Institute’s Capability Maturity Model Integration
                        Certificate.
                      </p>
                    </div>

                    {/* <div className="container p-0">
<p style={{fontSize:'18px'}}> New Blog added to our <a href="https://blog.eigennet.com/" target="_blank" className="mailink" rel="noreferrer">Research Lab</a> - Paper to Pixel: Understanding the Different Types of Document Digitization for Federal Government Agencies.<br/>
</p>
</div> */}
                    {/* <div className="container p-0">
<p style={{fontSize:'18px'}}>
Eigennet Launches web site<a href="https://allforrecovery.org/" target="_blank" className="mailink" rel="noreferrer"> Allforrecovery.org</a> to support those impacted by Addiction.
</p>
</div> */}
                  </Slider>
                </div>
              </div>

              <div className="col-4">
                <br />
                <Slider {...settings1}>
                  {/* <div className="container">
<img src={d2dbooth} alt='d2d' style={{margin:'auto',objectFit:'cover',height:'130px',width:'20rem'}}/>
</div> */}
                  <div className="container">
                    <img
                      src={d2dbooth5}
                      alt="d2d"
                      style={{
                        margin: "auto",
                        objectFit: "cover",
                        height: "130px",
                        width: "20rem",
                      }}
                    />
                  </div>
                  <div className="container">
                    <img
                      src={d2dbooth2}
                      alt="d2d"
                      style={{
                        margin: "auto",
                        objectFit: "cover",
                        height: "130px",
                        width: "20rem",
                      }}
                    />
                  </div>
                  <div className="container">
                    <img
                      src={d2dbooth3}
                      alt="d2d"
                      style={{
                        margin: "auto",
                        objectFit: "cover",
                        height: "130px",
                        width: "20rem",
                      }}
                    />
                  </div>
                  <div className="container">
                    <img
                      src={d2dbooth4}
                      alt="d2d"
                      style={{
                        margin: "auto",
                        objectFit: "cover",
                        height: "130px",
                        width: "20rem",
                      }}
                    />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
          <div className="container mb-4">
            <h2
              className="col-title font-weight-bold text-left mb-4"
              style={{ color: "#1C2951" }}
            >
              Research Lab{" "}
            </h2>

            <div className="row">
              <div
                className="col-sm-12 col-md-6 col-lg-4 d-flex flex-column "
                style={{ justifyContent: "center" }}
              >
                <article className="AHcard">
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-4 d-flex flex-column ">
                      <div
                        className="AHthumb_New"
                        style={{
                          objectFit: "cover",
                          objectPosition: "top right",
                        }}
                      ></div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-8 d-flex flex-column pl-0 pr-3">
                      <div className="infos" style={{ marginTop: "22px" }}>
                        <h2 className="AHtitle">
                        Document Processing Vs Document Management
                        </h2>

                        <br />
                        <br />
                        <br />
                        <a
                          type="button"
                          className="btn btn-sm btn-primary1"
                          style={{ marginLeft: "80px", marginTop: "10px" }}
                          href="https://blog.eigennet.com/DocumentProcessingVsDocumentManagement"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Read Blog
                        </a>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
              <div
                className="col-sm-12 col-md-6 col-lg-4 d-flex flex-column "
                style={{ justifyContent: "center" }}
              >
                <article className="AHcard">
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-4 d-flex flex-column ">
                      <div
                        className="AHthumb"
                        style={{
                          objectFit: "cover",
                          objectPosition: "top right",
                        }}
                      ></div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-8 d-flex flex-column pl-0 pr-3">
                      <div className="infos" style={{ marginTop: "22px" }}>
                        <h2 className="AHtitle">
                          Document Digitization for Federal Government Agencies
                        </h2>

                        <br />
                        <br />
                        <br />
                        <a
                          type="button"
                          className="btn btn-sm btn-primary1"
                          style={{ marginLeft: "80px", marginTop: "10px" }}
                          href="https://blog.eigennet.com/post/171"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Read Blog
                        </a>
                      </div>
                    </div>
                  </div>
                </article>
              </div>

              <div
                className="col-sm-12 col-md-6 col-lg-4 d-flex flex-column "
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <article className="AHcard">
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-4 d-flex flex-column ">
                      <div className="AHthumbTwo"></div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-8 d-flex flex-column ">
                      <div className="infos" style={{ marginTop: "34px" }}>
                        <h2 className="AHtitle">PFL Benefits by State</h2>

                        <br />
                        <br />
                        <br />
                        <br />
                        <a
                          type="button"
                          className="btn btn-sm btn-primary1"
                          style={{ marginLeft: "70px", marginTop: "10px" }}
                          href="https://blog.eigennet.com/Tableau"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Read Blog
                        </a>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
              {/* <div
                className="col-sm-12 col-md-6 col-lg-4 d-flex flex-column "
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <article className="AHcard">
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-4 d-flex flex-column ">
                      <div className="AHthumbFour"></div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-8 d-flex flex-column ">
                      <div className="infos" style={{ marginTop: "30px" }}>
                        <h2 className="AHtitle">
                          Demographics of FEMA Incidents
                        </h2>

                        <br />
                        <br />
                        <br />
                        <a
                          type="button"
                          className="btn btn-sm btn-primary1"
                          style={{ marginLeft: "70px", marginTop: "20px" }}
                          href="https://blog.eigennet.com/FemaTableau"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Read Blog
                        </a>
                      </div>
                    </div>
                  </div>
                </article>
              </div> */}

              {/* <div className="col-sm-12 col-md-6 col-lg-3 d-flex flex-column " style={{justifyContent:'center', alignItems:'end'}}>
            <article className="AHcard">
      <div className="AHthumbThree"></div>
      <div className="infos">
        <h2 className="AHtitle">Tableau VS. Qlik VS. Power BI</h2>
        
        <br/><br/>
        <a type="button" className="btn btn-primary1" href="https://blog.eigennet.com/TableauVsQlik" target="_blank" rel="noreferrer">Read Blog</a>
        
      </div>
    </article>
      </div> */}
            </div>
            <div style={{ textAlign: "center" }}>
              <a
                className="btn btn-primary mt-3"
                href="https://blog.eigennet.com/"
                target="_blank"
                rel="noreferrer"
                style={{
                  borderRadius: "6px",
                  backgroundColor: "#89C000",
                  border: "none",
                  width: "14%",
                }}
              >
                View All
              </a>
            </div>
          </div>
          {/* <div className='container mb-5'>
    
<h3 className="col-title" style={{paddingBottom: '5px',textAlign:'left'}}><u>Announcements</u></h3>
<ul>
<div className="container" style={{fontSize:'14px'}}>
<p  style={{textAlign:'left',marginBottom:'0rem'}}><FaArrowRight/> Eigennet has been awarded Cloud Computing and Cloud Related IT Professional Services 518210C and Document Production On-site and<br/>&nbsp;&nbsp;&nbsp;&nbsp; Off-site Services 561439 SINs.</p>
<p  style={{textAlign:'left',marginBottom:'0rem'}}><FaArrowRight/> Eigennet is going to exhibit the Doc2Data.ai and its capabilities at <a href="https://digitalgovernment.com/2022-930gov-sponsors-exhibitors/" target="_blank" className="mailink" rel="noreferrer">The Digital Government Institute</a> conference and tradeshow.</p>

<p  style={{textAlign:'left',marginBottom:'0rem'}}><FaArrowRight/> Eigennet has been appraised at level 2 of the CMMI Institute’s Capability Maturity Model Integration Certificate.<br/></p>
<p  style={{textAlign:'left',marginBottom:'0rem'}}><FaArrowRight/> New Blogs added to our <a href="https://blog.eigennet.com/" target="_blank" className="mailink" rel="noreferrer">Research Lab</a>: BTC Blockchain Network, PFL Benefits by State.</p>
<p  style={{textAlign:'left',marginBottom:'0rem'}}><FaArrowRight/> Eigennet Launches web site<a href="https://allforrecovery.org/" target="_blank" className="mailink" rel="noreferrer"> Allforrecovery.org</a> to support those impacted by Addiction.</p>

<p  style={{textAlign:'left',marginBottom:'0rem'}}><FaArrowRight/><a href="https://blog.eigennet.com/" target="_blank" className="mailink" rel="noreferrer"> Research Lab </a>
     Launched with great insights on several of our Capabilities. </p>
   <ul style={{paddingLeft: "50px"}}>  
    <li style={{textAlign:"left",marginBottom:'0rem'}}>Check out our white paper on <a href="https://blog.eigennet.com/post/126" target="_blank" className="mailink" rel="noreferrer">Power BI.</a></li>
    <li style={{textAlign:"left",marginBottom:'0rem'}}>A comprehensive <a href="https://blog.eigennet.com/FemaTableau" target="_blank" className="mailink" rel="noreferrer">Tableau Dashboard</a> on FEMA 
    Events.</li> 
    <li style={{textAlign:"left",marginBottom:'0rem'}}>An emerging discussion on <a href="https://blog.eigennet.com/post/127" target="_blank" className="mailink" rel="noreferrer">IOT (Internet of Things).</a> 
    </li> 
    <li style={{textAlign:"left",marginBottom:'0rem'}}>Comparative Market analysis using <a href="https://blog.eigennet.com/post/130" target="_blank" className="mailink" rel="noreferrer">drawdown 
      metrics.</a> </li>
    </ul>
   </div>
</ul>
</div> */}
          {/* 
<div className="container">

<div className="marquee" id="maruqee1" >
  <p onMouseOver="document.getElementById('maruqee1').stop();" onMouseOut="document.getElementById('maruqee1').start();">
This is a sample scrolling text that has scrolls in the left direction.
</p>
</div>
</div> */}
          {/* <div className="container mb-4">
<h2 className="col-title font-weight-bold text-left mb-4" style={{color:'#1C2951'}}>Industry Updates </h2>


    <div className="row">

      <div className="col-sm-12 col-md-6 col-lg-4">
            <article className="AHcard">
                              
      <div className="AHthumb" style={{ objectFit:'cover',objectPosition:'top right' }} ></div>
      <div className="infos">
        <h2 className="AHtitle">Paper to Pixel: Understanding the Different Types of Document Digitization for Federal Government Agencies</h2>
        
        <br/><br/>
        <a type="button" className="btn btn-primary1" href="https://blog.eigennet.com/post/171" target="_blank" rel="noreferrer" >Read Blog</a>
        
      </div>
    </article>
      </div>

      <div className="col-sm-12 col-md-6 col-lg-4">
            <article className="AHcard">
      <div className="AHthumbTwo"></div>
      <div className="infos">
        <h2 className="AHtitle">Data Analytics with Power BI</h2>
        
        <br/><br/><br/><br/>
        <a type="button" className="btn btn-primary1" href="https://blog.eigennet.com/post/126" target="_blank" rel="noreferrer">Read Blog</a>
        
      </div>
    </article>
      </div>

      <div className="col-sm-12 col-md-6 col-lg-4">
            <article className="AHcard">
      <div className="AHthumbThree"></div>
      <div className="infos">
        <h2 className="AHtitle">Powercharge Lean Manufacturing with Internet of Things</h2>
        
        <br/><br/><br/><br/>
        <a type="button" className="btn btn-primary1" href="https://blog.eigennet.com/post/127" target="_blank" rel="noreferrer">Read Blog</a>
        
      </div>
    </article>
      </div>

    </div>
    <div style={{textAlign:'center'}}>
    <a className="btn btn-lg btn-primary mt-3" href="https://blog.eigennet.com/" target="_blank" rel="noreferrer" style={{borderRadius:'6px',backgroundColor:'#89C000',border:'none',width:'20%'}}>View All</a></div>
  </div> */}
          {/* </div> */}
          {/* </div>   */}
        </MediaQuery>
      </MediaQuery>

      {/* this is for ultra wide screen */}
      <MediaQuery minWidth={1921}>
        <div
          className="container text-center bg-image"
          style={{
            width: "101%",
            backgroundImage: `url(${banner})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            marginTop: "66px",
          }}
        >
          <div className="mask">
            <div className="row d-flex justify-content-center align-items-center h-100">
              {/* <div className="col-9 text-black justify-content-center" style={{margin:'80px 0px 0px -108px',color:'#1c2951'}} >
          <h1 className="mb-3" style={{textAlign:'left',fontSize:'3.5rem'}}><b>Engineering The Mission With<br/> Innovation</b></h1>
          <p className="mb-3" style={{textAlign:'left',fontSize:'24px',fontWeight:'300'}}>Transform your organization with cutting-edge, cost-saving IT solutions<br/> powered by our exclusive products</p>
       
        </div> */}

              <div
                className="container p-5"
                style={{ margin: "10px 72px 0px -108px", color: "#1c2951" }}
              >
                <h1
                  className="mb-3"
                  style={{
                    textAlign: "left",
                    fontSize: "3.3rem",
                    color: "#1c2951",
                    lineHeight: "0.9em",
                  }}
                >
                  <b>
                    Engineering The Mission With
                    <br /> Innovation
                  </b>
                </h1>
                <ul>
                  <div className="container">
                    <p
                      style={{
                        textAlign: "left",
                        fontSize: "24px",
                        lineHeight: "0.9em",
                      }}
                    >
                      Transform your organization with cutting-edge, cost-saving
                      IT solutions
                      <br /> powered by our exclusive products
                    </p>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-3">
          <div className="row">
            <div className="col-8">
              {/* <h3 className="col-title mb-2" style={{textAlign:'left'}}>Announcements</h3> */}
              <h2
                className="col-title font-weight-bold text-left mb-2"
                style={{ color: "#1C2951" }}
              >
                Announcements{" "}
              </h2>

              <div>
                <Slider {...announcement_slider}>
                <div className="container p-0">
                      <div style={{ fontSize: "18px" }}>
                        <img
                          src={new_gif}
                          alt="new_gif"
                          style={{ display: "inline-block" }}
                        />
Excited to announce that Eigennet has been awarded a new contractor from the U.S Naval Hospital Guam under the Resource Management Department. 
                       </div>
                    </div>
                <div className="container p-0">
                      <div style={{ fontSize: "18px" }}>

Excited to announce that Eigennet has been awarded a new GSA Contract under Multiple Award Schedule for SBSA.
                      </div>
                    </div>
                <div className="container p-0">
                      <div style={{ fontSize: "18px" }}>
                        {/* <img
                          src={new_gif}
                          alt="new_gif"
                          style={{ display: "inline-block" }}
                        /> */}
                          Eigennet's latest blog is out!{" "}                      
                          <a
                          href="https://blog.eigennet.com/DocumentProcessingVsDocumentManagement"
                          // href="https://blog.eigennet.com/"
                          target="_blank"
                          className="mailink"
                          rel="noreferrer"
                        >
                        Read more                     
                        </a>{" "}
                        to uncover the key distinctions between Document Processing and Document Management.

                      </div>
                    </div>

                  <div className="container p-0">
                    <div style={{ fontSize: "18px" }}>
                      {/* <img
                        src={new_gif}
                        alt="new_gif"
                        style={{ display: "inline-block" }}
                      /> */}
                      Eigennet has successfully exhibited the
                      Doc2Data.ai and its capabilities at{" "}
                      <a
                        href="https://digitalgovernment.com/2022-930gov-sponsors-exhibitors/"
                        target="_blank"
                        className="mailink"
                        rel="noreferrer"
                      >
                        The Digital Government Institute
                      </a>{" "}
                      conference and tradeshow.
                    </div>
                  </div>
                  <div className="container p-0">
                    <p style={{ fontSize: "18px" }}>
                      Eigennet has been awarded Cloud Computing and Cloud
                      Related IT Professional Services 518210C and Document
                      Production On-site and Off-site Services 561439 SINs.
                      <br />
                    </p>
                  </div>
                  <div className="container p-0">
                    <p style={{ fontSize: "18px" }}>
                      Eigennet has been appraised at level 2 of the CMMI
                      Institute’s Capability Maturity Model Integration
                      Certificate.
                    </p>
                  </div>

                  {/* <div className="container p-0">
<p style={{fontSize:'18px'}}> New Blog added to our <a href="https://blog.eigennet.com/" target="_blank" className="mailink" rel="noreferrer">Research Lab</a> - Paper to Pixel: Understanding the Different Types of Document Digitization for Federal Government Agencies.<br/>
</p>
</div> */}
                  {/* <div className="container p-0">
<p style={{fontSize:'18px'}}>
Eigennet Launches web site<a href="https://allforrecovery.org/" target="_blank" className="mailink" rel="noreferrer"> Allforrecovery.org</a> to support those impacted by Addiction.
</p>
</div> */}
                </Slider>
              </div>
            </div>

            <div className="col-4">
              <br />
              <Slider {...settings1}>
                {/* <div className="container">
<img src={d2dbooth} alt='d2d' style={{margin:'auto',objectFit:'cover',height:'130px',width:'20rem'}}/>
</div> */}
                <div className="container">
                  <img
                    src={d2dbooth2}
                    alt="d2d"
                    style={{
                      margin: "auto",
                      objectFit: "cover",
                      height: "130px",
                      width: "20rem",
                    }}
                  />
                </div>
                <div className="container">
                  <img
                    src={d2dbooth3}
                    alt="d2d"
                    style={{
                      margin: "auto",
                      objectFit: "cover",
                      height: "130px",
                      width: "20rem",
                    }}
                  />
                </div>

                <div className="container">
                  <img
                    src={d2dbooth4}
                    alt="d2d"
                    style={{
                      margin: "auto",
                      objectFit: "cover",
                      height: "130px",
                      width: "20rem",
                    }}
                  />
                </div>
              </Slider>
            </div>
          </div>
        </div>
        <div className="container mb-4">
          <h2
            className="col-title font-weight-bold text-left mb-4"
            style={{ color: "#1C2951" }}
          >
            Research Lab{" "}
          </h2>

          <div className="row">
            <div
              className="col-sm-12 col-md-6 col-lg-4 d-flex flex-column "
              style={{ justifyContent: "center" }}
            >
              <article className="AHcard">
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-4 d-flex flex-column ">
                    <div
                      className="AHthumb_New"
                      style={{
                        objectFit: "cover",
                        objectPosition: "top right",
                      }}
                    ></div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-8 d-flex flex-column pl-0 pr-3">
                    <div className="infos" style={{ marginTop: "22px" }}>
                      <h2 className="AHtitle">
                      Document Processing Vs Document Management
                      </h2>

                      <br />
                      <br />
                      <br />
                      <a
                        type="button"
                        className="btn btn-sm btn-primary1"
                        style={{ marginLeft: "80px" }}
                        href="https://blog.eigennet.com/DocumentProcessingVsDocumentManagement"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Read Blog
                      </a>
                    </div>
                  </div>
                </div>
              </article>
            </div>
            <div
              className="col-sm-12 col-md-6 col-lg-4 d-flex flex-column "
              style={{ justifyContent: "center" }}
            >
              <article className="AHcard">
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-4 d-flex flex-column ">
                    <div
                      className="AHthumb"
                      style={{
                        objectFit: "cover",
                        objectPosition: "top right",
                      }}
                    ></div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-8 d-flex flex-column pl-0 pr-3">
                    <div className="infos" style={{ marginTop: "22px" }}>
                      <h2 className="AHtitle">
                        Document Digitization for Federal Government Agencies
                      </h2>

                      <br />
                      <br />
                      <br />
                      <a
                        type="button"
                        className="btn btn-sm btn-primary1"
                        style={{ marginLeft: "80px" }}
                        href="https://blog.eigennet.com/post/171"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Read Blog
                      </a>
                    </div>
                  </div>
                </div>
              </article>
            </div>

            <div
              className="col-sm-12 col-md-6 col-lg-4 d-flex flex-column "
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <article className="AHcard">
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-4 d-flex flex-column ">
                    <div className="AHthumbTwo"></div>
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-8 d-flex flex-column ">
                    <div className="infos" style={{ marginTop: "34px" }}>
                      <h2 className="AHtitle">PFL Benefits by State</h2>

                      <br />
                      <br />
                      <br />
                      <br />
                      <a
                        type="button"
                        className="btn btn-sm btn-primary1"
                        style={{ marginLeft: "70px" }}
                        href="https://blog.eigennet.com/Tableau"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Read Blog
                      </a>
                    </div>
                  </div>
                </div>
              </article>
            </div>
            {/* <div
              className="col-sm-12 col-md-6 col-lg-4 d-flex flex-column "
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <article className="AHcard">
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-4 d-flex flex-column ">
                    <div className="AHthumbFour"></div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-8 d-flex flex-column ">
                    <div className="infos" style={{ marginTop: "30px" }}>
                      <h2 className="AHtitle">
                        Demographics of FEMA Incidents
                      </h2>

                      <br />
                      <br />
                      <br />
                      <a
                        type="button"
                        className="btn btn-sm btn-primary1"
                        style={{ marginLeft: "70px" }}
                        href="https://blog.eigennet.com/FemaTableau"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Read Blog
                      </a>
                    </div>
                  </div>
                </div>
              </article>
            </div> */}

            {/* <div className="col-sm-12 col-md-6 col-lg-3 d-flex flex-column " style={{justifyContent:'center', alignItems:'end'}}>
            <article className="AHcard">
      <div className="AHthumbThree"></div>
      <div className="infos">
        <h2 className="AHtitle">Tableau VS. Qlik VS. Power BI</h2>
        
        <br/><br/>
        <a type="button" className="btn btn-primary1" href="https://blog.eigennet.com/TableauVsQlik" target="_blank" rel="noreferrer">Read Blog</a>
        
      </div>
    </article>
      </div> */}
          </div>
          <div style={{ textAlign: "center" }}>
            <a
              className="btn btn-primary mt-3"
              href="https://blog.eigennet.com/"
              target="_blank"
              rel="noreferrer"
              style={{
                borderRadius: "6px",
                backgroundColor: "#89C000",
                border: "none",
                width: "14%",
              }}
            >
              View All
            </a>
          </div>
        </div>
        {/* <div className='container mb-5'>
    
<h3 className="col-title" style={{paddingBottom: '5px',textAlign:'left'}}><u>Announcements</u></h3>
<ul>
<div className="container" style={{fontSize:'14px'}}>
<p  style={{textAlign:'left',marginBottom:'0rem'}}><FaArrowRight/> Eigennet has been awarded Cloud Computing and Cloud Related IT Professional Services 518210C and Document Production On-site and<br/>&nbsp;&nbsp;&nbsp;&nbsp; Off-site Services 561439 SINs.</p>
<p  style={{textAlign:'left',marginBottom:'0rem'}}><FaArrowRight/> Eigennet is going to exhibit the Doc2Data.ai and its capabilities at <a href="https://digitalgovernment.com/2022-930gov-sponsors-exhibitors/" target="_blank" className="mailink" rel="noreferrer">The Digital Government Institute</a> conference and tradeshow.</p>

<p  style={{textAlign:'left',marginBottom:'0rem'}}><FaArrowRight/> Eigennet has been appraised at level 2 of the CMMI Institute’s Capability Maturity Model Integration Certificate.<br/></p>
<p  style={{textAlign:'left',marginBottom:'0rem'}}><FaArrowRight/> New Blogs added to our <a href="https://blog.eigennet.com/" target="_blank" className="mailink" rel="noreferrer">Research Lab</a>: BTC Blockchain Network, PFL Benefits by State.</p>
<p  style={{textAlign:'left',marginBottom:'0rem'}}><FaArrowRight/> Eigennet Launches web site<a href="https://allforrecovery.org/" target="_blank" className="mailink" rel="noreferrer"> Allforrecovery.org</a> to support those impacted by Addiction.</p>

<p  style={{textAlign:'left',marginBottom:'0rem'}}><FaArrowRight/><a href="https://blog.eigennet.com/" target="_blank" className="mailink" rel="noreferrer"> Research Lab </a>
     Launched with great insights on several of our Capabilities. </p>
   <ul style={{paddingLeft: "50px"}}>  
    <li style={{textAlign:"left",marginBottom:'0rem'}}>Check out our white paper on <a href="https://blog.eigennet.com/post/126" target="_blank" className="mailink" rel="noreferrer">Power BI.</a></li>
    <li style={{textAlign:"left",marginBottom:'0rem'}}>A comprehensive <a href="https://blog.eigennet.com/FemaTableau" target="_blank" className="mailink" rel="noreferrer">Tableau Dashboard</a> on FEMA 
    Events.</li> 
    <li style={{textAlign:"left",marginBottom:'0rem'}}>An emerging discussion on <a href="https://blog.eigennet.com/post/127" target="_blank" className="mailink" rel="noreferrer">IOT (Internet of Things).</a> 
    </li> 
    <li style={{textAlign:"left",marginBottom:'0rem'}}>Comparative Market analysis using <a href="https://blog.eigennet.com/post/130" target="_blank" className="mailink" rel="noreferrer">drawdown 
      metrics.</a> </li>
    </ul>
   </div>
</ul>
</div> */}
        {/* 
<div className="container">

<div className="marquee" id="maruqee1" >
  <p onMouseOver="document.getElementById('maruqee1').stop();" onMouseOut="document.getElementById('maruqee1').start();">
This is a sample scrolling text that has scrolls in the left direction.
</p>
</div>
</div> */}
        {/* <div className="container mb-4">
<h2 className="col-title font-weight-bold text-left mb-4" style={{color:'#1C2951'}}>Industry Updates </h2>


    <div className="row">

      <div className="col-sm-12 col-md-6 col-lg-4">
            <article className="AHcard">
                              
      <div className="AHthumb" style={{ objectFit:'cover',objectPosition:'top right' }} ></div>
      <div className="infos">
        <h2 className="AHtitle">Paper to Pixel: Understanding the Different Types of Document Digitization for Federal Government Agencies</h2>
        
        <br/><br/>
        <a type="button" className="btn btn-primary1" href="https://blog.eigennet.com/post/171" target="_blank" rel="noreferrer" >Read Blog</a>
        
      </div>
    </article>
      </div>

      <div className="col-sm-12 col-md-6 col-lg-4">
            <article className="AHcard">
      <div className="AHthumbTwo"></div>
      <div className="infos">
        <h2 className="AHtitle">Data Analytics with Power BI</h2>
        
        <br/><br/><br/><br/>
        <a type="button" className="btn btn-primary1" href="https://blog.eigennet.com/post/126" target="_blank" rel="noreferrer">Read Blog</a>
        
      </div>
    </article>
      </div>

      <div className="col-sm-12 col-md-6 col-lg-4">
            <article className="AHcard">
      <div className="AHthumbThree"></div>
      <div className="infos">
        <h2 className="AHtitle">Powercharge Lean Manufacturing with Internet of Things</h2>
        
        <br/><br/><br/><br/>
        <a type="button" className="btn btn-primary1" href="https://blog.eigennet.com/post/127" target="_blank" rel="noreferrer">Read Blog</a>
        
      </div>
    </article>
      </div>

    </div>
    <div style={{textAlign:'center'}}>
    <a className="btn btn-lg btn-primary mt-3" href="https://blog.eigennet.com/" target="_blank" rel="noreferrer" style={{borderRadius:'6px',backgroundColor:'#89C000',border:'none',width:'20%'}}>View All</a></div>
  </div> */}
        {/* </div> */}
        {/* </div>   */}
      </MediaQuery>

      {/* Rendering a blog announcement component for displaying the latest blog. */}
      {/* <BlogAnnouncement/> */}

      <div className="container mt-5">
        <div className="row">
          {/* This media query is for hiding the image card in mobile */}
          <MediaQuery minWidth={930}>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <img
                src={card_icon}
                alt="card_icon"
                width={300}
                style={{
                  borderRadius: "20px",
                  boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.25)",
                  // paddingLeft: '0px',
                }}
              />
            </div>
          </MediaQuery>

          {/* this media query code is for laptop */}

          <MediaQuery minWidth={930}>
            <div
              className="col-sm-12 col-md-6 col-lg-8 d-flex flex-column"
              style={{ justifyContent: "center" }}
            >
              <h2 className="font-weight-bold" style={{ color: "#1c2951" }}>
                <i>Leading the Way in AI-Enabled Document Processing</i>
              </h2>
              <p className="mb-2" style={{ fontSize: "18px" }}>
                <i>
                  We define AI-powered document processing and IT solutions for
                  US Federal agencies. Our dynamic team crafts bespoke,
                  cutting-edge services that fuel efficiency and success.
                  Experience the Eigennet difference as we transform the way
                  federal organizations manage their documents and IT
                  infrastructure, setting new standards in service and quality.
                </i>
              </p>
              <Link to="Doc2data">
                <button
                  type="button"
                  className="btn"
                  style={buttonStyle}
                  onClick={handleClick}
                >
                  Know More &nbsp; <FaArrowRight />
                </button>{" "}
              </Link>
            </div>
          </MediaQuery>

          {/* this media query code is for Mobile */}
          <MediaQuery maxWidth={929}>
            <div className="container">
              <h2 className="col-title font-weight-bold">
                Leading the Way in AI-Enabled Document Processing
              </h2>
              <p className="mb-2" style={{ fontSize: "18px" }}>
                We define AI-powered document processing and IT solutions for US
                Federal agencies. Our dynamic team crafts bespoke, cutting-edge
                services that fuel efficiency and success. Experience the
                Eigennet difference as we transform the way federal
                organizations manage their documents and IT infrastructure,
                setting new standards in service and quality.
              </p>

              <Link to="OurTeam">
                <button
                  type="button"
                  className="btn text-center"
                  style={buttonStyle}
                  onClick={handleClick}
                >
                  Know More &nbsp; <FaArrowRight />
                </button>{" "}
              </Link>
            </div>
          </MediaQuery>
        </div>
      </div>

      <div className="container mt-5">
        <MediaQuery minWidth={930}>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-12 mb-2 text-center">
              <h2
                className="col-title font-weight-bold text-center"
                style={{ color: "#1C2951" }}
              >
                Our Services{" "}
              </h2>
            </div>
          </div>
          <p style={{ fontSize: "18px", textAlign: "center" }}>
            Discover the range of exceptional services we offer at Eigennet LLC,
            designed to empower your organization&#8217;s mission. Our expertise
            spans Document Processing, Custom Application Development, and
            Personnel Support Services, tailored to meet your unique needs.
          </p>
        </MediaQuery>

        <MediaQuery maxWidth={929}>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 text-center">
              <h2
                className="col-title font-weight-bold"
                style={{ textAlign: "center" }}
              >
                Our Services{" "}
              </h2>
            </div>
          </div>
          <p className="mb-2" style={{ fontSize: "18px", textAlign: "center" }}>
            Discover the range of exceptional services we offer at Eigennet LLC,
            designed to empower your organization&#8217;s mission. Our expertise
            spans Document Processing, Custom Application Development, and
            Personnel Support Services, tailored to meet your unique needs.
          </p>
        </MediaQuery>
      </div>

      <div className="container">
        <Row className="mt-3">
          <Col xs={12} sm={12} md={12} lg={4} className="mb-3">
            <Card
              style={{
                width: "-webkit-fill-available",
                boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.25)",
                borderRadius: "8px",
              }}
            >
              <Card.Body>
                <Card.Title>
                  <h4 className="font-weight-bold" style={{ color: "#1C2951" }}>
                    <FaFileCode size={26} style={{ color: "#89c000" }} />{" "}
                    AI-Driven Document Processing Solutions
                  </h4>
                </Card.Title>
                <Card.Text>
                  <span style={{ fontSize: "16px" }}>
                    Experience the power of AI-driven Document Processing with
                    our cutting-edge tools designed to optimize and automate
                    critical processes for organizations. Our flagship product
                    Doc2Data.ai can enhance and foster organizational success.{" "}
                  </span>{" "}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} className="mb-3">
            <Card
              style={{
                width: "-webkit-fill-available",
                boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.25)",
                borderRadius: "8px",
              }}
            >
              <Card.Body>
                <Card.Title>
                  <h4 className="font-weight-bold" style={{ color: "#1C2951" }}>
                    <FaBook size={26} style={{ color: "#89c000" }} /> Library
                    Support Solutions
                  </h4>
                </Card.Title>
                <Card.Text>
                  <span style={{ fontSize: "16px" }}>
                    We leverage our library expertise to offer clients
                    comprehensive solutions for record processing, electronic
                    list creation, accessioning, and collections management. Our
                    streamlined workflows and collaborative approach ensure
                    prompt and tangible results for all your library needs.
                  </span>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} className="mb-2">
            <Card
              style={{
                width: "-webkit-fill-available",
                boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.25)",
                borderRadius: "8px",
              }}
            >
              <Card.Body>
                <Card.Title>
                  <h4 className="font-weight-bold" style={{ color: "#1C2951" }}>
                    <FaHandsHelping size={26} style={{ color: "#89c000" }} />{" "}
                    Federal Agency Staffing Solutions
                  </h4>
                </Card.Title>
                <Card.Text>
                  <span style={{ fontSize: "16px" }}>
                    Enhance your workforce with our comprehensive staffing
                    services, providing skilled contractors to meet your
                    organization's needs. Our carefully vetted professionals
                    provide their expertise, and we enable seamless
                    collaboration boosting the success of your projects.
                  </span>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* <div className="row">
  <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
    <div className="card" style={{width:'-webkit-fill-available',
        boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.25)',
        borderRadius:'8px',height:'-webkit-fill-available'}}>
  <div className="card-body">
    <div className="row">
      <div className="col-1">
      <FaFileCode style={{color:'#89c000'}}/>
      </div>
      <div className="col-10">
    <h4 className="card-title font-weight-bold" style={{color:'#1C2951'}}> AI-Driven Document Management Solutions</h4>
    <p className="card-text">Experience the power of AI-driven Document Management with our cutting-edge tools designed to optimize and automate critical processes for organizations. Our flagship product Doc2Data.ai can enhance and foster organizational success.      
</p>
</div>
</div>
  </div>
</div>
</div>
<div className="col-sm-12 col-md-6 col-lg-4 mb-3">

<div className="card" style={{width:'-webkit-fill-available',
        boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.25)',
        borderRadius:'8px',height:'-webkit-fill-available'}}>
  <div className="card-body">
    <div className="row">
      <div className="col-1">
      <FaBook style={{color:'#89c000'}}/>
      </div>
      <div className="col-10">comprehensive
          <h4 className="card-title font-weight-bold" style={{color:'#1C2951'}}>Library Support<br/> Solutions</h4>
    <p className="card-text">We leverage our library expertise to offer clients  solutions for record processing, electronic list creation, accessioning, and collections management. Our streamlined workflows and collaborative approach ensure prompt and tangible results for all your library needs.
</p>
</div>
</div>
  </div>
</div>
</div>

<div className="col-sm-12 col-md-6 col-lg-4 mb-3">

<div className="card" style={{width:'-webkit-fill-available',
        boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.25)',
        borderRadius:'8px',height:'-webkit-fill-available'}}>
  <div className="card-body">
    <div className="row">
      <div className="col-">
      <FaHandsHelping style={{color:'#89c000'}}/>
      </div>
      <div className="col-11">
          <h4 className="card-title font-weight-bold" style={{color:'#1C2951'}}> Federal Agency Staffing Solutions</h4>
    <p className="card-text">Enhance your workforce with our comprehensive staffing services, providing skilled contractors to meet your organization's needs. Our carefully vetted professionals provide their expertise, and we enable seamless collaboration boosting the success of your projects.
</p>
</div>
</div>
  </div>
</div>
</div>
</div> */}
      </div>

      <div className="container mb-2 mt-5">
        <h2
          className="col-title mb-2 mt-4 text-center font-weight-bold"
          style={{ color: "#1C2951" }}
        >
          Transform Your Business With Our Tech
        </h2>
        <p className="mb-2" style={{ fontSize: "18px", textAlign: "center" }}>
          Discover Eigennet’s versatile, ready-to-use products designed for
          diverse, innovative solutions across industries.
        </p>

        {/* laptop view */}
        <MediaQuery minWidth={1209}>
          <div
            className="container text-center bg-image"
            style={{
              width: "-webkit-fill-available",
              backgroundImage: `url(${d2dBg})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              borderRadius: "20px",
              padding: "20px",
            }}
          >
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 mt-3 text-center">
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-8 mt-2 mb-2 pr-0">
                    <h2
                      style={{
                        color: "white",
                        lineHeight: "0.9",
                        paddingTop: "12px",
                        textAlign: "left",
                      }}
                    >
                      <i>Introducing Eigennet’s Flagship Product</i>
                    </h2>
                  </div>
                  <div
                    className="col-sm-12 col-md-6 col-lg-4 mt-3 mb-3 pl-0 d-flex flex-column"
                    style={{
                      justifyContent: "center",
                      backgroundColor: "white",
                      borderRadius: "20px",
                    }}
                  >
                    <a
                      href="https://doc2data.ai/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={d2d_new_icon}
                        alt="card_icon"
                        width={185}
                        style={{ paddingLeft: "8px", paddingRight: "8px" }}
                      />
                    </a>
                  </div>
                </div>
                <p
                  className="mt-2"
                  style={{
                    color: "white",
                    textAlign: "left",
                    fontSize: "20px",
                    fontWeight: "300",
                  }}
                >
                  Eigennet offers unmatched document processing experience and
                  takes pride in our product Doc2Data.ai, transforming files
                  into searchable and scalable data assets. Using
                  state-of-the-art tools, Artificial Intelligence, and
                  Intelligence Augmentation, we seamlessly extract, convert, and
                  systematize critical data from diverse documents, images, and
                  formats. Customizability and automated Quality Control are
                  Doc2Data.ai’s biggest strengths.
                </p>
                <Button
                  variant="primary mt-3"
                  target="_blank"
                  href="https://doc2data.ai/"
                  style={{
                    backgroundColor: "#89c000",
                    float: "left",
                    borderRadius: "6px",
                    border: "0",
                  }}
                  size="lg"
                >
                  Learn More
                </Button>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 mt-3 text-center">
                <img src={d2d_imac} alt="card_icon" width={520} />
              </div>
            </div>
          </div>
        </MediaQuery>

        {/* mobile view */}
        <MediaQuery maxWidth={1208}>
          <div
            className="container bg-image"
            style={{
              width: "-webkit-fill-available",
              backgroundImage: `url(${d2dBg})`,
              backgroundSize: "cover",
              backgroundRepeat: "repeat",
              borderRadius: "20px",
              padding: "20px",
            }}
          >
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 mt-4 text-center">
                <h3 style={{ color: "white", textAlign: "left" }}>
                  <i>Introducing Eigennet’s Flagship Product</i>
                  <br />
                  <a
                    href="https://doc2data.ai/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="mt-2"
                      src={d2d_new_icon}
                      alt="card_icon"
                      width={200}
                      style={{ backgroundColor: "white", borderRadius: "20px" }}
                    />
                  </a>
                </h3>
                <p
                  style={{
                    color: "white",
                    textAlign: "left",
                    fontSize: "18px",
                  }}
                >
                  Eigennet offers unmatched document processing experience and
                  takes pride in our product Doc2Data.ai, transforming files
                  into searchable and scalable data assets. Using
                  state-of-the-art tools, Artificial Intelligence, and
                  Intelligence Augmentation, we seamlessly extract, convert, and
                  systematize critical data from diverse documents, images, and
                  formats. Customizability and automated Quality Control are
                  Doc2Data.ai’s biggest strengths.
                </p>
                <Button
                  variant="primary"
                  target="_blank"
                  href="https://doc2data.ai/"
                  style={{
                    backgroundColor: "#89c000",
                    float: "left",
                    borderRadius: "6px",
                    border: "0",
                  }}
                  size="lg"
                >
                  Learn More
                </Button>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 mt-4 text-center">
                <img
                  src={d2d_imac}
                  alt="card_icon"
                  style={{ width: "-webkit-fill-available" }}
                />
              </div>
            </div>
          </div>
        </MediaQuery>
      </div>

      {/* laptop view */}
      <MediaQuery minWidth={1230}>
        {/* <div className="container"> */}
        {/* <div className="float-container" > */}
        {/* <div className="row"> */}
        {/* <div className="float-child" >
    <div className="fcard" >
        <div className="front side">
            <div className="content">
                <h1>AROUND EARNINGS</h1>
                <p>Invest confidently using Around Earnings, our smart app that predicts
                    high-potential stocks and keeps you informed with timely alerts</p>
            </div>
        </div>
        <div className="back side">
            <div className="content">
                <img src={ae} alt="AroundEamings_logo" width="200" height="200"/><br/>
                <Button href="https://aroundearnings.com" target="_blank" variant="warning" className="mt-3" style={{backgroundColor:'#dcb663',color:'white',border:'0'}} size="lg">Learn More</Button>{' '}
              

  
            </div>
        </div>
    </div>
</div> */}

        {/* <div className="float-child"> */}
        {/* <div className="fcard">
        <div className="front2 side">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 d-flex flex-column"  style={{justifyContent:'center',paddingLeft:'0'}}>
                <p style={{textAlign:'left',fontSize:'20px',fontWeight:'300'}}>The Al-based tool streamlines proposal management by organizing and
                    tracking RFx data for faster responses and intemal reviews</p>
        <Button href="https://rfp-engine.com" target="_blank" variant="success" style={{backgroundColor:'#89C000',color:'white',border:'0',float:'left'}} size="lg">Learn More</Button>{' '}
        </div> */}
        {/* <div className="col-sm-12 col-md-12 col-lg-6 d-flex flex-column" style={{justifyContent:'center'}}> */}
        {/* <div className="content"> */}
        {/* <img src={rfp} alt="RFP_logo" width="200" height="200"/> */}
        {/* <h1>RFP ENGINE</h1> */}
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}
        {/* <div className="back2 side">
            <div className="content">
            
            </div>
        </div> */}
        {/* </div> */}
        {/* </div> */}

        {/* </div> */}
        {/* </div> */}
        {/* </div> */}
        <div className="container mt-2">
          <div
            className="container"
            style={{
              backgroundColor: "#0D2D4A",
              padding: "20px",
              borderRadius: "20px",
            }}
          >
            <div className="row">
              <div
                className="col-sm-12 col-md-12 col-lg-6 flex-column"
                style={{ justifyContent: "center", paddingLeft: "15px" }}
              >
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "20px",
                    fontWeight: "300",
                    color: "white",
                  }}
                >
                  The AI-based tool streamlines proposal management by
                  organizing and tracking RFx data for faster responses and
                  internal reviews
                </p>
                <Button
                  href="https://rfp-engine.com"
                  target="_blank"
                  variant="success"
                  style={{
                    backgroundColor: "#C7BB8E",
                    float: "left",
                    borderRadius: "6px",
                    border: "0",
                  }}
                  size="lg"
                >
                  Learn More
                </Button>{" "}
              </div>
              <div
                className="col-sm-12 col-md-12 col-lg-6 d-flex flex-column"
                style={{ justifyContent: "center" }}
              >
                {/* <div className="content"> */}
                <img src={rfp} alt="RFP_logo" width="350" height="150" />
                {/* <h1>RFP ENGINE</h1> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </MediaQuery>

      {/* mobile view */}
      <MediaQuery maxWidth={1220}>
        <div className="container mt-2">
          <div
            className="container"
            style={{
              backgroundColor: "#0D2D4A",
              padding: "20px",
              borderRadius: "20px",
            }}
          >
            <div className="row">
              <div
                className="col-sm-12 col-md-6 col-lg-6 d-flex flex-column "
                style={{ justifyContent: "center" }}
              >
                {/* <div className="content"> */}
                <img src={rfp} alt="RFP_logo" width="250" height="100" />
                {/* <h1>RFP ENGINE</h1> */}
                {/* </div> */}
              </div>
              <div
                className="col-sm-12 col-md-6 col-lg-6 flex-column mt-3"
                style={{ justifyContent: "center", paddingLeft: "20px" }}
              >
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "20px",
                    fontWeight: "300",
                    color: "white",
                  }}
                >
                  The AI-based tool streamlines proposal management by
                  organizing and tracking RFx data for faster responses and
                  internal reviews
                </p>
                <Button
                  href="https://rfp-engine.com"
                  target="_blank"
                  variant="success"
                  style={{
                    backgroundColor: "#C7BB8E",
                    color: "white",
                    border: "0",
                    float: "left",
                  }}
                  size="lg"
                >
                  Learn More
                </Button>{" "}
              </div>
            </div>
          </div>
        </div>
      </MediaQuery>

      <div className="container mt-5">
        <h2
          className="col-title mb-2 font-weight-bold"
          style={{ color: "#1C2951", textAlign: "center" }}
        >
          AI-Enhanced Solutions
        </h2>
        <p
          className="mb-2"
          style={{ fontSize: "18px", color: "#1C2951", textAlign: "center" }}
        >
          Empowering Organizations with Cutting-Edge Technology
        </p>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 mb-2 mt-3">
            <div className="row">
              <div className="col-1">
                <FaFileAlt size={28} style={{ color: "89c000" }} />
              </div>
              <div className="col-11">
                <h5 style={{ color: "#1C2951" }}>
                  {" "}
                  Al-Powered Document Processing:
                </h5>
                <p>
                  Streamline data extraction, processing, and compliance with
                  Doc2Data.ai.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-1">
                <FaBrain size={28} style={{ color: "89c000" }} />
              </div>
              <div className="col-11">
                <h5 style={{ color: "#1C2951" }}>
                  Natural Language Processing:
                </h5>
                <p>
                  {" "}
                  Enhance efficiency and accuracy in document handling and data
                  extraction.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-1">
                <FaRegLightbulb size={28} style={{ color: "89c000" }} />
              </div>
              <div className="col-11">
                <h5 style={{ color: "#1C2951" }}> Agile Solutions:</h5>
                <p>
                  {" "}
                  Adapt to organizational needs seamlessly with Al-enhanced
                  technology.
                </p>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-6 mb-2 mt-3">
            <div className="row">
              <div className="col-1">
                <FaSlidersH size={28} style={{ color: "89c000" }} />
              </div>
              <div className="col-11">
                <h5 style={{ color: "#1C2951" }}> Custom Al Applications:</h5>
                <p>
                  Develop tailored solutions using the latest Al technologies
                  for improved outcomes.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-1">
                <FaRegFileCode size={28} style={{ color: "89c000" }} />
              </div>
              <div className="col-11">
                <h5 style={{ color: "#1C2951" }}>
                  {" "}
                  Automated Error Detection:
                </h5>
                <p>
                  Reduce manual intervention and boost accuracy with AI-driven
                  compliance checks.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-1">
                <FaChartPie size={28} style={{ color: "89c000" }} />
              </div>
              <div className="col-11">
                <h5 style={{ color: "#1C2951" }}>Smart Alerts For Trading:</h5>
                <p>
                  {" "}
                  Make informed investment decisions with Around Earning's smart
                  algorithms.
                </p>
              </div>
            </div>
          </div>

          {/* <MediaQuery maxWidth={929}>
      <div className="col-sm-12 col-md-6 col-lg-6 mt-3">

        <div className="row">
      <div className="col-1">
      <FaFileAlt style={{color:'89c000'}}/> 
      </div>
      <div className="col-10">
      <h5 style={{color:'#1C2951'}}> Al-Powered Document Management:</h5>
      <p>Streamline data extraction, processing, and compliance with Doc2Data.ai.</p>
      </div>
      </div>

      <div className="row">
        <div className="col-1">
        <FaBrain style={{color:'89c000'}}/> 
        </div>
        <div className="col-11">
      <h5 style={{color:'#1C2951'}}>Natural Language Processing:</h5>
      <p> Enhance efficiency and accuracy in document handling and data extraction.</p>
      </div>
      </div>

<div className="row">
  <div className="col-1">
  <FaRegLightbulb style={{color:'89c000'}}/>
  </div>
  <div className="col-11">
      <h5 style={{color:'#1C2951'}}> Agile Solutions:</h5>
      <p> Adapt to organizational needs seamlessly with Al-enhanced technology.</p>
      </div>
      </div>
      </div>


      <div className="col-sm-12 col-md-6 col-lg-6 mb-2">
        <div className="row">  
        <div className="col-1">
        <FaSlidersH style={{color:'89c000'}}/>
        </div>
        <div className="col-11">
        <h5 style={{color:'#1C2951'}}> Custom Al Applications:</h5>
      <p>Develop tailored solutions using the latest Al technologies for improved outcomes.</p>
      </div>
      </div>

<div className="row">
  <div className="col-1">
  <FaRegFileCode style={{color:'89c000'}}/>
  </div>
  <div className="col-11">
      <h5 style={{color:'#1C2951'}}> Automated Error Detection:</h5>
      <p>Reduce manual intervention and boost accuracy with AI-driven compliance checks.</p>
      </div>
      </div>

      <div className="row">
        <div className="col-1">
        <FaChartPie style={{color:'89c000'}}/> 
        </div>
        <div className="col-11">
      <h5 style={{color:'#1C2951'}}>Smart Alerts For Trading:</h5>
      <p> Make informed investment decisions with Around Earning's smart algorithms.</p>
      </div>
      </div>
      </div>
      </MediaQuery> */}
        </div>
      </div>

      <div className="container mt-3" style={{ padding: "25px" }}>
        <h2
          className="col-title mb-2 text-center font-weight-bold"
          style={{ color: "#1C2951" }}
        >
          Our Clients
        </h2>
        <p className="mb-2 text-center" style={{ fontSize: "18px" }}>
          We strive to serve our clients beyond their expectations. Our clients
          include Government, Non-Profits, and Private sector.
        </p>

        <Slider {...settings}>
        <div className="container">
            <a href="https://guam.tricare.mil/" target="_blank" rel="noreferrer">
              <img
                className="align-left wp-image-168"
                src={usnaval}
                alt="US NAVAL"
                style={{ margin: "auto" }}
              />
            </a>
          </div>
          <div className="container">
            <a href="https://www.loc.gov/" target="_blank" rel="noreferrer">
              <img
                className="align-left wp-image-168"
                src={loc}
                alt="LOC"
                style={{ margin: "auto" }}
              />
            </a>
          </div>

          <div className="container">
            <a href="https://does.dc.gov/" target="_blank" rel="noreferrer">
              <img
                className="align-left wp-image-168"
                src={does}
                alt="DC DOES"
                style={{ margin: "auto" }}
              />
            </a>
          </div>

          <div className="container">
            <a
              href="http://allforrecovery.org/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="align-left wp-image-168"
                src={afr}
                alt="AFR"
                style={{ margin: "auto" }}
              />
            </a>
          </div>

          <div className="container">
            <a href="https://www.optum.com/" target="_blank" rel="noreferrer">
              <img
                className="align-left wp-image-168"
                src={optum}
                alt="Optum"
                style={{ margin: "auto" }}
              />
            </a>
          </div>

          <div className="container">
            <a href="https://www.abbvie.com/" target="_blank" rel="noreferrer">
              <img
                className="align-left wp-image-168"
                src={abbvie}
                alt="Abvvie"
                style={{ margin: "auto" }}
              />
            </a>
          </div>

          <div className="container">
            <a
              href="https://axispointhealth.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="align-left wp-image-168"
                src={axispoint}
                alt="Axis Point Health"
                style={{ margin: "auto" }}
              />
            </a>
          </div>

          <div className="container">
            <a href="https://www.gpo.gov/" target="_blank" rel="noreferrer">
              <img
                className="align-left wp-image-168"
                src={gpo}
                alt="gpo"
                style={{ margin: "auto" }}
              />
            </a>
          </div>

          <div className="container">
            <a
              href="https://www.heritageabroad.gov/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="align-left wp-image-168"
                src={logo_3}
                alt="heritage"
                style={{ margin: "auto" }}
              />
            </a>
          </div>

          <div className="container">
            <a href="https://www.irs.gov/" target="_blank" rel="noreferrer">
              <img
                className="align-left wp-image-168"
                src={irs}
                alt="irs"
                style={{ margin: "auto" }}
              />
            </a>
          </div>

          <div className="container">
            <a href="https://www.abmc.gov/" target="_blank" rel="noreferrer">
              <img
                className="align-left wp-image-168"
                src={abmc}
                alt="abmc"
                style={{ margin: "auto" }}
              />
            </a>
          </div>
        </Slider>
      </div>

      <div className="container mb-4 mt-5">
        <h2
          className="col-title text-center font-weight-bold"
          style={{ color: "#1C2951" }}
        >
          Certifications{" "}
        </h2>
        <p className="text-center" style={{ fontSize: "18px" }}>
          Certifications that vouch for our excellence
        </p>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-4 mb-2 mt-2 ">
            <ul className="ml-3">
              <li>CMMI Development Level 2 Certification</li>
              <li>CMMI Service Level 2 Certification</li>
              <li>ISO Certifications: ISO 9001:2015</li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-8 mb-2 mt-2 ">
            <span style={{ width: "30%", verticalAlign: "middle" }}>
              <img
                className="wp-image-129"
                style={{ width: "18em", marginRight: "0.5em" }}
                src={cmmidev}
                alt="CMMI"
              />
            </span>
            <span style={{ width: "30%", verticalAlign: "middle" }}>
              <img
                className="wp-image-129"
                style={{ width: "18em", marginRight: "0.5em" }}
                src={cmmisv}
                alt="CMMI"
              />
            </span>
            <span style={{ width: "15%" }}>
              <img
                src={iso}
                style={{ width: "8em", marginRight: "0.5em" }}
                alt="ab2"
              />
            </span>
          </div>
        </div>
      </div>

      <div className="container mb-4" style={{ marginTop: "65px" }}>
        {/* <p><b>DUNS Number</b>: 080267682. <br/><b>UEI</b>: XTA5EN9KNJN5.<br/><b>CAGE CODE</b>: 7MSQ9 <b>Headquarters</b>: Rockville, MD 20855. <br/><b>Email</b>: <a className="mailink" href="mailto:info@eigennet.com">info@eigennet.com</a></p> */}
      </div>
    </>
  );
}
