import React, { useEffect } from 'react';

import ae from '../Assets/Img/aroundearning-eigennet.png'
import ae_inner from '../Assets/Img/ae_inner.png'
import MediaQuery from 'react-responsive';
import "../index.css";


export default function AroundEarnings() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <div className='container' style={{boxShadow:'0px 0px 10px rgba(51, 51, 51, 0.04)',marginTop:'70px'}}>
            <h2 className="col-title" style={{borderBottom: '1px solid black',paddingBottom: '5px'}}>AROUND EARNINGS</h2>
            <h6 className='mb-3'>Improve your stock trading profits by purchasing it low and selling high.</h6>
            <p><img src={ae} style={{width:'-webkit-fill-available'}} alt=''/></p>
<br/>

<p className='mobile-responsive mt-2'>Around Earnings is a web-based application that employs Artificial intelligence to enable decisions with a statistical edge. The application has been developed primarily in Python and R Language and implements data science concepts to real world stock market scenarios. Around Earnings is equipped with features that will help you navigate the stock market with ease and confidence. It acts as a strategic tool and facilitates various aspects of trading such as strategy, psychology and risk management. It is your solution in a market being largely driven and dominated by cutting edge technology and analytics.</p>
<br/>
<div className='text-center'>
  <MediaQuery minWidth={930}>
  <img className="aligncenter" src={ae_inner} style={{marginBottom:'0px', width:'500px', height:'380px'}} alt=''/>
  </MediaQuery>
  <MediaQuery maxWidth={929}>
  <img className="aligncenter" src={ae_inner} style={{marginBottom:'0px', width:'330px'}} alt=''/>
  </MediaQuery>
  </div>

<p className='mobile-responsive mt-2'><h4 className='col-title'>Market Indicator</h4>A custom indicator developed on the principles of mean reversion. It gauges the overall market sentiment based on the behavior of carefully selected 500 stocks. The Market Indicator identifies the relative position of the market and helps you eliminate a large chunk of untimely entries. It helps turn the odds in your favor as it gives you an edge by adding the confluence of market structure and positioning you in the best risk to reward setups.</p>
<p className='mobile-responsive mt-2'><h4 className='col-title'>Predictions</h4>The Predictions feature leverages the power of Artificial Intelligence to filter for high probability trade setups. Powered by an algorithm developed based on Keltner Channels, it filters for stocks with a high probability of following the market tendency/cycle of mean reversion. The Prediction feature also calculates the accuracy of probability and correlation of the specific stock to the overall market. It is your answer to controlling human emotions while making trading decisions as it relies on 4 years of back tested data making your life easier as you navigate the ebbs and flows of the market.</p>
<p className='mobile-responsive mt-2'><h4 className='col-title'>Ticker Analysis</h4>The Ticker Analysis feature enables you to dig deep down into individual stocks. It helps you compare historically oversold scenarios in individual stocks. It equips you with precise Demand zones on multiple time frames. It also provides access to other statistical analysis tools such as Box Plot Analysis and BB halfwidth.</p><br/>

<a style={{padding: '10px 10px', border: '1px solid #d4defe', backgroundColor: '#48484A', borderRadius:'5px', color:'#fff'}} href="http://aroundearnings.com/" target="_blank" rel="noopener noreferrer nofollow">Go To Around Earnings</a>
<br/><br/>

<br/> 
    </div>
<br/> 
<br/> 
    </>
  )
}
