import React, { useEffect } from 'react';
import "../index.css";

export default function DataAnalytics() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='container'>
            <h3 className="col-title" style={{borderBottom: '1px solid black'}}>BIG DATA ANALYTICS</h3>
            <p style={{textAlign: 'justify'}}>Find the true value of what your data can do for you.</p>
 
            <div className="row">
            <div className="col-5"><img className="alignleft wp-image-168" style={{marginBottom: '5px'}}  src="https://www.eigennet.com/wp-content/uploads/2016/06/bd-inr.jpg" alt="ab2" width="383" height="230"/>
            </div>
            <div className="col-7">
            <h4>Big Data</h4>
            <p style={{textAlign: 'justify'}}>Industries are facing a drag on their systems with an exponential increase in digital data. The demands on traditional server systems can slow down important application functionality. Our services include an end-to-end data analytic pipeline by carefully adopting a Decision Analysis Review (DAR) procedure to meet your organizational needs. Our offerings include but not limited to Hadoop Administration and Optimization of Hadoop Clusters, Hadoop Development, Big Data Analytics, Data processing solutions at a low cost and No-SQL databases.</p>
            </div>
</div>

<div className="clear">&nbsp;</div>
<div style={{borderBottom: '1px solid black'}}></div><br/>

            <div className="row">
            <div className="col-5"><img className="alignleft wp-image-169" style={{marginBottom: '5px'}} src="https://www.eigennet.com/wp-content/uploads/2019/03/bigdata.png" alt="ab1" width="383" height="230"/>
            </div>
            <div className="col-7">
            <h4>Cloud Services</h4>
            <p style={{textAlign: 'justify'}}>Our knowledge in backend processes sets us apart. We have professional expertise in installing, administering and operating Enterprise Data Lakes/Data Hubs using all major of Hadoop distributions (Cloudera, Hortonworks, MapR) along with Technical consultation and implementation of high-performance computing and Hadoop/Spark based applications on premise and using AWS and Azure cloud. These efforts provide greater capabilities for scaling, integration and problem solving.</p>

            </div>
</div>

<div className="clear">&nbsp;</div>
<div style={{borderBottom: '1px solid black'}}></div><br/>

            <div className="row">
            <div className="col-5"><img className="alignleft wp-image-168" style={{marginBottom: '5px'}}  src="https://www.eigennet.com/wp-content/uploads/2016/06/gvr-new.jpg" alt="ab2" width="383" height="230"/>
            </div>
            <div className="col-7">
            <h4>Data Governance</h4>
            <p style={{textAlign: 'justify'}}>Manage your data for accuracy, reliability and timely delivery to meet your business standards and service level agreements. Our customers want to ensure that the right data can be made available to them especially in decision-making processes. Whether it’s a historical financial transaction or new information, our customers want to be able to distinguish all data for completeness and accuracy. Our team can conduct a thorough analysis on your data management practices and use powerful tools to identify and mitigate any gaps that could bring a risk to your processes.</p>
            <p style={{textAlign: 'justify'}}>Find out how Eigennet’s Data Analytic solutions can address your project needs by <a style={{color: '#005d94'}} href="mailto:info@eigennet.com" target="_blank" rel="nofollow noreferrer">Contacting Us</a></p>

</div>
</div>

<div className="clear">&nbsp;</div>

        </div>
  )
}
