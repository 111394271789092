import React, { useEffect } from 'react';
import { FaCheck } from 'react-icons/fa';
import cmmi from "../Assets/Img/cmmi1.png"
import iso from "../Assets/Img/iso.png"
import "../index.css";


export default function Certifications() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='container'  style={{boxShadow:'0px 0px 10px rgba(51, 51, 51, 0.04)',marginTop:'70px'}}>
                <h2 className="col-title" style={{borderBottom: '1px solid black'}}>CERTIFICATIONS</h2>
                <p style={{textAlign: 'justify'}}>Below are approved Certifications for Eigennet and Its Employees</p>
                <table style={{width: '100%', border: '0px solid #e1e0e0', background: '#fff', marginBottom:'10px'}}>
<tr>
 <td className="col-title" style={{borderBight: '0px solid #e1e0e0' , padding:'0px 8px', width:'50%',fontSize: '16px', fontWeight:'bold'}}>CMMI Certifications</td>
<td className="col-title" style={{borderBight: '0px solid #e1e0e0' , padding:'0px 8px', width:'50%',fontSize: '16px', fontWeight:'bold'}}>ISO Certifications</td>
</tr>
<tr>
<td style={{borderRight: '0px solid #e1e0e0', padding:'0px 8px', width:'50%'}}><FaCheck style={{color:"#84c000"}}/> CMMI Development Level 2 Certification<br/><FaCheck style={{color:"#84c000"}}/> CMMI Service Level 2 Certification</td>
<td style={{borderRight: '0px solid #e1e0e0', padding: '8px', width:'50%'}}><FaCheck style={{color:"#84c000"}}/> ISO 9001:2015</td>
</tr>
</table>
<p><img src={cmmi} alt="ab2" style={{width:'50%'}}/><img src={iso} style={{width:'12%'}} alt="ab2"/></p>

<br/>


      </div>
  )
}
