import React from "react";
import { FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa";
import // BrowserRouter as Router,
// Switch,
// Route,
// Link,
"react-router-dom";
import footlogo from "../Assets/Img/navbar_logo.svg";
import "../index.css";
import MediaQuery from "react-responsive";

export default function Footer(props) {
  return (
    <footer>
      {/* <div style={{position:'relative',left:'0',bottom:'0',right:'0',width:'100%',}}> */}
      <div className="container">
        <div className="row flex-wrap-reverse">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className=" d-flex ">
              <p
                style={{
                  textAlign: "left",
                  margin: "7px",
                  marginLeft: "0",
                  lineHeight: "20px",
                }}
              >
                <b style={{ color: "#1B2951" }}>UEI</b>: XTA5EN9KNJN5
                <br />
                <b style={{ color: "#1B2951" }}>DUNS</b>: 080267682 <br />
                <b style={{ color: "#1B2951" }}>CAGE CODE</b>: 7MSQ9 <br />
                <b style={{ color: "#1B2951" }}>Address</b>: 7361 Calhoun Place,
                Suite 650 <br /> Rockville, MD 20855
                {/* <b>Email</b>: <a className="mailink" href="mailto:info@eigennet.com">info@eigennet.com</a> */}
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 d-flex justify-content-start justify-content-md-end ">
            <div className="d-flex flex-column">
              <img src={footlogo} alt="logo" />
              <p>Engineering The Mission With Innovation</p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="p-4"
        style={{ backgroundColor: "#1b2951", color: "white" }}
      >
        <div className="container">
          <div className="row">
            <MediaQuery minWidth={930}>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <span className="text-light">
                  Copyright © 2024 Eigennet LLC{" "}
                </span>
              </div>

              <div
                className="col-sm-12 col-md-6 col-lg-6"
                style={{ textAlign: "right" }}
              >
                <a
                  className="text-light"
                  href="https://www.facebook.com/eigeninnovation/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebook />{" "}
                </a>{" "}
                &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                <a
                  className="text-light"
                  href="https://twitter.com/eigennet?lang=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaTwitter />{" "}
                </a>
                &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                <a
                  className="text-light"
                  href="https://www.linkedin.com/company/eigennet-llc?original_referer="
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedin />{" "}
                </a>
                &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; Email:{" "}
                <a
                  className="mailink text-light"
                  href="mailto:info@eigennet.com"
                >
                  info@eigennet.com
                </a>
              </div>
              {/* <FaFacebookSquare /> */}
            </MediaQuery>

            <MediaQuery maxWidth={929}>
              <div className="col-4">
                <a
                  className="text-light"
                  href="https://www.facebook.com/eigeninnovation/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebook />{" "}
                </a>
                &nbsp;&nbsp;
                <a
                  className="text-light"
                  href="https://twitter.com/eigennet?lang=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaTwitter />{" "}
                </a>
                &nbsp;&nbsp;
                <a
                  className="text-light"
                  href="https://www.linkedin.com/company/eigennet-llc?original_referer="
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedin />{" "}
                </a>
                &nbsp;&nbsp;
              </div>

              <div className="col-8" style={{ textAlign: "right" }}>
                <span style={{ fontSize: "14px" }}>
                  Email:{" "}
                  <a
                    className="mailink text-light"
                    href="mailto:info@eigennet.com"
                  >
                    info@eigennet.com
                  </a>{" "}
                </span>

                <br />
              </div>
            </MediaQuery>
          </div>

          <MediaQuery maxWidth={929}>
            <div className="d-flex justify-content-center">
              <span className="text-light " style={{ fontSize: "14px" }}>
                Copyright © 2024 Eigennet LLC{" "}
              </span>
            </div>
          </MediaQuery>
        </div>
      </div>
      {/* </div> */}
    </footer>
  );
}
