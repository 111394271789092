import React, { useEffect } from 'react';
import "../index.css";

export default function Administrative() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="container" style={{boxShadow:'0px 0px 10px rgba(51, 51, 51, 0.04)',marginTop:'70px'}}>
    <h2 className="col-title" style={{borderBottom: '1px solid black'}}>ADMINISTRATIVE OR CLERICAL SUPPORT</h2>
    <p className='mobile-responsive' style={{marginTop:'17px'}} >With a robust team comprising of a PMP certified program manager, administrative specialists and general clerks coupled with cutting edge records management solutions, Eigennet offers superior administrative or clerical support combined with smart technological solutions. By deploying a comprehensive management process consistent with Project Management Institute Eigennet ensures that the administrative services are optimized in execution, communication, risk management, and quality assurance. And with an efficient project management framework, Eigennet guarantees timely delivery of the deliverables while Eigennet’s experience in providing administrative support to various organizations ensures that detailed project plans are compiled with clearly defined expectations with realistic budgets. By combining our expertise in records management and data extraction, Eigennet is able to vastly reduce the resources and manpower required to review and maintain documents and files, Eigennet is able to expediate the administrative process quickly and efficiently to provide a reliable support service.</p>

<br/>


</div>

    )
}
