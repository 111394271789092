import { useEffect, useRef } from "react";
import MediaQuery from "react-responsive";
import banner from "../Assets/Img/Bannerx.png";
import bg_mobile from "../Assets/Img/mobile-resv5.png";

// import AtlasVision from "../Assets/hardware/AtlasVision.png";
import AtlasVision from "../Assets/hardware/AtlasVision.svg";
import DataStream_Master from "../Assets/hardware/DataStream_Master.png";
import Disc_Sentinel from "../Assets/hardware/Disc_Sentinel.png";
import SwiftPrep from "../Assets/hardware/SwiftPrep.png";
// import Five from "../Assets/hardware/5.png";
// import Six from "../Assets/hardware/6.png";

import UP from "../Assets/hardware/up.svg";
import Down from "../Assets/hardware/down.svg";
// import Left from "../Assets/hardware/left.svg";
// import Right from "../Assets/hardware/right.svg";

function Hardware() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const HardwareContent = [
    {
      title: "AtlasVision",
      description:
        "AtlasVision is an advanced scanning solution for oversized documents in architectural and engineering projects. It captures intricate details from maps and blueprints, converting them into digital formats with precision.",
      image: AtlasVision,
      specifications: [
        {
          title: "FASTER",
          description:
            "With improved speed and three times faster data transfer, the scanner becomes the cornerstone of productivity.",
        },
        {
          title: "SMARTER",
          description:
            // "Through the immediate display of all primary tasks required for basic scanning and copying on the scanner, the new software saves significant operator time.",
            "By displaying all essential tasks for basic scanning and copying directly on the scanner, the new software simplifies the process, allowing operators to keep track of their scanning workflow efficiently.",
        },
        {
          title: "BETTER",
          description:
            "Our special Gigabit Ethernet implementation with xDTR2.5, which offers three times the bandwidth of USB2, is incredibly quick and streamlines processes even more through network sharing, allowing scans to be managed from any computer.",
        },
      ],
    },
    {
      title: "OptiScan Pro",
      description:
        "OptiScan Pro is cutting-edge hardware that scans multiple DVDs through Automated Robotic Arm which place Disc into drive holders for extracting information. It ensures lightning-fast read and write speeds for archiving, analysis, and data transfer.",
      image: DataStream_Master,
      specifications: [
        {
          title: "Automation",
          description:
            // "The DataStream Master application handles the entire process of hands-off data extraction. It offers solutions ranging from 100-disc PC-based systems to fully integrated 300-disc designs.",
            "The OptiScan Pro application automates the entire data extraction process, eliminating manual intervention. It caters to a variety of needs, offering solutions that scale from PC-based systems handling 100 discs to fully integrated designs managing up to 300 discs.",
        },
        {
          title: "Powerful Encoding",
          description:
            "OptiScan Pro can create file copies or generate H.264 format MPEG4 files at any resolution, along with MKV files.",
        },
        {
          title: "Flexible Scripting",
          description:
            "The OptiScan Pro encoding engine can be tailored for various video formats and dedicated workflows. If you require an encoder-only dedicated machine, you can configure OptiScan Pro to align with your specific workflow requirements.",
        },
        {
          title: "Ripping Accuracy",
          description:
            "OptiScan Pro includes advanced error detection and correction features, such as drive C2, CRC, and multiread modes, ensuring data integrity. Additionally, it can automatically run third-party programs for enhanced data verification.",
        },
        {
          title: "Integrated metadata",
          description:
            // "DataStream Master offers flexibility by supporting external XML data and folder JPG images. Additionally, it includes embedded, iTunes-compliant metadata for all MPEG4 formats, including deep data and high-resolution cover art.",
            "OptiScan Pro provides robust flexibility by supporting external data sources like XML files and folder-based JPG images. It also embeds comprehensive metadata within MPEG4 formats, including detailed information (deep data) and high-resolution cover art, enhancing organization and searchability.",
        },
      ],
      imageClass: "col-12 ",
    },
    // {
    //   title: "DataStream ",
    //   description:
    //     "DataStream Master is cutting-edge hardware that scans multiple DVDs through Automated Robotic Arm which place Disc into drive holders for extracting information. It ensures lightning-fast read and write speeds for archiving, analysis, and data transfer.",
    //   image: DataStream_Master,
    //   specifications: [
    //     {
    //       title: "Automation",
    //       description:
    //         // "The DataStream Master application handles the entire process of hands-off data extraction. It offers solutions ranging from 100-disc PC-based systems to fully integrated 300-disc designs.",
    //         "The DataStream Master application automates the entire data extraction process, eliminating manual intervention. It caters to a variety of needs, offering solutions that scale from PC-based systems handling 100 discs to fully integrated designs managing up to 300 discs.",
    //     },
    //     {
    //       title: "Powerful Encoding",
    //       description:
    //         "DataStream Master can create file copies or generate H.264 format MPEG4 files at any resolution, along with MKV files.",
    //     },
    //     {
    //       title: "Flexible Scripting",
    //       description:
    //         "The DataStream Master encoding engine can be tailored for various video formats and dedicated workflows. If you require an encoder-only dedicated machine, you can configure DataStream Master to align with your specific workflow requirements.",
    //     },
    //     {
    //       title: "Ripping Accuracy",
    //       description:
    //         "DataStream Master includes advanced error detection and correction features, such as drive C2, CRC, and multiread modes, ensuring data integrity. Additionally, it can automatically run third-party programs for enhanced data verification.",
    //     },
    //     {
    //       title: "Integrated metadata",
    //       description:
    //         // "DataStream Master offers flexibility by supporting external XML data and folder JPG images. Additionally, it includes embedded, iTunes-compliant metadata for all MPEG4 formats, including deep data and high-resolution cover art.",
    //         "DataStream Master provides robust flexibility by supporting external data sources like XML files and folder-based JPG images. It also embeds comprehensive metadata within MPEG4 formats, including detailed information (deep data) and high-resolution cover art, enhancing organization and searchability.",
    //     },
    //   ],
    //   imageClass: "col-12 col-md-9",
    // },
    // {
    //   title: "SwiftPrep",
    //   description:
    //     "The SwiftPrep scanner processes payments and scans documents directly from file folders, producing images and usable data in seconds. It handles damaged, fragile, and small documents, reducing labor costs and enhancing document security.",
    //   image: SwiftPrep,
    //   specifications: [
    //     {
    //       title: "DOCUMENT FEEDER",
    //       description:
    //         "The SwiftPrep feeder minimizes document preparation by allowing single pages or stacks of intermixed weights and sizes to be fed directly for scanning.",
    //     },
    //     {
    //       title: "SWIFTPREP SCAN SOFTWARE",
    //       description:
    //         "SwiftPrep SCAN Software creates customized scanning jobs, identifies page types, and automates traditional document prep tasks while integrating with your software.",
    //     },
    //     {
    //       title: "ENHANCE DETECTION OF MULTI-FEED",
    //       description:
    //         "The SwiftPrep scanner is equipped with seven strategically placed Ultrasonic Multi-Feed Detectors (MFDs) that recognize double feeds, sticky notes, and turned corners anywhere on a page. These detectors notify the operator, ensuring the most accurate scanning results in the market.",
    //     },
    //     {
    //       title: "FLEXIBLE AND EFFICIENT",
    //       description:
    //         "The SwiftPrep scanner efficiently processes a wide range of materials, from thick paper to fragile or damaged pieces. It handles envelopes, file folders, receipts, and small pages without the need for pre-sorting or taping. Additionally, it can even handle X-rays and three-dimensional objects with ease.",
    //     },
    //     {
    //       title: "SORTING CAPABILITIES",
    //       description:
    //         "The SwiftPrep offers versatile sorting abilities with three or five programmable sort-bins. Its expanded bin capacity allows for nearly unlimited batch sizes, and all models include an additional pass-through bin for thick and delicate items. ",
    //     },
    //     {
    //       title: "ONE-CLICK SCAN",
    //       description:
    //         "Document scanners streamline the document transformation process by minimizing prep, paper handling, and manual tasks.",
    //     },
    //     {
    //       title: "SECURITY AND INTEGRITY",
    //       description:
    //         "SwftPrep Scanning ensures efficient, safe, and secure document transformation, guarding against costly data breaches in today’s world of heightened data security and compliance.",
    //     },
    //   ],
    // },
    {
      title: "Disc Sentinel",
      description:
        "Disc Sentinel efficiently scans DVDs, discarding corrupt ones to preserve pristine data. Ideal for archival and data management.",
      image: Disc_Sentinel,
      specifications: [
        {
          title: "Enhanced Disc Burning Speed and Increased Disc Capacity",
          description:
            "Blu-ray discs provide over five times the storage capacity compared to traditional DVDs. The Disc Sentinel, equipped with USB 3.0 technology allowing data transfer speeds of up to 5 Gbit/s, can burn Blu-ray discs at a maximum speed of 12X, significantly reducing the burning time.",
        },
        {
          title: "USB 3.0 and USB 2.0 Compatible",
          description:
            "The Disc Sentinel is compatible with both SuperSpeed USB 3.0 and Hi-speed USB 2.0 interfaces. When connected to a PC without a USB 3.0 interface, it automatically switches to USB 2.0 mode.",
        },
        {
          title: "ADRS - Advanced Disc Reject System",
          description:
            "Disc sentinel automatically ejects faulty discs and seamlessly resumes its current project. This essential feature sets it apart from many other auto loaders. With Disc sentinel, bad copies are consistently separated from the good ones, ensuring that projects don’t get delayed due to a single problematic disc.",
        },
        {
          title: "Sleep Mode",
          description:
            "If the USB connection is not established between Disc Sentinel and the host PC, Disc Sentinel automatically enters sleep mode to save energy.",
        },
      ],
      imageClass: "col-12 col-md-8 col-lg-6",
    },
  ];

  return (
    <>
      <div
        className=""
        style={{
          width: "-webkit-fill-available",
          backgroundImage: `url(${banner})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          marginTop: "70px",
        }}
      >
        <div
          className="container"
          style={{
            minHeight: "200px",
          }}
        >
          <div
            className="d-flex flex-column align-content-start pt-5"
            // style={{
            //   paddingTop: "60px",
            // }}
          >
            <MediaQuery maxWidth={767}>
              <h3 className=" text-left">
                EMBRACE{" "}
                <sapn
                  style={{
                    color: "#84C000",
                  }}
                >
                  TOMORROW:
                </sapn>
              </h3>
            </MediaQuery>
            <MediaQuery minWidth={768} maxWidth={991}>
              <h2 className=" text-left">
                EMBRACE{" "}
                <sapn
                  style={{
                    color: "#84C000",
                  }}
                >
                  TOMORROW:
                </sapn>
              </h2>
            </MediaQuery>
            <MediaQuery minWidth={992}>
              <h1 className=" text-left">
                EMBRACE{" "}
                <sapn
                  style={{
                    color: "#84C000",
                  }}
                >
                  TOMORROW:
                </sapn>
              </h1>
            </MediaQuery>

            <p
              className=" text-left col-md-7 col-lg-12 p-0 fw-normal"
              style={{
                fontSize: "16px",
              }}
            >
              Optimize Your Operations with Eigennet’s
              <br className="d-block d-lg-none " /> Cutting-Edge Automation
              Systems
            </p>
          </div>
          {/* <div className="mask">
            <div className="row mt-1">
              <div
                className="col-10 text-black p-0"
                style={{ color: "#1c2951" }}
              >
                <h1
                  className="mb-3 mt-3"
                  style={{ textAlign: "left", fontSize: "2.5rem" }}
                >
                  <b>
                    <span>EMBRACE</span>{" "}
                    <span
                      style={{
                        color: "#84C000",
                      }}
                    >
                      TOMORROW:
                    </span>
                  </b>
                </h1>
                <p
                  className="mb-3"
                  style={{ textAlign: "left", fontSize: "18px" }}
                >
                  Optimize Your Operations with Eigennet’s Cutting-Edge
                  Automation Systems
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <div
        className="container"
        style={
          {
            // boxShadow: "0px 0px 10px rgba(51, 51, 51, 0.04)",
            // marginTop: "70px",
          }
        }
      >
        {/* <h2 className="col-title" style={{ borderBottom: "1px solid black" }}>
          HARDWARE
        </h2>
        <p style={{ textAlign: "justify" }}>
          Optimal Hardware Solutions Tailored to Your Needs.
        </p> */}
        {/* <div className=" row my-5"> */}
        {/* <div className=" col-12 col-md-6 d-flex flex-column justify-content-center">
            <p
              className="p-0 m-0 "
              style={{
                fontWeight: "500",
                fontSize: "36px",
                lineHeight: "49px",
              }}
            >
              Unleash the Power <span className=" d-none d-lg-inline ">of</span>
            </p>
            <p
              className="p-0 m-0 fw-bold"
              style={{
                fontWeight: "500",
                fontSize: "36px",
                lineHeight: "49px",
              }}
            >
              <span className="d-inline d-lg-none">of</span> Next-Gen{" "}
              <span
                style={{
                  color: "#84C000",
                }}
              >
                Automation
              </span>
            </p>
          </div> */}
        <p
          className=" mt-3 mb-4 col-12  p-0 "
          style={{
            fontSize: "18px",
          }}
        >
          At Eigennet LLC, we power your success through groundbreaking
          automation. We don't just automate tasks, we innovate. Our solutions
          go beyond simple transactions, fostering a culture of continuous
          improvement. Let's partner to unlock the potential of automation and
          drive your business to new heights.
          {/* “Your Lifelong Ally in Innovation: At Eigennet LLC, we are your
            dedicated partner in innovation, committed to delivering superior
            automation solutions that transcend mere transactions. Our focus is
            on building lasting relationships and achieving mutual growth.
            Explore our innovative solutions and let us drive your business
            towards sustained success.” */}
        </p>
        {/* </div> */}
        {/* <div className="my-5">
          <p
            className=" p-0 m-0"
            style={{
              fontWeight: "500",
              fontSize: "32px",
            }}
          >
            {" "}
            Empowering Your{" "}
            <span
              style={{
                color: "#84C000",
              }}
            >
              Workflow
            </span>
          </p>
          <div
            className=" p-0 m-0"
            style={{
              fontSize: "16px",
              width: "max-content",
            }}
          >
            <span>Our Cutting-Edge Automation Systems</span>
            <div
              className=""
              style={{
                height: "4px",
                backgroundImage: "linear-gradient(to right, #1B2951, #84C000)",
              }}
            ></div>
          </div>
        </div> */}
        <p
          className="p-0"
          style={{
            fontWeight: "500",
            fontSize: "32px",
          }}
        >
          Our Automation{" "}
          <span
            style={{
              color: "#84C000",
            }}
          >
            Systems
          </span>
        </p>
        {HardwareContent.map((content, index) => (
          <HardwareComponent {...content} key={index} index={index} />
        ))}
      </div>
    </>
  );
}

function HardwareComponent({
  index,
  title,
  tradeMark = "TM",
  description,
  image,
  imageClass = "col-12 col-md-9 col-lg-7",
  specifications,
}) {
  const specificationsRef = useRef(null);
  const arrowRef = useRef(null);
  const backgroundRef = useRef(null);

  const buttonID = `collapseExample${index}`;
  console.log("specificationsRef", specificationsRef.current?.id);
  // useEffect(() => {
  //   // window.scrollTo(0, 0);
  //   specificationsRef.current?.addEventListener("click", function () {
  //     console.log("show.bs.collapse");
  //   });
  // }, []);

  const ButtonClicked = () => {
    if (specificationsRef.current.classList.contains("show")) {
      // specificationsRef.current.classList.remove("show");
      backgroundRef.current.style.background = "transparent";
      arrowRef.current.src = Down;
    } else {
      // specificationsRef.current.classList.add("show");
      backgroundRef.current.style.background = "#f4f4f4";
      arrowRef.current.src = UP;
    }
  };

  return (
    <div
      className="container px-0 "
      style={{
        marginBottom: "50px",
      }}
    >
      <h2
        className=" mx-auto position-relative "
        style={{
          fontSize: "42px",
          width: "max-content",
          paddingBottom: "18px",
          color: "#1B2951",
        }}
      >
        {title}
        {tradeMark ? (
          <span
            className="position-absolute "
            style={{
              fontSize: "12px",
              right: "0",
              top: "0",
              transform: "translate(100%,-50%)",
            }}
          >
            {tradeMark}
          </span>
        ) : null}
      </h2>
      <p
        className=" text-center "
        style={{
          paddingBottom: "18px",
        }}
      >
        {description}
      </p>
      <div
        className=" d-flex justify-content-center "
        style={{
          background: "#f4f4f4",
        }}
      >
        <img src={image} alt="Hardware" className={imageClass + " px-0 mx-0"} />
      </div>
      <div
        ref={backgroundRef}
        className=""
        style={{
          paddingTop: "18px",
          paddingBottom: "18px",
          background: "transparent",
          // background: "#f4f4f4",
        }}
      >
        <button
          type="button"
          data-toggle="collapse"
          data-target={`#${buttonID}`}
          aria-expanded="false"
          aria-controls={buttonID}
          className=" rounded-lg py-2 mx-auto px-3 d-block btn text-white mt-3 d-flex align-items-center"
          style={{
            background: "#1B2951",
            gap: "10px",
            fontSize: "14px",
            outline: "none",
          }}
          onClick={ButtonClicked}
        >
          <span>EXPLORE SYSTEM</span>{" "}
          <img
            ref={arrowRef}
            style={{
              width: "10px",
              transform: "translateY(-1px)",
            }}
            src={Down}
          />
        </button>

        <div className="collapse" ref={specificationsRef} id={buttonID}>
          {specifications &&
            specifications.map(({ title, description }, index) => (
              <div
                key={index}
                className=" px-4"
                style={{
                  paddingTop: index === 0 ? "25px" : "0px",
                  paddingBottom: "25px",
                }}
              >
                <p
                  className=" "
                  style={{
                    fontSize: "28px",
                    marginBottom: "10px",
                    width: "max-content",
                    lineHeight: "32px",
                    color: "#1B2951",
                    borderBottom: "1px solid #1B2951",
                  }}
                >
                  {title}
                </p>
                <p
                  className="m-0"
                  style={{
                    color: "#525252",
                  }}
                >
                  {description}
                </p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default Hardware;
