import React,{useEffect} from 'react'
import "../index.css";

export default function QcTitle() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div  className='container'>
            <h3 className="col-title" style={{borderBottom: '1px solid black',paddingBottom: '5px'}}>QC TITLE</h3>
            <h6 className='mb-3'>Create AI Assisted Electronic Title List for Copyright Recordation</h6>
            <p><img src="https://www.eigennet.com/wp-content/uploads/2022/11/QCtitle-eigen.png" style={{width:'-webkit-fill-available'}} alt=''/></p>
<br/>
<p className='mt-2' style={{textAlign: 'justify'}}>For organizations dealing with a large number of work titles for copyright recordation, the QC Title offers a one-stop solution to create automated Electronic Title lists. By combining AI and Machine Learning algorithms the QC Title can effortlessly detect errors in a title list and can rectify the errors automatically. The QC Title aims to reduce the extensive manual labor and resources required to type and quality check the titles to create an Electronic Title List for copyright recordation. For organizations dealing with thousands of work titles, typing them to create an Electronic Title List, and then quality checking the titles for errors as per the Electronic Title List guidelines can be an error-prone and resource-intensive task if done manually, allowing the errors to be reflected in the public records. Furthermore, the title counts for primary and secondary titles are linked to revenue and failure to correctly provide the correct count can result in a loss of revenue.</p>
<br/>

<div className='text-center'><img className="aligncenter" style={{marginBottom:'0px', width:'500px', height:'380px'}} src="https://www.eigennet.com/wp-content/uploads/2022/05/qctitle3.png" alt=''/></div>
<br/>
<p className='mt-2' style={{textAlign: 'justify'}}>The QC Title application eliminates this bottleneck in title typing by automating the process of formatting and cleaning the work titles. With the help of AI and custom data scripts, the application can smartly identify the various facets of a title, including but not limited to author names, articles, secondary and primary titles, etc., and can be integrated with an automatic data extraction system. After identifying the errors, the application automatically formats the titles as per the mandated guidelines. The end result provides a thoroughly formatted and clean Electronic Title List that can be uploaded to a database.</p> 
<p className='mt-2' style={{textAlign: 'justify'}}>QC Title was tailor-made for the Library of Congress to reduce their fast number of recordation backlogs which were in the form of work titles. Using a customized data extraction solution derived from Doc2Data.ai, QC Title.io extracted titles from multiple documents, detected and corrected errors in typed or uploaded titles, and streamlined the process of creating electronic title lists into an automated, efficient, and accurate process.</p>
<p className='mt-2'  style={{textAlign: 'justify'}}>In addition to the above functions, with little customization, QC Title can help organizations dealing with the copyright process save time and money by creating AI-assisted Electronic Title Lists, autocorrecting and existing lists and much more.</p>
<br/>
<br/>
  
<a style={{padding: '10px 10px', border: '1px solid #d4defe', backgroundColor: '#7eab1c', borderRadius:'5px', color:'#fff'}} href="https://beta.qctitle.io/" target="_blank" rel="noopener noreferrer nofollow">Go To QC Title</a>
<br/><br/>

    </div>
  )
}
