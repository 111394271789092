import React from 'react'
import '../spagestyle.css'
import {
    // BrowserRouter as Router,
    // Switch,
    // Route,
    Link,
  } from "react-router-dom";

export default function Error404() {
  return (
    <>  
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
    
        {/* <title>Doc2data</title> */}
        <meta content="" name="description" />
        <meta content="" name="keywords" />
    
        <link href="assets/img/favicon.png" rel="icon" />
        <link href="assets/img/apple-touch-icon.png" rel="apple-touch-icon" />
    
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,700,700i|Roboto:100,300,400,500,700|Philosopher:400,400i,700,700i"
          rel="stylesheet"
        />
    
        <link href="assets/vendor/aos/aos.css" rel="stylesheet" />
        <link
          href="assets/vendor/bootstrap/css/bootstrap.min.css"
          rel="stylesheet"
        />
        <link
          href="assets/vendor/bootstrap-icons/bootstrap-icons.css"
          rel="stylesheet"
        />
        <link
          href="assets/vendor/glightbox/css/glightbox.min.css"
          rel="stylesheet"
        />
        <link href="assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet" />
    
        <link href="assets/css/spagestyle.css" rel="stylesheet" />
      </head>
    
      <body style={{background:'#89c000',color:'#1B2951'}}>
    
        
    
    
    
        <main id="main" className='err'>
                <div id="clouds">
            <div className="cloud x1"></div>
            <div className="cloud x1_5"></div>
            <div className="cloud x2"></div>
            <div className="cloud x3"></div>
            <div className="cloud x4"></div>
            <div className="cloud x5"></div>
        </div>
        <div className="c">
            <div className="_404">404</div>
            <div className="_1">THE PAGE</div>
            <div className="_2">WAS NOT FOUND</div>
            <div className="wrap">
                <Link to="/" className="button btn-lg">Back to Home Page</Link>
            </div>
        </div>
        </main>
        <script src="assets/vendor/aos/aos.js"></script>
        <script src="assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
        <script src="assets/vendor/glightbox/js/glightbox.min.js"></script>
        <script src="assets/vendor/swiper/swiper-bundle.min.js"></script>
        <script src="assets/vendor/php-email-form/validate.js"></script>
    
        <script src="assets/js/main.js"></script>
      </body>
    </html>
    
    </>

  )
}
