import React, { useEffect } from 'react';
import "../index.css";

export default function ContactUs() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='container' style={{marginTop:'70px'}}>
            <h3 className="col-title" style={{borderBottom: '1px solid black'}}>CONTACT US</h3>
            <p style={{textAlign: 'justify'}}>Eigennet</p>
            <p>7361 Calhoun Place, Suite 650<br />
Rockville, MD 20855<br />
Email: <a className="mailink" href="mailto:info@eigennet.com" target="_blank" rel="nofollow noreferrer">info@eigennet.com</a></p>
<hr/>

<div className="mapouter">
<div className="gmap_canvas"><iframe loading="lazy" title='Map' id="gmap_canvas" src="https://maps.google.com/maps?q=7361%20Calhoun%20Place%2C%20Suite%20650%20Rockville%2C%20MD%2020855&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed" width="600" height="500" frameborder="0" marginwidth="0" marginheight="0" scrolling="no"></iframe></div>
{/* <style>.mapouter{position:relative;text-align:right;height:500px;width:600px;}.gmap_canvas {overflow:hidden;background:none!important;height:500px;width:600px;}</style> */}
</div>
        </div>
  )
}
