import React,{useEffect} from 'react'
import "../index.css";

import phyRecords from "../Assets/Img/physical-records.jpg"

export default function PhysicalRecords() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <div className='container' style={{boxShadow:'0px 0px 10px rgba(51, 51, 51, 0.04)',marginTop:'70px'}}>
    <h2 className="col-title mb-3" style={{borderBottom: '1px solid black'}}>PHYSICAL RECORDS</h2>
<div className='row'>
<div className='col-sm-12 col-md-6 col-lg-5'><img loading="lazy" className="alignleft align wp-image-116" src={phyRecords} alt='phyrec' style={{width:'-webkit-fill-available'}}/></div>

<div className='mobile-responsive col-sm-12 col-md-6 col-lg-7 mt-3' >
  <p>Eigennet provides physical records management solutions to clients looking to manage large number of physical documents. It may be a daunting task to manage physical documents if not done in a systematic manner, but Eigennet, with its experience gathered over the years providing physical records management to various government organizations, leverages existing management practices to make sure that every phase of the management cycle is well-run. From sorting physical documents according to their file sizes and dates to securely archiving them following the mandated naming guidelines, Eigennet ensures that every stage is hassle-free while still being precise and accurate.
  </p>
  </div>
</div>
<br/>
<br/>
</div>
<br/>

</>
  )
}
