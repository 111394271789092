import "./App.css";
import "typeface-roboto";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link,
} from "react-router-dom";
import NavBar from "./Components/NavBar";
// import HomePage2 from './Components/HomePage2';
// import HomePageHero from './Components/HomePageHero';
// import HomePageOrg from './Components/HomePageOrg';
// import HomePageAnnounce from './Components/HomePageAnnounce';
import Homepage from "./Components/HomePage";
// import HomepageNew from './Components/HomePageNew';
import Contracts from "./Components/Contracts";
// import Doc2data from './Components/Doc2data';
import Doc2data from "./Components/Doc2data_siddu";
// import RfpEngine from "./Components/RfpEngine";
import RfpEngine from "./Components/RfpEngine_siddu";
import QcTitle from "./Components/QcTitle";
import AroundEarnings from "./Components/AroundEarnings";
import LegalAndLitigation from "./Components/LegalAndLitigation";
import AiAsAService from "./Components/AiAsAService";
import CustomAppDevelop from "./Components/CustomAppDevelop";
import Administrative from "./Components/Administrative";
// import OurTeam from "./Components/OurTeam";
import WhoWeServe from "./Components/WhoWeServe";
import WorkModels from "./Components/WorkModels";
import Certifications from "./Components/Certifications";
import Capabilities from "./Components/Capabilities";
import ResearchLabs from "./Components/ResearchLabs";
import Careers from "./Components/Careers";
import Footer from "./Components/Footer";
import ContactUs from "./Components/ContactUs";
import DataAnalytics from "./Components/DataAnalytics";
import DocumentConversion from "./Components/DocumentConversion";
import ElectronicRecords from "./Components/ElectronicRecords";
import PhysicalRecords from "./Components/PhysicalRecords";
import Error404 from "./Components/Error404";
// import ReactGA from 'react-ga';
import ReactGA from "react-ga4";
import RouteChangeTracker from "./Components/RouteChangeTracker ";
import Benefits from "./Components/Benefits";
import ScrollToTop from "react-scroll-to-top";
import EmployeeHandbook from "./Components/EmployeeHandbook";
import ProgressBar from "react-scroll-progress-bar";
import AboutUs from "./Components/AboutUs_siddu";
import Services from "./Components/Services_siddu";
import Hardware from "./Components/Hardware_siddu";
// import { Doc2dataNew } from './Components/Doc2dataNew';
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
// import { AboutUs } from "./Components/AboutUs";
// import { Services } from "./Components/Services";
// import Handbook from "./Components/Handbook";

const TRACKING_ID = "G-VPRK54V3HT"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.send("pageview");

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <Router>
        <NavBar
          contracts="CONTRACTS"
          capabilities="CAPABILITIES"
          re_labs="RESEARCH LAB"
          careers="CAREERS"
          about_us="ABOUT US"
          services="SERVICES"
          hardware="HARDWARE"
        />
        {/* <div className="container-fluid my-3"> */}
        <div
          style={{
            display: "flex",
            minHeight: "100vh",
            flexDirection: "column",
          }}
        >
          <div style={{ flex: 1 }}>
            {/* Page content goes here */}
            <div>
              <RouteChangeTracker />
            </div>
            <ProgressBar bgcolor="#89C000" />

            <Switch>
              <Route exact path="/Doc2Data">
                <Doc2data />
              </Route>
              {/* <Route exact path="/Doc2DataNew">
            <Doc2dataNew />
          </Route> */}
              <Route exact path="/RfpEngine">
                <RfpEngine />
              </Route>
              <Route exact path="/contracts">
                <Contracts />
              </Route>
              <Route exact path="/QcTitle">
                <QcTitle />
              </Route>
              <Route exact path="/AroundEarnings">
                <AroundEarnings />
              </Route>

              <Route exact path="/DocumentConversion">
                <DocumentConversion />
              </Route>
              <Route exact path="/ElectronicRecords">
                <ElectronicRecords />
              </Route>
              <Route exact path="/PhysicalRecords">
                <PhysicalRecords />
              </Route>
              <Route exact path="/LegalAndLitigation">
                <LegalAndLitigation />
              </Route>
              <Route exact path="/AiAsAService">
                <AiAsAService />
              </Route>
              <Route exact path="/CustomAppDevelop">
                <CustomAppDevelop />
              </Route>
              <Route exact path="/Administrative">
                <Administrative />
              </Route>

              <Route exact path="/Services">
                <Services />
              </Route>

              {/* <Route exact path="/OurTeam">
                <OurTeam />
              </Route> */}
              <Route exact path="/WhoWeServe">
                <WhoWeServe />
              </Route>
              <Route exact path="/WorkModels">
                <WorkModels />
              </Route>
              <Route exact path="/Certifications">
                <Certifications />
              </Route>

              <Route exact path="/aboutus">
                <AboutUs />
              </Route>

              <Route exact path="/capabilities">
                <Capabilities />
              </Route>
              <Route exact path="/re_labs">
                <ResearchLabs />
              </Route>
              <Route exact path="/careers">
                <Careers />
              </Route>
              <Route exact path="/Benefits">
                <Benefits />
              </Route>
              <Route exact path="/eigennetllc_handbook.pdf">
                <EmployeeHandbook />
              </Route>

              <Route exact path="/ContactUs">
                <ContactUs />
              </Route>
              <Route exact path="/DataAnalytics">
                <DataAnalytics />
              </Route>
              <Route exact path="/Hardware">
                <Hardware />
              </Route>
              {/* <Route exact path="/AboutUs">
                <AboutUs />
              </Route>
              <Route exact path="/Services">
                <Services />
              </Route> */}

              <Route exact path="/">
                {/* <HomePage2/> */}
                {/* <HomepageNew/> */}
                {/* <HomePageOrg/> */}
                <Homepage />
                {/* <HomePageHero /> */}
                {/* <HomePageAnnounce /> */}
              </Route>

              <Route exact path="*" component={Error404}>
                <Error404 />
              </Route>
            </Switch>
            {/* </div> */}
          </div>
          <Footer />
        </div>
        {/* <Footer/>    */}
      </Router>
      <div>
        <ScrollToTop smooth />
      </div>
    </>
  );
}

export default App;
